import React from 'react';
import { RootState } from "./../../store";
import { connect } from "react-redux";
import * as env from '../../app.json';
import FileBase64 from 'react-file-base64';
import { IFile } from '../../models/file';
import { IUpload } from '../../models/upload';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import AttachmentIcon from '@material-ui/icons/Attachment';

export interface Props {
    t: TFunction;
    style?: any;
    type: string;
    uploadedFiles?: IFile[];
    onChange: (addedFiles: IUpload[], deletedFiles: IFile[]) => void;
}

export interface State {
    key: number;
    selectedFiles: IUpload[];
    deletedFiles: IFile[];
}

class UserFilesAddComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            key: 0,
            selectedFiles: [],
            deletedFiles: [],
        };
    }

    render() {
        const { deletedFiles, selectedFiles } = this.state;
        const { t } = this.props;
        const uploadedFiles = (this.props?.uploadedFiles || []).filter(f => deletedFiles.findIndex((d) => d.ID === f.ID) < 0);

        return (
            <div className={'mini-text-input-component reg-input-styles'}>
                <p>{t('Attach files')}</p>
                <div className='mini-text-input-component__file-input-imitation'>
                    <span></span>
                    <AttachmentIcon htmlColor='#03a8f5' fontSize='large'/> 
                    <div>
                        <FileBase64
                            key={this.state.key}
                            onDone={(f) => {
                                const _selectedFiles = selectedFiles.concat([f]);
                                this.setState({key: this.state.key + 1, selectedFiles: _selectedFiles});
                                this.props.onChange && this.props.onChange(_selectedFiles, deletedFiles);
                            }}
                        />
                    </div>
                </div>
                {
                    this.state.selectedFiles.map((f, i) => <div>
                        <a target="_blank" onClick={() => {
                            const w = window.open('about:blank');
                            if (!w) {
                                return;
                            }
                            setTimeout(function(){ //FireFox seems to require a setTimeout for this to work.
                                const e =w.document.body.appendChild(w.document.createElement('iframe'));
                                e.src = f.base64;
                                e.setAttribute("style", "height: 100%, width: 100%");
                            }, 0);
                        }}>{f.name.substring(0, 28)}...</a>
                        <img style={{}}
                            onClick={() => this.setState({selectedFiles: selectedFiles.filter((_, j) => i !== j)})}
                            src={require('../../images/medical-card/small-icons/close-icon.png')}
                        />
                    </div>)
                }
                {
                    uploadedFiles && uploadedFiles.length ?
                        <>
                            <p style={{ margin: '10px 0 5px 0', fontSize: '13px' }}>{t('Previously uploaded files')}:</p>
                            {uploadedFiles.map((f, i) => <div>
                                <a href={f.uri ? f.uri : env.uploads + 'patients/' + this.props.type + f.Filename} target="_blank">{f.Filename}</a>
                                <img style={{}}
                                    onClick={() => {
                                        const _deletedFiles = deletedFiles.concat([f]);
                                        this.setState({deletedFiles: _deletedFiles});
                                        this.props.onChange && this.props.onChange(selectedFiles, _deletedFiles);
                                    }}
                                    src={require('../../images/medical-card/small-icons/close-icon.png')}
                                />
                            </div>)}
                        </>
                        : ''
                }
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
});

const mapDispatchToProps = {
};

let UserFilesAddComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(UserFilesAddComponent)
);

export default UserFilesAddComponentContainer