import { i18n, TFunction } from "i18next";
import moment from "moment";
import React, { Component } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { deletePatientDoctor, savePatientDoctor } from "../../actions/patient-doctor-action";
import { askDutyPrice, getDoctor, getDoctorPrice } from "../../actions/doctor-action";
import { IDoctor } from "../../models/doctor";
import { VisitType } from "../../models/doctor-filter";
import { IPatientDoctor } from "../../models/patient-doctor";
import { ISchedulingItem } from '../../models/scheduling-item';
import { ISchedulingItemFilter } from "../../models/scheduling-item-filter";
import { ISchedulingItemRecord, SchedulingItemRecordStatus, SchedulingItemRecordType } from "../../models/scheduling-item-record";
import { IAuthState } from "../../states/auth-state";
import { ISchedulingItemsState } from "../../states/scheduling-items-state";
import { RootState } from "../../store";
import './Doctors.scss';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { getSchedulingItems } from "../../actions/scheduling-item-actions";
import { saveSchedulingItemRecord, getSchedulingItemRecords, SchedulingItemRecordsType } from "../../actions/scheduling-item-record-actions";
import { ISchedulingItemRecordFilter } from "../../models/scheduling-item-record-filter";
import StarRatings from "react-star-ratings";
import { IDoctorState } from "../../states/doctor-state";
import DoctorRatings from "../Ratings/DoctorRatings";
import { ringOnDuty } from '../../actions/call-actions';
import { IProfile } from "../../models/profile";
import { type } from "os";
import { showAlert, showConfirm } from "../Dialogs";
import { IDictionary } from "../../models/dictionary";
import { IDictionaryState } from "../../states/dictionary-state";
import * as env from '../../app.json';
import Liked from '@material-ui/icons/FavoriteBorder';
import Like from '@material-ui/icons/Favorite';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCalendarAlt, faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import Calendar from "react-calendar";
import OutsideClick from "../Layouts/OutsideClick";
import { getSupportedCodeFixes } from "typescript";
import { IDoctorPriceFilter } from "../../models/doctor-price-filter";
import { Tooltip } from "@material-ui/core";
import { UserRole } from "../../models/user-role.enum";
import { ILoadingState, LoadingSubject } from '../../states/loading-state';
import Spinner from 'react-spinner-material';

export type DoctorsType = 'MyDoctors' | 'MyClininDoctors' | 'DutyDoctors';

interface MyProps {
    t: TFunction;
    i18n: i18n;
    auth: IAuthState;
    doctor: IDoctor;
    doctors: IDoctorState;
    doctorsType?: DoctorsType;
    dictionary: IDictionaryState;
    schedulingItems: ISchedulingItemsState;
    deletePatientDoctor: (
        id: number,
    ) => void;
    savePatientDoctor: (
        patientDoctor: IPatientDoctor,
    ) => void;
    saveSchedulingItemRecord: (
        item: ISchedulingItemRecord,
        filter: ISchedulingItemFilter,
    ) => void;
    getSchedulingItems: (
        filter: ISchedulingItemFilter
    ) => void;
    getSchedulingItemRecords: (
        filter: ISchedulingItemRecordFilter
    ) => void;
    getDoctor: (
        id: number
    ) => void;
    ringOnDuty: (schedulingItemId: number) => void;
    refreshDoctors: any;
    getDoctorPrice: (filter: IDoctorPriceFilter) => void;
    askDutyPrice: (filter: IDoctorPriceFilter, t: TFunction) => Promise<boolean>;
    loading: ILoadingState;
}

interface MyState {
    online: boolean;
    offline: boolean;
    itemVisitType: VisitType | undefined;
    selectedDay: any;
    selectedItem?: ISchedulingItem | null;
    showCalendar: boolean;
    showFullInfo: boolean;
    actionsOnDoctor: boolean;
}

class DoctorItem extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            online: false,
            offline: false,
            itemVisitType: undefined,
            selectedDay: {
                timestamp: moment(),
                dateString: moment().format('YYYY-MM-DD'),
            } as any,
            showCalendar: false,
            showFullInfo: false,
            actionsOnDoctor: false,
        };
    }

    componentDidMount() {
        this.sendRequestForList();
        this.getDoctorDetails(this.props.doctor);
    }

    filter = () => {
        const filter: ISchedulingItemFilter = {
            startFrom: moment(this.state.selectedDay.timestamp).startOf('day').toDate(),
            startTo: moment(this.state.selectedDay.timestamp).endOf('day').toDate(),
            asPatient: this.props.auth.userToken?.role === UserRole.Doctor,
            actual: false
        };

        return filter;
    }

    sendRequestForList = () => {
        const filter = this.filter();
        this.setState({ selectedItem: null });
        this.props.getSchedulingItems(filter as ISchedulingItemFilter);
        if (this.props.auth.userToken) {
            this.props.getSchedulingItemRecords(filter);
        }
    };

    getDoctorDetails = (doctor: IDoctor) => {
        const doctorFromCache = this.props.doctors.doctorDetails.get(doctor.id);
        if (!doctorFromCache) this.props.getDoctor(doctor.id);
    };

    checkAllowSchedule = () => {
        if (!this.props.auth.userToken) {
            showAlert(this.props.t('Please Sing in for appointment!'));
        }
        return !!this.props.auth.userToken;
    }

    changeDay = async (selectedDay: any) => {
        console.log('selectedDay changeDay', selectedDay);
        console.log('this.state.selectedDay', this.state.selectedDay);

        if (this.state.selectedDay !== selectedDay) {
            await this.setState({ selectedDay: selectedDay });
            console.log(this.state);
            this.sendRequestForList();
        }
    };

    filterSchedulingItems = (schedulingItems: ISchedulingItem[] | undefined) => {
        const { itemVisitType } = this.state;
        if (!schedulingItems) { return []; }
        return schedulingItems.filter((si: ISchedulingItem) => {
            return itemVisitType === undefined || (itemVisitType === VisitType.Clinic && si.offline) || (itemVisitType === VisitType.Online && si.online);
        });
    }

    changeItemVisitType = (visitType: VisitType) => {
        this.setState({ itemVisitType: visitType });
    }

    hideCalendarHandler = () => {
        this.setState({ showCalendar: false });
    }

    render() {
        const { t, doctor, doctorsType } = this.props;
        const { selectedDay, itemVisitType } = this.state;
        const schedulingItemsList = this.props.schedulingItems.schedulingItemsList;

        const visitType: { name: string, value: SchedulingItemRecordType }[] = [
            { name: 'In the clinic', value: SchedulingItemRecordType.Offline },
            { name: 'Online', value: SchedulingItemRecordType.Online }
        ];
        const doctorFromCache = this.props.doctors.doctorDetails.get(doctor.id);

        const patientDoctorList = this.props.doctors?.mydoctors;
        let doctorInMyDoctors = false;
        let deleteMyDoctorId: null | number = null;
        patientDoctorList && patientDoctorList.length && patientDoctorList.map((doc: any) => {
            if (doc?.id === doctor.id) {
                doctorInMyDoctors = true;
                deleteMyDoctorId = doc?.deleteMyDoctorId;
            }
        })

        const getSex = (sex: string | number): string => {
            switch (sex) {
                case 1:
                    return t('Female');
                    break;
                case 2:
                    return t('Male');
                    break;
                default:
                    return t('Male');
                    break;
            }
        }

        const height = document.getElementById('doctor-info')?.clientHeight;

        const selectItemHandler = (item: ISchedulingItem) => {
            this.props.getDoctorPrice({ doctorId: item.doctorDescId || 0 });
            this.setState({ selectedItem: item });
        }
        const price = this.props.auth.userToken?.role === UserRole.Doctor ? null : this.props.doctors.doctorPrice.find(p => p.clinicId === this.state.selectedItem?.clinicId);

        const deletePatientDoctorLoading = this.props.loading.subjects.has(LoadingSubject.DeletePatientDoctor);
        const savePatientDoctor = this.props.loading.subjects.has(LoadingSubject.SavePatientDoctor);

        console.log('doctorFromCache', doctorFromCache);

        return (
            <div>
                <div className='clinics-main-item'>
                    <div className='image-block'>
                        <div className='clinic-image-block'>
                            {this.props.auth?.userToken && this.props.auth?.userToken?.role === 3 &&
                                <div className='like_btn'
                                    onClick={async () => {
                                        if (doctorInMyDoctors) {
                                            const _delete = await showConfirm(t('Are you sure you want to delete the doctor?'));
                                            if (_delete && deleteMyDoctorId !== null) {
                                                this.setState({ actionsOnDoctor: true })

                                                await this.props.deletePatientDoctor(deleteMyDoctorId as number);
                                                await this.props.refreshDoctors();
                                            }
                                        }
                                        else {
                                            if (this.checkAllowSchedule()) {
                                                this.setState({ actionsOnDoctor: true })

                                                await this.props.savePatientDoctor(
                                                    { doctor_id: doctor?.id as number, description: '' });
                                                await this.props.refreshDoctors();
                                            }
                                        }
                                        this.setState({ actionsOnDoctor: false })
                                    }}
                                >
                                    {
                                        this.state.actionsOnDoctor ?
                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner radius={17} color={"#03A8F5"} stroke={3} visible={true} /></div>
                                            :
                                            doctorInMyDoctors ? <Liked /> : <Like />
                                    }
                                </div>
                            }
                            <img className='clinic-default-img'
                                src={doctor?.imageFile ? env.uploads + 'doctors/' + doctor?.imageFile :
                                    require('./../../images/doctor-default-img.png')}
                                alt='doctor-default-img' />
                        </div>
                        <div className='clinic-rating-block'>
                            <DoctorRatings doctor={doctorFromCache} />
                        </div>
                    </div>
                    <div className={this.state.showFullInfo ? 'doctor-info-block full' : 'doctor-info-block'} id='doctor-info'>
                        {doctorFromCache?.specializations && doctorFromCache?.specializations != 'None' && <p className='regular-text bold'>{doctorFromCache.specializations.join(', ')}</p>}
                        <p className='doctor-title-1'>
                            {
                                doctorFromCache && doctorFromCache?.surname
                                + ' ' +
                                doctorFromCache?.name
                                + ' ' +
                                doctorFromCache?.patronymic
                            }
                        </p>
                        <div className='about-doctor'>
                            <p className='regular-text doctor-card'>
                                {t('Experience') + ' '}{doctor?.work_experience} {' ' + t('years')}
                                {' • ' + getSex(doctor.sex)}
                            </p>
                        </div>
                        <div>
                            {!!doctor?.scientific_degree &&
                                <p className='regular-text'>
                                    {t('Degree') + ': ' + this.props.dictionary.academicDegree?.filter((a) => a.id == doctor?.scientific_degree).map(a => a.name).join(', ')}
                                </p>
                            }
                            {!!doctorFromCache?.education &&
                                <p className='regular-text'>
                                    {t('Education') + ': ' + doctorFromCache?.education}
                                </p>
                            }
                            {!!doctorFromCache?.training_work_abroad &&
                                <p className='regular-text'>
                                    {t('Training/work abroad') + ': ' + doctorFromCache?.training_work_abroad}
                                </p>
                            }
                            {!!doctorFromCache?.language &&
                                <p className='regular-text'>
                                    {t('Languages') + ': ' + doctorFromCache?.language}
                                </p>
                            }
                        </div>
                        <div className='clinics-block'>
                            {doctorFromCache && doctorFromCache.works && doctorFromCache.works != 'None' &&
                                <div className='procedures-block'>
                                    <p className='doctor-title-2'>{t('Hospitals')}:</p>
                                    <div className='item-list-block'>
                                        {doctorFromCache.works.map(p => {
                                            console.log('p', p);
                                            return (

                                                <p className='regular-text doctor-card'>• {p['Clinic ID']}</p>
                                            )
                                        })}
                                    </div>
                                </div>}
                        </div>
                        {doctorFromCache && doctorFromCache.diseases !== 'None' &&
                            <div className='procedures-block'>
                                <p className='doctor-title-2'>{t('Diseases')}:</p>
                                <div className='item-list-block'>
                                    {doctorFromCache.diseases.map((p) => (<p className='regular-text doctor-card'>• {p}</p>))}
                                </div>
                            </div>}
                        {doctorFromCache && doctorFromCache.procedures !== 'None' &&
                            <div className='procedures-block'>
                                <p className='doctor-title-2'>{t('Procedures')}:</p>
                                <div className='item-list-block'>
                                    {doctorFromCache.procedures.map((p) => (<p className='regular-text doctor-card'>• {p}</p>))}
                                </div>
                            </div>}
                        {height && height >= 420 &&
                            <div className='show_more_doctor_description' onClick={() => {
                                this.setState({ showFullInfo: !this.state.showFullInfo })
                            }}>
                                <p>{this.state.showFullInfo ? t('Minimize') : t('Show more')}</p>
                            </div>
                        }
                    </div>
                    <div className='record-time-block'>
                        {doctorsType === 'DutyDoctors' &&
                            <button className="delete-doctor-btn"
                                onClick={async () => {
                                    let usrData = this.props.auth.self as IProfile;
                                    if (usrData.birthdate && usrData.blood_type && usrData.name && usrData.sex !== null) {
                                        if (!await this.props.askDutyPrice({ schedulingItemId: doctor.schedulingItemId as number } as IDoctorPriceFilter, this.props.t)) {
                                            return;
                                        }
                                        this.props.ringOnDuty(doctor.schedulingItemId as number);
                                    } else {
                                        console.error('Please fill in all fields in your profile!');
                                    }
                                }}>{t('Call')}</button>
                        }

                        <p className='regular-text'>{t('Choose the appointment time')}</p>
                        <ButtonGroup toggle>
                            <div className='change-day-block'>
                                <button className='change-day-block__nav-button left'
                                    onClick={() => {
                                        if (selectedDay.dateString !== moment().format('YYYY-MM-DD')) {
                                            let selecDay = selectedDay;

                                            selecDay.timestamp = selecDay.timestamp.subtract(1, 'days');
                                            selecDay.dateString = selecDay.timestamp.format('YYYY-MM-DD');

                                            this.changeDay({ ...selecDay });
                                        }
                                    }}
                                ><FontAwesomeIcon size='lg' icon={faCaretLeft} /></button>

                                <div>{moment(selectedDay.dateString).format("DD MMMM")}</div>

                                <button className='change-day-block__nav-button right'
                                    onClick={() => {
                                        let selecDay = selectedDay;
                                        selecDay.timestamp = selecDay.timestamp.add(1, 'days');
                                        selecDay.dateString = selecDay.timestamp.format('YYYY-MM-DD');
                                        this.changeDay({ ...selecDay });
                                    }}
                                ><FontAwesomeIcon size='lg' icon={faCaretRight} /></button>

                                <div className='change-day-block__calendar-button'>
                                    <OutsideClick clickHandler={this.hideCalendarHandler}>
                                        {this.state.showCalendar &&
                                            <Calendar
                                                onChange={(e) => {
                                                    this.changeDay({ ...{ timestamp: moment(e as Date), dateString: moment(e as Date).format('YYYY-MM-DD') } });
                                                    this.setState({ showCalendar: false })
                                                }}
                                                value={new Date(selectedDay.dateString)}
                                            />}
                                        <button className='change-day-block__nav-button'
                                            onClick={() => this.setState({ showCalendar: !this.state.showCalendar })}>
                                            <FontAwesomeIcon className='calendar-button' icon={faCalendarAlt} size='lg' />
                                        </button>
                                    </OutsideClick>
                                </div>
                            </div>
                        </ButtonGroup>
                        <div className='time-buttons-block'>
                            {
                                this.filterSchedulingItems(schedulingItemsList).map((itm: ISchedulingItem) => {
                                    const start = moment(itm.start).format('HH:mm');
                                    const end = moment(itm.end).format('HH:mm');
                                    if (itm.doctorId === doctor.userId && itm.patientId === null) {
                                        if (
                                            (moment().format('YYYY-MM-DD') === moment(itm.start).format('YYYY-MM-DD')) &&
                                            (moment().valueOf() <= moment(itm.start).valueOf())
                                        ) {
                                            return (
                                                <div className={'time-button' + (itm.id === this.state.selectedItem?.id ? ' time-button-selected' : '')} onClick={() => selectItemHandler(itm)}>{start} - {end}</div>
                                            )
                                        }
                                        else if (moment().format('YYYY-MM-DD') !== moment(itm.start).format('YYYY-MM-DD')) {
                                            return (
                                                <div className={'time-button' + (itm.id === this.state.selectedItem?.id ? ' time-button-selected' : '')} onClick={() => selectItemHandler(itm)}>{start} - {end}</div>
                                            )
                                        }
                                    }
                                })
                            }
                        </div>

                        {!!this.state.selectedItem && <div className='visit-type-block'>
                            <p className='doctor-title-2'>{t('Making an appointment')}:</p>
                            <div className='visit-buttons-block'>
                                <Tooltip interactive arrow title={price ? `${t('Primary appointment price')}: ${price?.firstOffline} ₼ ${t('Secondary appointment price')}: ${price?.secondOffline} ₼` : ''}>
                                    <button
                                        disabled={this.state.selectedItem ? !this.state.selectedItem.offline : false}
                                        type="button"
                                        className={this.state.selectedItem && !this.state.selectedItem.offline ?
                                            "disabled-btn-appointment" : "blue-btn btn btn-primary"
                                        }
                                        onClick={async () => {
                                            const itm = this.state.selectedItem;
                                            if (!itm) {
                                                showAlert(t('Please, select appointment date and time'));
                                                return;
                                            }
                                            if (this.checkAllowSchedule()) {
                                                const _save = await showConfirm(
                                                    t('Are you sure you want to make an appointment from {{start}} to {{end}}?', {
                                                        start: moment(itm.start).format("HH:mm"),
                                                        end: moment(itm.end).format("HH:mm") + " " + moment(itm.start).format("LL")
                                                    }), {
                                                    title: this.props.auth.userToken?.role === UserRole.Doctor ? '' : `${t('Primary appointment price')}: ${price?.firstOffline} AZN, ${t('Secondary appointment price')}: ${price?.secondOffline} AZN`,
                                                    warning: this.props.auth.userToken?.role === UserRole.Doctor ? '' : 'secondary_desc'
                                                }
                                                );
                                                if (_save) {
                                                    this.props.saveSchedulingItemRecord({
                                                        schedulingItemId: itm.id as number,
                                                        status: SchedulingItemRecordStatus.Created,
                                                        type: visitType[0].value
                                                    }, this.filter());
                                                }
                                            }
                                        }}>
                                        {t('In the clinic')}
                                        <p className={!price ? 'hide-price' : ''}>
                                            {price && `${price.firstOffline} ₼ / ${price.secondOffline} ₼`}
                                        </p>
                                    </button>
                                </Tooltip>
                                <Tooltip interactive arrow title={price ? `${t('Primary appointment price')}: ${price?.firstOnline} ₼ ${t('Secondary appointment price')}: ${price?.secondOnline} ₼` : ''}>
                                    <button
                                        disabled={this.state.selectedItem ? !this.state.selectedItem.online : false}
                                        type="button"
                                        className={this.state.selectedItem && !this.state.selectedItem.online ?
                                            "disabled-btn-appointment" : "blue-btn btn btn-primary dark"
                                        }
                                        onClick={async () => {
                                            const itm = this.state.selectedItem;
                                            if (!itm) {
                                                showAlert(t('Please, select appointment date and time'));
                                                return;
                                            }
                                            if (this.checkAllowSchedule()) {
                                                const _save = await showConfirm(
                                                    t('Are you sure you want to make an appointment from {{start}} to {{end}}?', {
                                                        start: moment(itm.start).format("HH:mm"),
                                                        end: moment(itm.end).format("HH:mm") + " " + moment(itm.start).format("LL")
                                                    }), {
                                                    title: this.props.auth.userToken?.role === UserRole.Doctor ? '' : `${t('Primary appointment price')}: ${price?.firstOnline} AZN, ${t('Secondary appointment price')}: ${price?.secondOnline} AZN`,
                                                    warning: this.props.auth.userToken?.role === UserRole.Doctor ? '' : 'secondary_desc'
                                                }
                                                );
                                                if (_save) {
                                                    this.props.saveSchedulingItemRecord({
                                                        schedulingItemId: itm.id as number,
                                                        status: SchedulingItemRecordStatus.Created,
                                                        type: visitType[1].value
                                                    }, this.filter());
                                                }
                                            }
                                        }}>
                                        {t('Online')}
                                        <p className={!price ? 'hide-price' : ''}>
                                            {price && `${price.firstOnline} ₼ / ${price.secondOnline} ₼`}
                                        </p>
                                    </button>
                                </Tooltip>
                            </div>
                        </div>}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    doctors: state.doctor,
    dictionary: state.dictionary,
    schedulingItems: state.schedulingItems,
    schedulingItemRecords: state.schedulingItemRecords,
    loading: state.loading,
});

const mapDispatchToProps = {
    deletePatientDoctor,
    savePatientDoctor,
    saveSchedulingItemRecord,
    getSchedulingItems,
    getSchedulingItemRecords,
    getDoctor,
    ringOnDuty,
    getDoctorPrice,
    askDutyPrice,
};

let DoctorsItemComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    DoctorItem
);

export default withTranslation()(DoctorsItemComponentContainer);