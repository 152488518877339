import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Download from '../Download/Download';
import './MobileHome.scss';

interface MyProps {
}

interface MyState {
}

class MobileHomeComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <Router>
                <Route path='/download' component={Download} />
                <Route>
                    <header className='mobile-home_header'>
                        <img className='mobile-home_header_logo' src={require('./../../images/logo.png')} alt='logo' />
                        <span className='mobile-home_header_text'>VideoDoktor</span>
                    </header>
                    <div className='mobile-home_main'>
                        <div>
                            <h5>Скачивайте наше мобильное приложение<br/>VideoDoktor</h5>
                            <span>Проверенные врачи у вас в телефоне</span>
                        </div>
                        <img className='mobile-home_main_image' src={require('./../../images/mobile.png')} />
                    </div>
                    <div className='mobile-home_container'>
                        <div className='mobile-home_container_appstore'>
                            <a href='https://apps.apple.com/ru/app/video-doktor/id1520369601' target='__blank' ><img src={require('./../../images/appstore.png')} /></a>
                        </div>
                        <div className='mobile-home_container_playmarket'>
                            <a href='https://play.google.com/store/apps/details?id=az.ezgil.videodoctor' target='__blank'><img src={require('./../../images/playmarket.png')} /></a>
                        </div>
                    </div>
                    <div className='mobile-home_container'>
                        <div className='mobile-home_container_contacts'>
                            <div>Звоните нам:</div>
                            <div style={{width: '100%'}}>(+994)51-206-38-13<br/>(+994)51-206-38-13</div>
                        </div>
                        <div className='mobile-home_container_contacts'>
                            <div>Пишите нам:</div>
                            <div style={{width: '100%'}}>ezgilmedical@gmail.com<br/>info@ezgil.az</div>
                        </div>
                    </div>
                    <div className='mobile-home_partners'>
                        <img className='mobile-home_partners_sn' src={require('./../../images/sn.png')} />
                        <img className='mobile-home_partners_isim' src={require('./../../images/isim.png')} />
                        <img className='mobile-home_partners_undp' src={require('./../../images/undp.png')} />
                    </div>
                    <div className='mobile-home_footer'>
                        <img src={require('./../../images/ezgil.png')} />
                    </div>
                </Route>
                
            </Router>
        )
    }
}


export default MobileHomeComponent