import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors, formValueSelector } from 'redux-form';
import TextInput from './../FormComponents/TextInput';
import { IDoctor_2_Step } from "../../models/doctor-2-step";
import { IFile } from '../../models/file';
import { RootState } from '../../store';
import FilesComponent from './../FormComponents/FilesComponent';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';

type Props = {
    t: TFunction
    type: number;
    files: IFile[];
}

class RegistrationFormDoctor2Step extends React.Component<InjectedFormProps<IDoctor_2_Step> & { isChecked: boolean } & Props> {

    render() {
        const { handleSubmit, isChecked, t } = this.props;

        return (
            <div className='auth-form auth-form-flex'>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field
                            name="uploadfilediplom"
                            label={t('Diploma photo')}
                            doctorRegistration={true}
                            files={this.props.files}
                            type={'uploadfilediplom/'}
                            placeholder={t('Choose the photo')}
                            component={FilesComponent}
                        />
                    </div>
                    <div className='auth-form__col'>
                        <Field
                            name="uploadfilecertificate"
                            label={t('Certificate photo')}
                            doctorRegistration={true}
                            files={this.props.files}
                            type={'uploadfilecertificate/'}
                            placeholder={t('Choose the photo')}
                            component={FilesComponent}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const validate = (values: IDoctor_2_Step, props: InjectedFormProps<IDoctor_2_Step>): FormErrors<IDoctor_2_Step> => {
    const errors: FormErrors<IDoctor_2_Step> = {};
    return errors;
};

const form = reduxForm<IDoctor_2_Step, any>({
    form: 'RegistrationFormDoctor2Step',
    validate,
})(withTranslation()(RegistrationFormDoctor2Step));

const selector = formValueSelector('RegistrationFormDoctor2Step');
const mapStateToProps = (state: RootState) => {
    const type = selector(state, 'type') || null;
    return { type }
};

export default connect(mapStateToProps)(form);