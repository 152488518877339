import React, { Component } from 'react';
import { connect } from "react-redux";
import { submit, FormAction, initialize } from "redux-form";
import { RootState } from "../../store";
import './PersonalCabinetDoctor.scss';
import { signIn, signUp, doChange, changePassword } from './../../actions/auth-actions';
import { IAuthState } from '../../states/auth-state';
import PersonalCabinetDoctorForm from '../../forms/PersonalCabinetDoctorForm';
import { Link } from 'react-router-dom';
import { signOut } from '../../actions/auth-actions';
import QuickAccessComponentContainer from './../QuickAccess/QuickAccess';
import { IProfile } from '../../models/profile';
import { IDoctor } from '../../models/doctor';
import { withTranslation, WithTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PersonalCabinetPasswordForm from '../../forms/PersonalCabinetPasswordForm';
import DescriptionIcon from '@material-ui/icons/Description';

interface MyProps {
    submit: (form: string) => FormAction;
    auth: IAuthState;
    signOut: (token: string) => void;
    doChange: (user: IProfile, token: string) => void;
    changePassword: (token: string, user: IProfile | IDoctor) => void;
    initialize: (form: string, data: any, keepDirty?: boolean) => void;
}

interface MyState {
    editMode: boolean;
    changePasswordMode: boolean;
    fields?: { [name: string]: string }
    selectedIndex: number;
}

class PersonalCabinetDoctorComponent extends Component<MyProps & WithTranslation, MyState> {

    constructor(props: MyProps & WithTranslation) {
        super(props);
        this.state = {
            editMode: false,
            changePasswordMode: false,
            selectedIndex: 0
        };
    }

    onSubmit(data: IProfile) {
        console.log('user', data);
        if (!this.state.changePasswordMode) {
            this.props.doChange(data, this.props.auth.userToken?.auth as string);
        }
        else {
            this.props.changePassword(this.props.auth.userToken?.auth as string, data);
            this.setState({ changePasswordMode: !this.state.changePasswordMode });
        }
        this.setState({ editMode: false, changePasswordMode: false });
    };

    onEdit() {
        if (this.state.editMode) this.props.submit('PersonalCabinetDoctorForm');
        this.setState({ editMode: !this.state.editMode });
    }

    onEditPass() {
        if (this.state.changePasswordMode) {
            this.props.submit('PersonalCabinetPasswordForm');
        } else {
            this.props.initialize('PersonalCabinetPasswordForm', {});
            this.setState({ changePasswordMode: !this.state.changePasswordMode });
        }
    }

    render() {
        const { t } = this.props;

        console.log('doc cab props', this.props.auth)
        return (
            <>
                <div className='cabinet-wrapper-doc'>
                    <Tabs value={this.state.selectedIndex} onChange={(_, v) => this.setState({ selectedIndex: v })}>
                        <Tab label={t('My profile')} {...a11yProps(0)} />
                        <Tab label={t('My data')} {...a11yProps(1)} />
                    </Tabs>

                    <TabPanel value={this.state.selectedIndex} index={1}>
                        <PersonalCabinetDoctorForm
                            uploadfileimage={this.props.auth.self?.imageFile}
                            editMode={this.state.editMode}
                            onSubmit={(data: IProfile) => this.onSubmit(data)}
                        />
                        {this.state.changePasswordMode &&
                            <PersonalCabinetPasswordForm
                                onSubmit={(data: IProfile) => this.onSubmit(data)} />
                        }
                        <div className='bottom-block'>
                            {/* <Link to='/medical-card'>Моя медицинская карта</Link> */}
                            {this.state.changePasswordMode &&
                                <button onClick={() => this.setState({ changePasswordMode: false })} className='blue-btn btn btn-primary'>{t('Cancel')}</button>
                            }
                            {/* {this.state.editMode && */}
                            <button onClick={() => this.onEditPass()} className={'blue-btn btn' + (this.state.changePasswordMode ? ' dark' : '') + ' btn-primary'}>{t('Change Password')}</button>
                            {/* }  */}
                            {/* <div className='show-more-btn'
                                onClick={() => this.onEdit()}
                            >
                                <p className='show-more-btn-title'>{this.state.editMode ? t('Save') : t('Edit')}</p>
                            </div> */}

                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.selectedIndex} index={0}>
                        <div className='my-profile'>
                            <div className='features-block'>

                                <Link to={'/doctor-duty'} className='item'>
                                    <img src={require('./../../images/doctor-cabinet/my-shifts.png')} />
                                    <div>
                                        <h5>{t('My duties')}</h5>
                                        <p>{t('In this section, you can choose the hours of your duty.')}</p>
                                    </div>
                                </Link>

                                <Link to='/online-appointment' className='item'>
                                    <img src={require('./../../images/doctor-cabinet/my-video-appointments.png')} />
                                    <div>
                                        <h5>{t('My online receptions')}</h5>
                                        <p>{t('In this section, you can conduct all your consultations online, also chat with patients.')}</p>
                                    </div>
                                </Link>

                                <Link to='/doctor/schedule-details' className='item'>
                                    <img src={require('./../../images/doctor-cabinet/my-schedule.png')} />
                                    <div>
                                        <h5>{t('My schedule')}</h5>
                                        <p>{t('In this section, you can create and keep track of your schedule, schedule and cancel consultations with patients and colleagues.')}</p>
                                    </div>
                                </Link>

                                <Link className='item' to={'/doctors'}>
                                    <img src={require('./../../images/doctor-cabinet/consultation-with-colleague.png')} />
                                    <div>
                                        <h5>{t('Doctor-to-Doctor')}</h5>
                                        <p>{t('In this section, you can consult with colleagues online.')}</p>
                                    </div>
                                </Link>

                                <Link to='/profile' className='item'>
                                    <img src={require('./../../images/doctor-cabinet/information.png')} />
                                    <div>
                                        <h5>{t('Information about me')}</h5>
                                        <p>{t('In this section, you can change information about you visible to another doctors and patients.')}</p>
                                    </div>
                                </Link>

                                <Link to='/doctors-user-access' className='item'>
                                    <img src={require('./../../images/doctor-cabinet/doctor-access.png')} />
                                    <div>
                                        <h5>{t('Access for doctors')}</h5>
                                        <p>{t('In this section you can view the medical records of your patients who have given you access to their data.')}</p>
                                    </div>
                                </Link>

                            </div>

                        </div>
                    </TabPanel>
                </div>

                <QuickAccessComponentContainer />

            </>
        );

    }
}


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        value: index,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}
const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

const mapDispatchToProps = {
    submit,
    signIn,
    signUp,
    signOut,
    doChange,
    changePassword,
    initialize,
};

let PersonalCabinetDoctorComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(PersonalCabinetDoctorComponent)
);

export default PersonalCabinetDoctorComponentContainer