import { IUser } from "../models/user";
import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator } from "redux";
import { IUserToken } from "../models/user-token";
import { loadingEnd, loadingStart } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import { IProfile } from "../models/profile";
import { IUserSignUpRequest } from "../models/user-sign-up-request";
import * as environment from './../app.json';
import { UserRole } from "../models/user-role.enum";
import { IDoctor } from "../models/doctor";
import i18n from 'i18next';
import { LoginStage } from "../states/auth-state";
import { RootState } from "../store";
import history from "./../history";
import { IDoctor_1_Step } from "../models/doctor-1-step";
import { IDoctor_2_Step } from "../models/doctor-2-step";
import { UserStatus } from "../models/user-status.enum";
import { startPinging } from "./call-actions";
import { connect } from "@giantmachines/redux-websocket/dist";
import { showAlert, showConfirm } from "./../components/Dialogs";
import { base32Decode } from '@ctrl/ts-base32';
import { IClinic } from "../models/clinic";

const TOKEN_LIFE_TIME = 4 * 60 * 1000;

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language == 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

function phone(login: string) {
    if (login) {
        if (login.startsWith('0')) {
            return '+994' + login.substring(1);
        } else {
            if (!login.startsWith('+')) {
                return '+994' + login;
            }
        }
    }
    return login;
}

export const SIGN_IN = 'SIGN_IN';
export interface SignInAction {
    type: typeof SIGN_IN;
    userToken: IUserToken;
}

export const _signIn: ActionCreator<SignInAction> = (userToken: IUserToken) => {
    return {
        type: SIGN_IN,
        userToken
    };
};

export const TOGGLE_AUTH = 'TOGGLE_AUTH';
interface ToggleAuthAction {
    type: typeof TOGGLE_AUTH;
    value: boolean;
}

export const toggleAuth: ActionCreator<ToggleAuthAction> = (value: boolean) => {
    return {
        type: TOGGLE_AUTH,
        value,
    };
};

export const SIGN_OUT = 'SIGN_OUT';
interface SignOutAction {
    type: typeof SIGN_OUT;
}

export const _signOut: ActionCreator<SignOutAction> = () => {
    return {
        type: SIGN_OUT
    };
};

export const PRE_SIGN_IN = 'PRE_SIGN_IN';
interface PreSignInAction {
    type: typeof PRE_SIGN_IN;
    userToken: IUserToken;
}

export const _preSignIn: ActionCreator<PreSignInAction> = (userToken: IUserToken) => {
    return {
        type: PRE_SIGN_IN,
        userToken
    };
};

export const SIGN_UP = 'SIGN_UP';
interface SignUpAction {
    type: typeof SIGN_UP;
}

const _signUp: ActionCreator<SignUpAction> = () => {
    return {
        type: SIGN_UP,
    };
};

export const USER_BY_ID = 'USER_BY_ID';
interface UserByIdAction {
    type: typeof USER_BY_ID;
    userProfileById: IProfile
}

const _userById: ActionCreator<UserByIdAction> = (userProfileById: IProfile) => {
    return {
        type: USER_BY_ID,
        userProfileById
    };
};

export const PROCESS_STAGE = 'PROCESS_STAGE';
interface ProcessStageAction {
    type: typeof PROCESS_STAGE;
    processStage: number,
}

export const processStageChange: ActionCreator<ProcessStageAction> = (processStage: number) => {
    return {
        type: PROCESS_STAGE,
        processStage,
    };
};

export const SELF_PROFILE = 'SELF_PROFILE';
interface FetchSelfAction {
    type: typeof SELF_PROFILE;
    self: IProfile;
}

const _fetchSelf: ActionCreator<FetchSelfAction> = (self: IProfile) => {
    return {
        type: SELF_PROFILE,
        self,
    };
};

export const SAVE_USER = 'SAVE_USER';
interface SaveUserAction {
    type: typeof SAVE_USER;
    user: IUser;
}

const saveUser: ActionCreator<SaveUserAction> = (user: IUser) => {
    return {
        type: SAVE_USER,
        user,
    };
};

export const AUTH_ERROR_TEXT = 'AUTH_ERROR_TEXT';
interface authErrorTextAction {
    type: typeof AUTH_ERROR_TEXT;
    text: string;
}

export const authErrorText: ActionCreator<authErrorTextAction> = (text: string) => {
    return {
        type: AUTH_ERROR_TEXT,
        text,
    };
};

// ---- new doctor

export const NEW_DOCTOR = 'NEW_DOCTOR';
interface NewDoctorAction {
    type: typeof NEW_DOCTOR;
}

export const _newDoctor: ActionCreator<NewDoctorAction> = () => {
    return {
        type: NEW_DOCTOR,
    };
};

export const NEW_DOCTOR1 = 'NEW_DOCTOR1';
interface NewDoctor1Action {
    type: typeof NEW_DOCTOR1;
}

const _newDoctor1: ActionCreator<NewDoctor1Action> = () => {
    return {
        type: NEW_DOCTOR1,
    };
};

export const NEW_DOCTOR2 = 'NEW_DOCTOR2';
interface NewDoctor2Action {
    type: typeof NEW_DOCTOR2;
}

const _newDoctor2: ActionCreator<NewDoctor2Action> = () => {
    return {
        type: NEW_DOCTOR2,
    };
};

// ---- new doctor
export const SAVE_OPERATION = 'SAVE_OPERATION';
interface SaveOperationAction {
    type: typeof SAVE_OPERATION;
    operation: string;
    hasEmail: boolean;
}

const saveOperation: ActionCreator<SaveOperationAction> = (operation: string, hasEmail: boolean) => {
    return {
        type: SAVE_OPERATION,
        operation,
        hasEmail,
    };
};
export type AuthActionTypes = ToggleAuthAction | NewDoctorAction | NewDoctor1Action | NewDoctor2Action | PreSignInAction | SignInAction | SignOutAction | SignUpAction | FetchSelfAction | ProcessStageAction | SaveUserAction | SaveOperationAction | UserByIdAction | authErrorTextAction;

export const errorMsgSignUp = (code: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    if (code === 2020 || code === 107) {
        dispatch(authErrorText('Username already taken'));
    }
    else if (code === 2021 || code === 108) {
        dispatch(authErrorText('Email address already taken'));
    }
    else if (code === 2025 || code === 108) {
        dispatch(authErrorText('Email address registered, but not verified'));
    }
    else if (code === 2022) {
        dispatch(authErrorText('Phone number already taken'));
    }
    else dispatch(authErrorText('Registration error'));
}

export const errorMsgLogin = (code: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    if (code === 1010) {
        dispatch(authErrorText('Incorrect Login or Password'));
    }
    else if (code === 2025) {
        dispatch(authErrorText('Email address registered, but not verified'));
        dispatch(processStageChange(LoginStage.EmailVerification));
    }
    else if (code === 2026) {
        dispatch(authErrorText('Phone number registered, but not verified'));
        dispatch(processStageChange(LoginStage.PhoneVerification));
    }
    else dispatch((authErrorText('Login error')));
}

export const signIn = (
    user: IUser, closeOnSuccess: any
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SignIn));
    console.log('user', user);
    try {
        dispatch(saveUser((user)));
        user.phone = phone(user.login);
        const response = await fetch(environment.auth + 'signIn?longToken=true', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                // 'Device-Id': '',
                // 'Device-Type': ''
            },
            body: JSON.stringify(user),
        });

        const json = await response.json();
        if (response.status === 200) {
            const infoResponse = await fetch(environment.baseUrl + 'external/private/user/info', {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: json.auth,
                }
            });
            if (infoResponse.status !== 200) {
                console.warn(infoResponse);
                dispatch(authErrorText('Unknown error. Please, check internet connection'));
                return;
            }
            const info = await infoResponse.json();
            json.role = info.role;
            json.selfId = info.id;
            json.status = info.status;
            json.clientId = info.clientId;

            if (info.role === UserRole.Doctor && info.status !== UserStatus.Verified) {
                dispatch(_signIn());
                dispatch(_preSignIn(json))
                dispatch(processStageChange(LoginStage.VerifyPhoneSuccess));
            }
            else {
                dispatch(_preSignIn())
                localStorage.setItem('az.ezgil.videodoctor.token', JSON.stringify(json));
                dispatch(_signIn(json));
                dispatch(fetchSelf(json.auth, json.role));
                dispatch(processStageChange(LoginStage.VerifyPhoneSuccess));
                dispatch(startPinging());
                dispatch(connect(environment.wsHost));
                dispatch(toggleAuth(false));
            }
        }
        else if (response.status === 401) {
            dispatch(authErrorText('Incorrect Login or Password'));
        }
        else {
            dispatch(errorMsgLogin(json.code));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SignIn));
    }
}

export const signOut = (
    token: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SignOut));
    try {
        const response = await authFetch(getState, dispatch, environment.auth + 'signOut', 'POST')
        localStorage.removeItem('az.ezgil.videodoctor.token');
        dispatch(_signOut());
        history.push('/');
        if (response.status !== 200) {
            dispatch(authErrorText('Logout error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SignOut));
    }
}

export const signUp = (
    user: IUserSignUpRequest,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SignUp));

    try {
        if (user.phone) {
            if (user.phone.length == 9) {
                user = { ...user, phone: '+994' + user.phone };
            }
            else if (user.phone.length > 0) {
                dispatch(authErrorText('Wrong phone number format!'));
                console.log(user.phone);
                return;
            }
        }

        const response = await fetch(topDoctorData() + 'users', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ phone: user.phone, username: user.login, email: user.email, password: user.password, language: null }),
        });

        const json = await response.json();

        if (response.status === 200) {
            if (json.success) {
                dispatch(saveUser(user));
                dispatch(processStageChange(LoginStage.PhoneVerification));
                return;
            }
        }
        console.log('response', json);
        dispatch(errorMsgSignUp(json.code));
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SignUp));
    }
};

export const fetchSelf = (
    token: string,
    role: UserRole,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    console.log('fetchSelf role', role);
    console.log("fetchSelf", topDoctorData() + `${role == UserRole.Patient ? 'users/profile' : 'doctors/info'}?token=` + encodeURIComponent(token));
    try {
        const response = await fetch(topDoctorData() + `${role == UserRole.Patient ? 'users/profile' : 'doctors/info'}?token=` + encodeURIComponent(token), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });
        if (response.status === 200) {
            const json = await response.json();
            console.log('json self', json);
            dispatch(_fetchSelf(json?.payload));
        } else {
            console.log('response', response);
            dispatch(authErrorText('Error getting data'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    }
};

export const getUserById = (
    id: number,
    token: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.UserById));
    dispatch(_userById(undefined));
    try {
        const response = await fetch(topDoctorData() + 'users/sprofile?id=' + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const json = await response.json();
        if (response.status === 200) {
            dispatch(_userById(json?.payload));
        } else {
            dispatch(authErrorText('Error getting data'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.UserById));
    }
};

export const restorePassword = (
    emailOrLogin: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.RestorePswd));
    try {
        const response = await fetch(environment.auth + 'restorePassword', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: emailOrLogin,
                email: emailOrLogin,
                phone: phone(emailOrLogin),
            }),
        });
        const json = await response.json();
        console.warn('json', json);

        if (response.status === 200) {
            dispatch(saveUser({ login: emailOrLogin }));
            dispatch(saveOperation(json.operation, json.hasEmail));
            dispatch(processStageChange(6));
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.RestorePswd));
    }
};

const arrayBufferToString = async (buffer): Promise<string> => {
    return new Promise((resolve, reject) => {
        var blob = new Blob([buffer], { type: 'text/plain' });
        var reader = new FileReader();
        reader.onload = function (evt) {
            if (evt.target?.result) {
                resolve(evt.target.result as any);
            } else {
                reject('');
            }
        };
        reader.onerror = function (e) {
            reject('');
        }
        reader.readAsText(blob, 'UTF-8');
    })
}

export const verfyFromParams = (
    userdata: string,
    code: string,
    operation: string | null,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.VerifyEmail));
    try {
        const base32 = base32Decode(userdata, 'Crockford');
        const params = JSON.parse(await arrayBufferToString(base32));
        if (params) {
            if (params.email) {
                dispatch(saveUser({ login: params.email }));
                dispatch(processStageChange(2));
                dispatch(toggleAuth(true));
                dispatch(verifyEmail(params.email, code));
                return;
            } else if (params.type && params.type.indexOf('restore_by_') > -1 && operation) {
                dispatch(saveUser({ login: params.type.substring(11) }));
                dispatch(processStageChange(6));
                dispatch(toggleAuth(true));
                dispatch(saveOperation(operation, true));
                dispatch(verifyOperation(operation, code));
                return;
            }
        }
    } catch (e) {
        dispatch(loadingEnd(LoadingSubject.VerifyEmail));
    }
    await showAlert('Unknown error. Please, check internet connection');
};

export const verifyOperation = (
    operation: string,
    code: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.VerifyEmail));
    try {
        console.warn('operation', operation);
        console.warn('code', code);
        const response = await fetch(`${environment.auth}verifyOperation?operation=${operation}&code=${code}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: '{}',
        });

        const json = await response.json();
        console.warn('json', json);
        console.warn('response', response);
        if (response.status === 200) {
            //что-то еще, наверно, надо будет тут делать
            if (json.status === 1) {
                dispatch(authErrorText('Code expired'));
            }
            else dispatch(processStageChange(7));
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.VerifyEmail));
    }
};

export const restoreChangePassword = (
    operation: string,
    user: IUser
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.ChangePswd));
    try {
        console.warn('operation', operation);
        console.warn('user', user);
        const response = await fetch(`${environment.auth}restoreChangePassword?operation=${operation}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                login: user.login || user.email,
                email: user.email || user.login,
                phone: phone(user.email || user.login),
                password: user.password,
            }),
        });

        const json = await response.json();
        console.warn('json', json);
        console.warn('response', response);
        if (response.status === 200) {
            dispatch(processStageChange(8));
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.ChangePswd));
    }
};

export const verifyEmail = (
    username: string,
    code: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.VerifyEmail));
    try {
        const response = await fetch(`${environment.auth}verifyEmail?username=${encodeURIComponent(username)}&code=${code}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: '{}',
        });

        const json = await response.json();
        if (response.status === 200) {
            if (json.status === 1) {
                dispatch(authErrorText('Code expired'));
            }
            else dispatch(processStageChange(LoginStage.VerifyEmailSuccess));
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.VerifyEmail));
    }
};

export const verifyPhone = (
    username: string,
    code: string,
    next: LoginStage = LoginStage.PhoneVerification,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.VerifyPhone));
    try {
        const response = await fetch(`${environment.auth}verifyPhone?username=${encodeURIComponent(username)}&phone=${encodeURIComponent(phone(username))}&code=${code}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: '{}',
        });

        const json = await response.json();
        // console.log('json', json);
        // console.log('response', response);
        // console.log('next', next)
        if (response.status === 200) {
            if (json.status === 1) {
                dispatch(authErrorText('Code expired'));
            }
            else {
                dispatch(processStageChange(LoginStage.VerifyPhoneSuccess));
            }
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.VerifyPhone));
    }
};

export const resendVerification = (
    emailOrUsername: string,
    subject: string,
    operation: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
        let url = '';
        if (!operation) {
            url = `emailOrUsername=${emailOrUsername}&subject=${subject}`;
        }
        else {
            url = `emailOrUsername=${emailOrUsername}&subject=${subject}&operation=${operation}`;
        }
        const response = await fetch(`${environment.auth}resendVerification?${url}`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: '{}',
        });
        if (response.status === 200) {
            dispatch(authErrorText('Code was sent'));
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        console.warn('Error', e);
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    }
};

export const doChange = (
    user: IProfile,
    token: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.UpdateProfile));
    try {
        let imageBase64: string | undefined = undefined;
        let extension = '';

        if (user.image === '') {
            await authFetch(getState, dispatch, topDoctorData() + 'users/deleteprofileimage', 'GET', null, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token=');
        }

        const response = await authFetch(getState, dispatch, topDoctorData() + 'users/updateprofile', 'POST', user, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token=');

        const json = await response.json();
        console.warn('json', json);
        console.warn('response', response);
        if (response.status === 200) {
            dispatch(_fetchSelf(json));
            dispatch(fetchSelf(token, UserRole.Patient));
            dispatch(authErrorText('Profile successfully updated!'));
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.UpdateProfile));
    }
};

export const changePassword = (
    token: string,
    user: IProfile | IDoctor | IClinic,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.ChangePswd));

    try {
        const response = await authFetch(getState, dispatch, `${environment.auth}changePassword`,
            'POST', {
            oldPassword: user.oldpassword,
            newPassword: user.password,
        });

        console.log('response passws', response);
        if (response.status === 200) {
            dispatch(authErrorText('Password successfully changed. Please, login with new password'));
            dispatch(signOut(token));
        } else {
            console.warn('Error', response);
            dispatch(authErrorText('Error'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.ChangePswd));
    }
};

// ---- new doctor +
export const newDoctor = (
    user: IUserSignUpRequest,
    onSuccess: any,
    doctorStep: any,

): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.NewDoctor));

    try {
        if (user.phone) {
            if (user.phone.length == 9) {
                user = { ...user, phone: '+994' + user.phone };
            }
            else if (user.phone.length > 0) {
                showAlert('Wrong phone number format!')
                console.log(user.phone);
                return;
            }
        }

        const response = await fetch(topDoctorData() + 'users/newdoctor', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                phone: user.phone,
                username: user.login,
                email: user.email,
                password: user.password,
                // language: await AsyncStorage.getItem('az.ezgil.videodoctor.language')
            }),
        });

        const json = await response.json();

        if (response.status === 200) {
            if (json.success) {
                dispatch(saveUser(user));
                doctorStep();
                dispatch(processStageChange(LoginStage.PhoneVerification));
                onSuccess()
                return;
            }
        }
        dispatch(errorMsgSignUp(json.code));
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.NewDoctor));
    }
};
// ---- new doctor -

// ---- new doctor1 +
export const newDoctor1 = (
    doctor: IDoctor_1_Step,
    token: string,
    doctorStep: any,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.NewDoctor1));

    let _imageBase64 = '';
    let extension = '';
    doctor.uploadfileimage.map((img: any) => {
        _imageBase64 = img.uploadFile;
        extension = img.extension;
    })
    doctor = { ...doctor, uploadfileimage: _imageBase64, extensionimage: extension };

    let img64 = doctor.uploadfileimage;

    doctor = { ...doctor, uploadfileimage: img64 };

    try {
        const response = await fetch(topDoctorData() + `users/newdoctor1?token=` + encodeURIComponent(token), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(doctor),
        });

        const json = await response.json();

        if (response.status === 200 && json.success) {
            dispatch(_newDoctor1());
            doctorStep();
            return;
        }
        else {
            dispatch(authErrorText('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.NewDoctor1));
    }
};
// ---- new doctor1 -

// ---- new doctor2 +
export const newDoctor2 = (
    doctor: IDoctor_2_Step,
    token: string,
    doctorFinal: any,
    doctorStep: any,

): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.NewDoctor2));

    let extensiondiplom = '';
    let uploadfilediplom = '';

    let extensioncertificate = '';
    let uploadfilecertificate = '';

    for (const itm in doctor) {
        let myArr = doctor[itm];
        if (itm === 'uploadfilecertificate') {

            if (myArr instanceof Array && myArr.length) {
                myArr.map((e: any) => {

                    extensioncertificate = e.extension;
                    let img64 = e.uploadFile;
                    uploadfilecertificate = img64;

                    doctor = { ...doctor, extensioncertificate: extensioncertificate, uploadfilecertificate: uploadfilecertificate };

                })
            }
        }
        if (itm === 'uploadfilediplom') {

            if (myArr instanceof Array && myArr.length) {
                myArr.map((e: any) => {

                    extensiondiplom = e.extension;
                    let img64 = e.uploadFile;
                    uploadfilediplom = img64;

                    doctor = { ...doctor, extensiondiplom: extensiondiplom, uploadfilediplom: uploadfilediplom };

                })
            }
        }
    }

    try {
        const response = await fetch(topDoctorData() + `users/newdoctor2?token=` + encodeURIComponent(token), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(doctor),
        });

        const json = await response.json();

        if (response.status === 200 && json.success) {
            dispatch(_newDoctor2());
            doctorFinal();
            doctorStep();
            return;
        }
        else {
            dispatch(authErrorText('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        dispatch(authErrorText('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.NewDoctor2));
    }
};
// ---- new doctor2 -

export const restoreLogin = (
    token: IUserToken
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const result = await syncRefresh(token, dispatch);
    if (result) {
        dispatch(fetchSelf(result.auth, result.role));
        dispatch(startPinging());
        dispatch(connect(environment.wsHost));
    }
}

const refresh = async (token: IUserToken, dispatch: (a: any) => void) => {
    try {
        console.log("Refresh token started");
        const response = await fetch(`${environment.auth}refresh`, {
            method: "POST", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
                Authorization: token.refresh,
            },
            redirect: "error", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: JSON.stringify({}),
        });

        if (response.status === 401) {
            localStorage.removeItem('az.ezgil.videodoctor.token');
            dispatch(_signOut());
            history.push('/');

            console.log("Refresh token expired");
            return undefined;
        }

        if (response.status !== 200) {
            console.log("Refresh token failed");
            return undefined;
        }

        const json = (await response.json());
        json.role = token.role;
        json.selfId = token.selfId;
        json.status = token.status;
        json.clientId = token.clientId;

        localStorage.setItem("az.ezgil.videodoctor.token", JSON.stringify(json));
        dispatch(_signIn(json));

        return json as IUserToken;
    } catch (e) {
        // Ignore
    }
    return undefined;
};

let refreshPromise: Promise<IUserToken | undefined> | undefined = undefined;

export const syncRefresh = (token: IUserToken, dispatch: (a: any) => void): Promise<IUserToken | undefined> => {
    if (refreshPromise) {
        return refreshPromise;
    }
    return (refreshPromise = new Promise(async (myResolve, myReject) => {
        const payload = await refresh(token, dispatch);
        myResolve(payload);
        refreshPromise = undefined;
    }));
};

export const lastToken = async (dispatch: (a: any) => void): Promise<IUserToken | undefined> => {
    const token = JSON.parse(localStorage.getItem("az.ezgil.videodoctor.token") || '{}');
    if (token.date && new Date().getTime() - token.date < TOKEN_LIFE_TIME) {
        return token;
    }
    if (!token) {
        return undefined;
    }
    return await syncRefresh(token, dispatch);
}

export const authFetch = async (getState: () => RootState, dispatch: (a: any) => void, url: string, method: "GET" | "POST" | "PUT" | "DELETE" | "PATCH", body?: any, headers?: any, tokenSuffix?: string) => {
    const headers_obj = {
        ...(headers || {}),
        "Content-Type": "application/json",
    };

    let tokens = JSON.parse(localStorage.getItem("az.ezgil.videodoctor.token") || '{}');

    if (tokens?.auth && !tokenSuffix) {
        headers_obj.Authorization = tokens.auth;
    }

    let response = await fetch(url + (tokenSuffix && tokens?.auth ? tokenSuffix + encodeURIComponent(tokens.auth) : ""), {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: "cors", // no-cors, *cors, same-origin
        cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
        credentials: "same-origin", // include, *same-origin, omit
        headers: headers_obj,
        //  redirect: "error", // manual, *follow, error
        referrerPolicy: "no-referrer", // no-referrer, *client
        body: body ? JSON.stringify(body) : undefined,
    });

    //    console.log('response', response);

    if (response.status === 401) {
        if (!tokens || !tokens.refresh) {
            localStorage.removeItem('az.ezgil.videodoctor.token');
            dispatch(_signOut());
            history.push('/');

            throw new Error("refresh token empty");
        }
        tokens = await syncRefresh(tokens, dispatch);
        if (!tokens) {
            throw new Error("refresh token failed");
        }

        response = await fetch(url + (tokenSuffix ? tokenSuffix + encodeURIComponent(tokens.auth) : ""), {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: tokenSuffix ? {
                ...(headers || {}),
                "Content-Type": "application/json",
            } : {
                ...(headers || {}),
                "Content-Type": "application/json",
                Authorization: tokens.auth,
            },
            redirect: "error", // manual, *follow, error
            referrerPolicy: "no-referrer", // no-referrer, *client
            body: body ? JSON.stringify(body) : undefined,
        });

        if (response.status === 401) {
            localStorage.removeItem('az.ezgil.videodoctor.token');
            dispatch(_signOut());
            history.push('/');
            throw new Error("auth token failed");
        }
    }

    return response;
};