import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { WrappedFieldProps } from "redux-form";
import './DropDownSelect.scss';

export interface CustomFieldProps extends WithTranslation {
    name?: string;
    label?: string;
    _className: string;
    style?: any;
    valuesForDropdown: Array<any>;
    onClose?: (id: number | undefined) => void;
}

class DropDownSelect extends React.Component<WrappedFieldProps & CustomFieldProps> {

    render() {

        const { input: { onChange, onBlur, value },
            label, valuesForDropdown, onClose,
            meta: { error, touched, valid }, _className, name, t } = this.props;

        return (
            <div className={_className ? _className : ''}>
                <p style={{ textAlign: 'left', fontSize: '13px' }}>{label}:</p>
                <select
                    name={name}
                    onChange={onChange}
                    style={this.props.style ? this.props.style : {}}
                >
                    <option key={"not-selected"} selected={!value} value={""}>{t('Not selected')}</option>
                    {
                        valuesForDropdown && valuesForDropdown.map((i) => {
                            return <option key={i.value} selected={i.value == value} value={i.value}>{i.label}</option>
                        })
                    }
                </select>
                {!valid && touched ? <p style={{
                    color: 'rgb(238, 4, 4)',
                    marginLeft: '2px',
                    marginBottom: '-5px',
                    textAlign: 'left',
                    fontSize: '13px',
                }} >{error}</p> : null}
            </div>
        );
    }
}

export default withTranslation()(DropDownSelect);