export default {
    palette: {
      primaryColor: '$main-dark-color',//3ca6bd
      secondaryColor: '#03A8F5',
      lightGreenColor: '#7ECCC4',
      lightBlueColor: '#05A8F5',
      blueColor: '#83D6FC',
      redColor: '#ad1457',
      accentColor: '#FFE64E',
      grayPrimaryColor: '#bfbebe',
      graySecondaryColor: '#E6E6E6',
      grayColor: '#5C5C5C',
      grayAgendaColor: '#4B515D',
      grayLightColor: '#A4A7A9',
      grayLightPrimaryColor: '#BBBEC3',
      grayLightSecondaryColor: '#95989A',
      graySecondaryDarkColor: '#898989',
      grayDarkColor: '#676767',
      whiteColor: '#fff',
      bluePrimaryColor: '#B3E0DC',
      blackColor: '#000000',
    },
    toolbar: {
      container: {
        height: 50,
      },
    },
};
