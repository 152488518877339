import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator } from "redux";
import { loadingEnd, loadingStart } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import { authFetch, SignInAction, signOut } from "./auth-actions";
import { pushAlert } from "./alert-actions";
import * as environment from '../app.json';
import { RootState } from "../store";
import i18n from 'i18next';
import { IProfileValue } from "../models/profile-value";
import { IProfileSection } from "../models/profile-section";
import { ProfileValueType } from "../models/profile-value-type";
import { UserRole } from '../models/user-role.enum';

export const PROFILE_PAGE_LIMIT = 40;

export const PROFILE_VALUES = 'PROFILE_VALUES';
interface ProfileValuesAction {
    type: typeof PROFILE_VALUES;
    section: IProfileSection;
    values: IProfileValue[];
    page: number;
    hasMore: boolean;
}

export const _profileValues: ActionCreator<ProfileValuesAction> = (
    section: IProfileSection,
    values: IProfileValue[],
    page: number,
    hasMore: boolean,
) => {
    return {
        type: PROFILE_VALUES,
        section,
        values,
        page,
        hasMore
    };
};

export const DELETE_PROFILE_VALUES = 'DELETE_PROFILE_VALUES';
interface DeleteProfileValuesAction {
    type: typeof DELETE_PROFILE_VALUES;
    values: { [key: string]: any }[];
}

export const _deleteProfileValues: ActionCreator<DeleteProfileValuesAction> = (
    values: { [key: string]: any }[]
) => {
    return {
        type: DELETE_PROFILE_VALUES,
        values
    };
};

export type ProfileActionsTypes = DeleteProfileValuesAction | ProfileValuesAction | SignInAction;

function topDoctorData(language?: string) {
    let topDoctor = environment.topDoctor;
    language = language || i18n.language;
    if (language === 'az') topDoctor = environment.topDoctorAz;
    if (language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

const translate = (section: IProfileSection, json: any,) => {
    for (const value of section.values) {
        if (value.type === ProfileValueType.dictionary || value.type === ProfileValueType.boolean) {
            json[value.name] = json[value.name] || json[value.name] === 0 ? Number(json[value.name]) : undefined;
        }
        if (!value.multilanguage) {
            continue;
        }
        json[value.name + "_az"] = json[value.name];
        delete json[value.name];
        if (!json.translations) {
            continue;
        }
        for (const lang of ["ru", "en"]) {
            const translation = json.translations.find(v => v.language = lang);
            if (!translation) {
                json[value.name + "_" + lang] = '';
                continue;
            }
            json[value.name + "_" + lang] = translation[value.name] || '';
        }
    }
    delete json['translations'];
    return json as { [key: string]: any };
}

export const getProfileValues = (
    section: IProfileSection,
    page: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(page ? LoadingSubject.ProfileValuesNext : LoadingSubject.ProfileValues));

    try {
        if (section.singleLoad) {
            const response = await authFetch(
                getState, dispatch, topDoctorData() + section.singleLoad, 'GET',
                null, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
            );
            if (response.status === 200) {
                const json = await response.json();
                dispatch(_profileValues(section, [translate(section, json.payload || json)], 1, false));
            }
            else {
                console.warn('Error', response);
                dispatch((pushAlert('Error')));
            }
            return;
        }
        const singleDictionary = section.values?.length === 1 && section.values[0].type === ProfileValueType.dictionary;
        const limit = singleDictionary ? 10000 : PROFILE_PAGE_LIMIT;
        const response = await authFetch(
            getState, dispatch, topDoctorData() + section.name + '/list?per-page=' + limit + '&page=' + page, 'GET',
            null, { Accept: 'application/json', 'Content-Type': 'application/json' }, '&token='
        );

        if (response.status === 200) {
            const json = await response.json();
            dispatch(_profileValues(section, json, page + 1, json.length === limit));
        }
        else {
            console.warn('Error', response);
            dispatch((pushAlert('Error')));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(page ? LoadingSubject.ProfileValuesNext : LoadingSubject.ProfileValues));
    }
}


export const getProfileValue = (
    id: number
): ThunkAction<Promise<{ [key: string]: any } | null>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.ProfileValue));

    try {
        const role = getState().auth.userToken?.role;
        const section = role === UserRole.Doctor ? getState().profile.section : getState().profile.section;

        const response = await authFetch(
            getState, dispatch, topDoctorData("az") + section.name + '/' + id, 'GET',
            null, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
        );

        if (response.status === 200) {
            const json = await response.json();
            return translate(section, json);
        }
        else {
            console.warn('Error', response);
            dispatch((pushAlert('Error')));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.ProfileValue));
    }
    return null;
}

const addSingle = async (
    getState: () => RootState, dispatch: (a: any) => void,
    values: { [key: string]: any },
    section: IProfileSection,
) => {
    values = { ...values };
    for (const value of section.values) {
        if (value.multilanguage) {
            values[value.name] = values[value.name + "_az"];
            delete values[value.name + "_az"];
            continue;
        }
        if (value.type !== ProfileValueType.file) {
            continue;
        }
        const field = values[value.name];
        if (!Array.isArray(field)) {
            continue;
        }
        if (!field.length) {
            values[value.name] = "";
            continue;
        }
        if (field[0].ID) {
            values['uploadfile'] = field[0].uploadFile;
            values['extension'] = field[0].extension;
            delete values[value.name];
        }
    }
    return await authFetch(
        getState, dispatch, topDoctorData() + section.name + (values.id ? '/' + values.id : ''), values.id ? 'PATCH' : 'POST',
        values, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
    );
}

export const profileValueSave = (
    values: { [key: string]: any },
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveProfileValue));

    console.log('profileValueSave')

    try {
        const role = getState().auth.userToken?.role;
        const section = role === UserRole.Doctor ? getState().profile.section : getState().profile.section;

        const response = await addSingle(getState, dispatch, values, section);

        if (response?.status === 200) {
            dispatch(getProfileValues(section, 0));
            success && success();
        }
        else if (response?.status === 401) {
            dispatch(signOut(getState().auth.userToken!.auth));
        }
        else {
            console.warn('valueSaveBulkError', response);
            dispatch((pushAlert('Error')));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveProfileValue));
    }
}

export const profileValueDeleteBulk = (
    values: { [key: string]: any }[],
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DeleteProfileValue));

    try {
        const role = getState().auth.userToken?.role;
        const section = role === UserRole.Doctor ? getState().profile.section : getState().profile.section;
        for (const value of values) {
            const response = await authFetch(
                getState, dispatch, topDoctorData() + section.name + '/' + value.id, 'DELETE',
                {}, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
            );

            if (response.status === 401) {
                dispatch(signOut(getState().auth.userToken!.auth));
                return;
            }
            if (response.status !== 200) {
                console.warn('profileValueDeleteBulk', response);
                dispatch((pushAlert('Error')));
                return;
            }
        }

        dispatch(_deleteProfileValues(values));
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DeleteProfileValue));
    }
}

export const profileValueChangeBulk = (
    toDelete: { [key: string]: any }[],
    toAdd: { [key: string]: any }[],
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.SaveProfileValue));

    try {
        const role = getState().auth.userToken?.role;
        const section = role === UserRole.Doctor ? getState().profile.section : getState().profile.section;
        for (const value of toDelete) {
            const response = await authFetch(
                getState, dispatch, topDoctorData() + section.name + '/' + value.id, 'DELETE',
                {}, { Accept: 'application/json', 'Content-Type': 'application/json' }, '?token='
            );

            if (response.status === 401) {
                dispatch(signOut(getState().auth.userToken!.auth));
                return;
            }
            if (response.status !== 200) {
                console.warn('profileValueDeleteBulk', response);
                dispatch((pushAlert('Error')));
                return;
            }
        }

        for (const value of toAdd) {
            const response = await addSingle(
                getState, dispatch, value, section);

            if (response?.status === 401) {
                dispatch(signOut(getState().auth.userToken!.auth));
                return;
            }
            if (response?.status !== 200) {
                console.warn('profileValueDeleteBulk', response);
                dispatch((pushAlert('Error')));
                return;
            }
        }

        dispatch(getProfileValues(section, 0));
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.SaveProfileValue));
    }
}