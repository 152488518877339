import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors, formValueSelector } from 'redux-form';
import TextInput from './FormComponents/TextInput';
import { IProfile } from "../models/profile";
import { RootState } from "../store";
import DropDownSelect from './FormComponents/DropDownSelect';
import { BloodTypeEnum } from "../models/blood-type.enum";
import './PersonalCabinetPatientForm.scss';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import ImageInput from './FormComponents/ImageInput';

interface PersonalCabinetPatientFormProps extends InjectedFormProps<IProfile> {
    t: TFunction;
    editMode?: boolean;
}

class PersonalCabinetPatientForm extends React.Component<PersonalCabinetPatientFormProps> {
    render() {
        const { editMode, t } = this.props;
        const valuesForSexDropdown = [
            { value: "0", label: t('Male') },
            { value: "1", label: t('Female') }
        ];

        const valuesForBloodTypeDropdown = [{ value: BloodTypeEnum.IPlus, label: 'O(I) Rh+' },
        { value: BloodTypeEnum.IMinus, label: 'O(I) Rh-' },
        { value: BloodTypeEnum.IIPlus, label: 'A(II) Rh+' },
        { value: BloodTypeEnum.IIMinus, label: 'A(II) Rh-' },
        { value: BloodTypeEnum.IIIPlus, label: 'B(III) Rh+' },
        { value: BloodTypeEnum.IIIMinus, label: 'B(III) Rh-' },
        { value: BloodTypeEnum.IVPlus, label: 'AB(IV) Rh+' },
        { value: BloodTypeEnum.IVMinus, label: 'AB(IV) Rh-' },];

        let textInputClassName = 'personal-cabinet-patient-styles_text-input';
        let selectInputClassName = 'personal-cabinet-patient-styles_text-dropdown';
        let ImageInputClassName = 'personal-cabinet-patient-styles_image-input';
        if (editMode) {
            textInputClassName += ' edit-mode';
            selectInputClassName += ' edit-mode';
            ImageInputClassName += 'edit-mode';
        }

        return (
            <div className='personal-cabinet-patient-form_main'>
                <div className='_row'>
                    <div className='_block0'>
                        <Field
                            _className={ImageInputClassName}
                            name="image"
                            type={'patients'}
                            component={ImageInput}
                        />

                    </div>
                    <div className='_block1'>
                        <Field
                            _className={textInputClassName}
                            name="name"
                            placeholder={t('Name')}
                            component={TextInput}
                        />
                        <Field
                            _className={selectInputClassName}
                            name="sex"
                            label={t('Gender')}
                            component={DropDownSelect}
                            valuesForDropdown={valuesForSexDropdown}
                            style={{
                                width: '100%',
                                height: '31px',
                                outline: 'none',
                                paddingLeft: '8px',
                                marginTop: '-7px'
                            }}
                        />
                    </div>

                    <div className='_block2'>
                        <Field
                            _className={textInputClassName}
                            name="birthdate"
                            type='date'
                            placeholder={t('Birthdate')}
                            component={TextInput}
                        />

                        <Field name="blood_type"
                            label={t('Blood type')}
                            component={DropDownSelect}
                            _className={selectInputClassName}
                            valuesForDropdown={valuesForBloodTypeDropdown}
                            style={{
                                width: '100%',
                                height: '31px',
                                outline: 'none',
                                paddingLeft: '8px',
                                marginTop: '-7px'
                            }}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const selector = formValueSelector('IProfilePersonalCabinetPatientForm');

const mapStateToProps = (state: RootState) => {
    let { sex, blood_type, image } = selector(state, 'sex', 'blood_type', 'image');
    sex = sex?.toString();
    console.log('state.auth.self', state.auth.self);
    return {
        initialValues: state.auth.self as IProfile,
        sex,
        blood_type,
    }
}

const validate = (values: IProfile, props: PersonalCabinetPatientFormProps & { t: TFunction }): FormErrors<IProfile> => {
    const errors: FormErrors<IProfile> = {};
    const { t } = props;
    if (!values.name || values.name.trim() === '') {
        errors.name = t('Field couldn\'t be empty');
    }
    if (!values.sex && values.sex !== 0) {
        errors.sex = t('Field couldn\'t be empty');
    }
    if (!values.birthdate) {
        errors.birthdate = t('Field couldn\'t be empty');
    }
    if (!values.blood_type) {
        errors.blood_type = t('Field couldn\'t be empty');
    }
    return errors;
};

const form = reduxForm<IProfile, any>({
    form: 'PersonalCabinetPatientForm',
    validate
})(PersonalCabinetPatientForm);

export default connect(mapStateToProps)(withTranslation()(form));