import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { RootState } from "../../store";
import { DictionaryTypesEnum } from '../../models/dictionary-types.enum';
import { IDictionary } from '../../models/dictionary';
import { Link } from 'react-router-dom';
import './alphabet.scss';

interface AlphabetProps {
    clinics?: IDictionary[];
    specializationTypes?: IDictionary[];
}

interface AlphabetState {
    sortedClinics?: IDictionary[];
    alphabetClinics: {};
    sortedSpecializations?: IDictionary[];
    alphabetSpecializations: {};
    showClinics: boolean;
    showSpecializations: boolean;
}

class AlphabetComponent extends React.Component<AlphabetProps & WithTranslation, AlphabetState> {
    constructor(props: AlphabetProps & WithTranslation) {
        super(props);
        this.state = {
            sortedClinics: [],
            alphabetClinics: {},
            sortedSpecializations: [],
            alphabetSpecializations: {},
            showClinics: false,
            showSpecializations: false
        }
    }

    componentDidMount() {
        this.sortAndCreateClinicsAlphabet();
        this.sortAndCreateSpecializationsAlphabet();
    }

    componentDidUpdate(prevProps: AlphabetProps) {
        if (prevProps.clinics !== this.props.clinics) {
            this.sortAndCreateClinicsAlphabet();
        }
        if (prevProps.specializationTypes !== this.props.specializationTypes) {
            this.sortAndCreateSpecializationsAlphabet();
        }
    }

    sortAndCreateClinicsAlphabet() {
        const sAlph: IDictionary[] | undefined = this.props.clinics?.sort((a, b) => {
            if (a.name.replace('\"', '') < b.name.replace('\"', '')) return -1;
            if (a.name.replace('\"', '') > b.name.replace('\"', '')) return 1;
            return 0;
        });

        this.setState({sortedClinics: sAlph});

        let letters = {};

        for (let clinic of (sAlph || [])) {
            const firstLetter = clinic.name.replace('\"', '')[0];
            if (letters[firstLetter]) {
                letters[firstLetter] = [...letters[firstLetter], clinic]
            } else {
                letters[firstLetter] = [clinic]
            }
        }

        this.setState({alphabetClinics: letters});
    }

    sortAndCreateSpecializationsAlphabet() {
        const sAlph: IDictionary[] | undefined = this.props.specializationTypes?.sort((a, b) => {
            if (a.name.replace('\"', '') < b.name.replace('\"', '')) return -1;
            if (a.name.replace('\"', '') > b.name.replace('\"', '')) return 1;
            return 0;
        });

        this.setState({sortedSpecializations: sAlph});

        let letters = {};

        for (let spec of (sAlph || [])) {
            const firstLetter = spec.name.replace('\"', '')[0];
            if (letters[firstLetter]) {
                letters[firstLetter] = [...letters[firstLetter], spec]
            } else {
                letters[firstLetter] = [spec]
            }
        }

        this.setState({alphabetSpecializations: letters});
    }

    showClinicsHandler() {
        this.setState({showClinics: !this.state.showClinics});
    }

    showSpecializationsHandler() {
        this.setState({showSpecializations: !this.state.showSpecializations});
    }

    render() {
        const {t} = this.props;
        return(
            <>
                <div className='content-block'>
                    <h1 className='big-title'>{t('Select doctor by specialization')}</h1>
                    <div className={this.state.showSpecializations ? 'alphabet opened' : 'alphabet'}>
                    {Object.keys(this.state.alphabetSpecializations).map((letter, i) => (
                        <div className='alphabet-item' key={letter}>
                            <p className='alphabet-item-title'>{letter}</p>
                            <div className='alphabet-data-container'>
                                {this.state.alphabetSpecializations[letter].map(spec => (
                                    <Link to={`/doctors?specialization=${spec.id}`} key={spec.id}><p key={spec.name} className='alphabet-data-name'>{spec.name}</p></Link>       
                                ))}
                            </div>
                        </div>)
                    )}
                        {!this.state.showSpecializations && <div className='gradient'/>}
                    </div>
                    <div className='btn-home-alphabet-wrapper'>
                        <button onClick={this.showSpecializationsHandler.bind(this)} className='blue-btn btn btn-primary'>
                            {this.state.showSpecializations ? t('Minimize') : t('Show more')}
                        </button>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    clinics: state.dictionary.clinics,
    specializationTypes: state.dictionary.specializationTypes
});

const mapDispatchToProps = {
}

const Alphabet = connect(mapStateToProps, mapDispatchToProps)(AlphabetComponent);

export default withTranslation()(Alphabet);