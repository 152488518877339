import { i18n, TFunction } from "i18next";
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from "react-redux";
import { deletePatientClinic, savePatientClinic, _getPatientClinic } from "../../actions/patient-clinic-action";
import * as env from '../../app.json';
import { IPatientClinic } from "../../models/patient-clinic";
import { IAuthState } from "../../states/auth-state";
import { IDictionaryState } from '../../states/dictionary-state';
import { RootState } from "../../store";
import ClinicsRatings from './../Ratings/ClinicsRatings';
import './Clinics.scss';
import './Filter.scss';
import { showAlert, showConfirm } from "../Dialogs";
import DoctorsList from './DoctorsList';
import Liked from '@material-ui/icons/FavoriteBorder';
import Like from '@material-ui/icons/Favorite';
import Spinner from 'react-spinner-material';

interface MyProps {
    deletePatientClinic: (
        id: number,
    ) => void;

    savePatientClinic: (
        patientClinic: IPatientClinic,
    ) => void;

    auth: IAuthState;
    dictionary: IDictionaryState;
    t: TFunction;
    i18n: i18n;

    clinicItem: any;
    clinicType: any;
    patientClinicList: any;
}

interface MyState {
    showAllDesc: boolean;
    actionsOnClinics: boolean;
}

class ClinicItemComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            showAllDesc: false,
            actionsOnClinics: false,
        };
    }

    checkAllowSchedule = () => {
        if (!this.props.auth.userToken) {
            showAlert(this.props.t('Please Sing in'));
        }
        return !!this.props.auth.userToken;
    }

    render() {
        const { t } = this.props;

        const clinic = this.props.clinicItem;
        const clinicType = this.props.clinicType;

        let _clinic = clinic;
        if (clinicType === "/my-clinics") {
            _clinic = clinic?.clinic;
        }
        else {
            _clinic = clinic;
        }

        let _clinicType = _clinic?.type;
        if (_clinicType && /^\d+$/.test(_clinicType)) {
            this.props.dictionary && this.props.dictionary?.clinicType && this.props.dictionary.clinicType.map((itm: any) => {
                if (itm.id == _clinicType) {
                    _clinicType = itm.name;
                }
            })
        }

        const patientClinicList = this.props.patientClinicList;
        let clinicInMyClinics = false;
        patientClinicList && patientClinicList.length && patientClinicList.map((clinique: any) => {
            if (clinique.clinic?.id === _clinic.id) {
                clinicInMyClinics = true;
            }
        })

        console.log('doctors test', this.props)

        return (
            <div className='clinics-main-item' key={_clinic.id}>
                <div className='image-block'>
                    <div className='clinic-image-block'>

                        {this.props.auth?.userToken && this.props.auth?.userToken?.role === 3 &&

                            <div
                                className='like_btn'
                                onClick={async () => {

                                    if (clinicInMyClinics) {
                                        const _delete = await showConfirm('Are you sure you want to delete the hospital?');
                                        if (_delete) {
                                            this.setState({ actionsOnClinics: true })
                                            await this.props.deletePatientClinic(clinic?.id as number);
                                        }
                                    }
                                    else {
                                        if (this.checkAllowSchedule()) {
                                            this.setState({ actionsOnClinics: true })
                                            await this.props.savePatientClinic({ clinic_id: _clinic?.id as number, description: '' });
                                        }
                                    }
                                    this.setState({ actionsOnClinics: false })

                                }}
                            >
                                {
                                    this.state.actionsOnClinics ?
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}><Spinner radius={17} color={"#03A8F5"} stroke={3} visible={true} /></div>
                                        :
                                        clinicInMyClinics ? <Liked /> : <Like />
                                }
                            </div>
                        }

                        <img className='clinic-default-img'
                            src={_clinic?.imageFile ? env.uploads + 'clinics/' + _clinic?.imageFile :
                                require('./../../images/clinic-default-img.png')}
                            alt='clinic-default-img' />
                    </div>
                    <div className='clinic-rating-block'>
                        <ClinicsRatings clinic={_clinic} />
                    </div>
                </div>
                <div className='clinic-info-block'>
                    <p className='regular-text clinic_type'>{_clinicType}</p>
                    <p className='clinic-title-1'>{_clinic?.name}</p>
                    <div className='about-clinic'>
                        <p className='regular-text'>{t('Opened since') + ' ' + _clinic?.year_o} • {_clinic?.workinghours}</p>
                        <p className='regular-text'>{t('Adress') + ': ' + _clinic?.address}</p>
                    </div>
                    <div className='clinic-description-block'>

                        <p className='clinic-title-2'>{t('Description')}:</p>

                        <p
                            className={
                                this.state.showAllDesc ? 'regular-text clinic-desc-text all-clinic-desc' :
                                    'regular-text clinic-desc-text'
                            }

                            dangerouslySetInnerHTML={{ __html: _clinic?.description }}
                        />
                        {_clinic?.description && _clinic?.description.length > 450 &&
                            <div className='show_more_clinic_description' onClick={() => {
                                this.setState({ showAllDesc: !this.state.showAllDesc })
                            }}>
                                <p>{this.state.showAllDesc ? t('Minimize') : t('Show more')}</p>
                            </div>
                        }
                    </div>
                </div>
                <DoctorsList clinicId={_clinic.id} />
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    clinic: state.clinic,
    auth: state.auth,
    patientClinic: state.patientClinic,
    dictionary: state.dictionary,
});

const mapDispatchToProps = {
    deletePatientClinic,
    savePatientClinic,
};

let ClinicItemComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    ClinicItemComponent
);

export default withTranslation()(ClinicItemComponentContainer);