import React, { MouseEvent } from 'react';
import './layouts.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CSS from 'csstype';

interface VDButtonProps {
    type?: "button" | "submit" | "reset" | undefined;
    color?: "success" | "primary" | "warning" | "danger" | "light" | undefined;
    onClick: () => void;
    label?: string;
    children?: any;
    faIcon?: any;
    rounded?: boolean;
    style?: CSS.Properties;
    vertical?: boolean;
    fullWidth?: boolean;
}

export class VDButton extends React.Component<VDButtonProps> {
    renderButton() {
        const {type, color, label, children, style, vertical, fullWidth} = this.props;
        let className = `vd-button vd-button_${color}`;
        if (!fullWidth) className += ' vd-button-button';
        if (vertical) className += ' vd-button-vertical';
        return (
            <button type={type} className={className} style={{...style}} onClick={this.props.onClick} >
                {children || label}
            </button>
        )
    }

    renderRounded() {
        const {color, faIcon} = this.props;
    
        return (
            <span className={`vd-rounded-button vd-rounded-button_${color}`} onClick={this.props.onClick}> {faIcon} </span>
        )
    }

    render() {
        if (this.props.rounded) {
            return this.renderRounded()
        } else return this.renderButton()
    }
    
}