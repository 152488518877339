import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import './MyAnalyzes.scss';
import { IAuthState } from "../../../states/auth-state";
import { submit, reset, FormAction, change } from 'redux-form';
import { IDictionaryState } from "../../../states/dictionary-state";
import { IPatientAnalyzeState } from "../../../states/patient-analyze-state";
import { getPatientAnalyzeList, deletePatientAnalyze } from "../../../actions/patient-analyze-actions";
import Modal from 'react-bootstrap/Modal'
import UserAnalyzesForm from '../../../forms/user-analyzes-form';
import { IPatientAnalyze } from "../../../models/user-analyze";
import { _patientAnalyzeItem, getPatientAnalyze, createPatientAnalyze, updatePatientAnalyze } from "../../../actions/patient-analyze-actions";
import { IFile } from "../../../models/file";
import { IUpload } from '../../../models/upload';
import { i18n, TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { showConfirm } from '../../Dialogs';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { JsxElement } from 'typescript';
import { LoadingCenter } from "../../Loading/Loading";
import { ILoadingState, LoadingSubject } from '../../../states/loading-state';
import SaveButton from './../SaveButton/SaveButton';
import { ReactComponent as MyAnalyzes } from '../../../images/medical-card/my-analyzes.svg';


interface MyProps {
    t: TFunction;
    i18n: i18n;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    auth: IAuthState;
    dictionary: IDictionaryState;
    patientAnalyze: IPatientAnalyzeState;
    getPatientAnalyzeList: () => void;
    deletePatientAnalyze: (id: number) => void;

    createPatientAnalyze: (analyze: IPatientAnalyze, uploadFiles: IUpload[], deleleFiles: IFile[], host_type: number, success: () => void) => void;
    updatePatientAnalyze: (analyze: IPatientAnalyze, uploadFiles: IUpload[], deleleFiles: IFile[], host_type: number, success: () => void) => void;
    getPatientAnalyze: (id: number) => void;
    _patientAnalyzeItem: (analyze: IPatientAnalyze | null) => void;

    fromDocItems: any;
    loading: ILoadingState;
    forDocItem: boolean;
    patientInfoBlock: JSX.Element;
}

interface MyState {
    show: boolean;
    updateItem: boolean;
    selectedFiles?: IUpload[];
    deletedFiles?: IFile[];
    removeMode: {} | null;

    fromDocItems: any;
    forDocItem: boolean;
}

class MyAnalyzesComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);

        this.state = {
            show: false,
            updateItem: false,
            removeMode: null,

            fromDocItems: this.props?.fromDocItems,
            forDocItem: this.props?.fromDocItems,
        };
    }

    onSubmit(data: IPatientAnalyze) {
        if (this.state.updateItem) {
            this.props.updatePatientAnalyze(data, this.state.selectedFiles || [], this.state.deletedFiles || [], 2, this.handleClose);
        } else {
            this.props.createPatientAnalyze(data, this.state.selectedFiles || [], this.state.deletedFiles || [], 2, this.handleClose);
        }
    }

    async confirmDelete() {
        const { t } = this.props;

        if (await showConfirm(t("Are you sure you want to delete this item/items?"))) {
            for (const index in this.state.removeMode) {
                if (this.state.removeMode[index]) {
                    let item: any;
                    if (this.props.patientAnalyze && this.props.patientAnalyze.list) {
                        item = this.props.patientAnalyze.list[index];
                    }
                    if (item) {
                        await this.props.deletePatientAnalyze(item.id);
                    }
                }
            }
            this.setState({ removeMode: null });
        }
    }

    componentDidMount() {
        this.props.getPatientAnalyzeList();
    }

    handleClose = () => {
        this.setState({ show: false })
        this.props._patientAnalyzeItem(null);
        this.setState({ selectedFiles: [], deletedFiles: [] });
    };

    handleShow = () => { this.setState({ show: true }) };

    render() {
        const { fromDocItems, forDocItem } = this.state;
        const { t } = this.props;

        let itemsList = this.props.patientAnalyze.list;
        if (fromDocItems) {
            itemsList = fromDocItems;
        }

        const patientAnalyzeLoading = this.props.loading.subjects.has(LoadingSubject.PatientAnalyze);

        return (
            <div className='analyzes_wrapper'>
                <Modal centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header className='my-meds-modal-header' closeButton>
                        <Modal.Title>{this.state.updateItem ? t('Change analysis') : t('New analysis')}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='step1'>
                        {
                            fromDocItems && this.state.forDocItem ?
                                Object.entries(this.state.forDocItem).map((itm: any) => {
                                    if (itm[0] !== 'Files') {
                                        return (
                                            <div style={itm[1] ? {} : { display: 'none' }}>
                                                <p
                                                    style={{
                                                        fontWeight: 'bold',
                                                        fontSize: '0.9rem',
                                                        marginBottom: '5px',
                                                        color: '#121C1E',
                                                    }}
                                                >{t(itm[0])}</p>
                                                <p
                                                    style={{
                                                        borderBottom: '1px solid #ccc',
                                                        fontSize: '0.8rem',
                                                        paddingBottom: '2px'
                                                    }}
                                                >{itm[1]}</p>
                                            </div>
                                        )
                                    }
                                })
                                :
                                <React.Fragment>
                                    <UserAnalyzesForm onSubmit={(data: any) => this.onSubmit(data)} onFilesChange={(selectedFiles, deletedFiles) => this.setState({ selectedFiles, deletedFiles })} />

                                    <SaveButton text={t('Save')}
                                        loading={patientAnalyzeLoading}
                                        onClickFunc={() => this.props.submit('UserAnalyzesForm')}
                                    />
                                </React.Fragment>
                        }
                    </Modal.Body>
                </Modal>

                <div className='medical_card_item_header'>
                    <div className='md_item_title_image'>
                        <div><MyAnalyzes /></div>
                        <h1>{t('My analyzes')}</h1>
                    </div>
                    <div className='button_blck' style={fromDocItems ? { display: 'none' } : {}}>
                        <div className='show-more-btn'
                            onClick={() => {
                                this.setState({ updateItem: false })
                                this.props.reset('UserAnalyzesForm')
                                this.handleShow();
                            }}
                        >
                            <p className='show-more-btn-title'>{t('Add')}</p>
                        </div>
                    </div>
                </div>

                <div className='medical_card_item_desc' style={
                    this.props.auth?.userToken?.role === 3 ? {} : { display: 'none' }}>
                    <p>{t('In the "My analyzes" section you can add and view the results of your laboratory analyzes. You can also take a photo or scan of the lab results issued to you on paper and attach them as files.')}</p>
                </div>

                <div className='medical_card_item_data_block'>
                    <p className='medical_card_item_title'>{this.props.t('All data')}</p>

                    <div className='button_blck' style={fromDocItems ? { display: 'none' } : {}}>
                        {!this.state.removeMode && !!itemsList?.length && <button onClick={() => this.setState({ removeMode: {} })} className='blue-btn btn btn-primary'>{t('Delete')}</button>}
                        {this.state.removeMode && <button onClick={() => this.setState({ removeMode: null })} className='blue-btn btn btn-primary cancel-btn'>{t('Cancel')}</button>}
                        {this.state.removeMode && <button onClick={() => this.confirmDelete()} className='blue-btn btn dark btn-primary'>{t('Confirm')}</button>}
                    </div>
                    {this.props.patientInfoBlock}
                    <div className='table_data'>
                        <table className={this.props.auth?.userToken?.role === 3 ? 'pointer' : ''}>
                            <tr>
                                <th>{this.props.t('Description')}</th>
                                <th>{this.props.t('Clinic')}</th>
                                <th>{this.props.t('Doctor')}</th>
                                <th>{this.props.t('Type')}</th>
                                <th>{this.props.t('Date')}</th>
                                <th>{this.props.t('File')}</th>
                            </tr>
                            {itemsList instanceof Array && itemsList.map((item: any, index: any) => {

                                const filesURL = 'https://topdoktor.org/uploads/patients/analysis/';

                                return (
                                    <tr onClick={async () => {
                                        if (this.state.removeMode || forDocItem) {
                                            return;
                                        }
                                        await this.props.getPatientAnalyze(item.id);
                                        this.setState({ updateItem: true })
                                        this.handleShow();
                                    }}>
                                        {!forDocItem ?
                                            <React.Fragment>
                                                <td>
                                                    {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                                                        {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                        {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                                    </div>}

                                                    {item.description}
                                                </td>
                                                <td>{item.clinic_id}</td>
                                                <td>{item.doctor_id}</td>
                                                <td>{item.type}</td>
                                                <td>{item.date}</td>
                                                <td>{item.uploadedfiles ? item.uploadedfiles.length : 0}</td>
                                            </React.Fragment>
                                            :
                                            <React.Fragment>
                                                <td>
                                                    {this.state.removeMode && <div style={{ alignItems: 'center' }}>
                                                        {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                                        {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                                    </div>}

                                                    {item.Description}
                                                </td>
                                                <td>{item.Clinic}</td>
                                                <td>{item.Doctor}</td>
                                                <td>{item.Type}</td>
                                                <td>{item.Date}</td>
                                                <td>
                                                    {
                                                        item.Files && item.Files.length ? item.Files.map((f: any, i: any) => {
                                                            return (
                                                                <React.Fragment>
                                                                    <a href={filesURL + f.filename} target="_blank">
                                                                        {i + 1}
                                                                    </a>
                                                                    {item.Files.length - 1 === i ? '' : <span>,</span>}
                                                                </React.Fragment>
                                                            )
                                                        }) : <span>Нет</span>
                                                    }
                                                </td>
                                            </React.Fragment>
                                        }
                                    </tr>
                                )
                            })}
                        </table>
                        {this.props.loading.subjects.has(LoadingSubject.PatientAnalyzeList) ? <div style={{ marginTop: '20px' }}><LoadingCenter /></div> :
                            !itemsList || itemsList.length === 0 && <div className='no-items-no-results'> <p>{t('No data available')}</p></div>}
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    patientAnalyze: state.patientAnalyze,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    getPatientAnalyzeList,
    deletePatientAnalyze,
    change,

    createPatientAnalyze,
    updatePatientAnalyze,
    getPatientAnalyze,
    _patientAnalyzeItem,
};

let MyAnalyzesComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(MyAnalyzesComponent)
);

export default MyAnalyzesComponentContainer