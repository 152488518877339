import React from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import CSS from 'csstype';
import './Vacantions.scss';

interface VacantionsProps {
    t: TFunction;
}

interface VacantionsState {

}

class Vacantions extends React.Component<VacantionsProps, VacantionsState> {
    constructor(props: VacantionsProps) {
        super(props);
    }

    render() {
        const { t } = this.props;

        return (
            <>
                <div className='vacantions'>
                    <div className='vacantions__title-block' >
                        <p className='big-title team-big-title vacantions'>{t('Our vacantions')}</p>
                    </div>
                    <div className='vacantions__block'>
                        <div className='team__block__text'>
                            <p>{t("There are no current vacancies")}</p>
                            {/* <p>{t('At the moment, our company requires specialists in the following areas:')}</p>
                            <ul className='dashed'>
                                <li>IT helpdesk;</li>
                                <li>System administrator;</li>
                                <li>DevOps specialist;</li>
                                <li>Sales and Marketing specialist;</li>
                            </ul>
                            <p>{t('You can connect with us by phone number {{number}}, and mailing us to our email address {{email}}', {number: '+(994) 51-206-38-13', email: 'ezgilmedical@gmail.com'})}</p> */}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const pageStyle: CSS.Properties = {
    height: 'calc(100vh - 455px - 110px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}

export default withTranslation()(Vacantions);