import React, { Component } from 'react';
import { connect } from "react-redux";
import { submit, FormAction, reset, initialize } from "redux-form";
import { RootState } from "../../store";
import './PersonalCabinetPatient.scss';
import { signIn, signUp, doChange, changePassword, authErrorText } from './../../actions/auth-actions';
import { IAuthState } from '../../states/auth-state';
import PersonalCabinetPatientForm from '../../forms/PersonalCabinetPatientForm';
import { Link } from 'react-router-dom';
import { signOut } from '../../actions/auth-actions';
import QuickAccessComponentContainer from './../QuickAccess/QuickAccess';
import { IProfile } from '../../models/profile';
import { IDoctor } from '../../models/doctor';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import DoctorDutyImg from '../../images/patient-cabinet/doctor-duty.png';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PersonalCabinetPasswordForm from '../../forms/PersonalCabinetPasswordForm';
import { ILoadingState, LoadingSubject } from '../../states/loading-state';
import Spinner from 'react-spinner-material';

interface MyProps {
    t: TFunction;
    submit: (form: string) => FormAction;
    auth: IAuthState;
    loading: ILoadingState;
    signOut: (token: string) => void;
    doChange: (user: IProfile, token: string) => void;
    changePassword: (token: string, user: IProfile | IDoctor) => void;
    reset: (form: string) => void;
    initialize: (form: string, data: any, keepDirty?: boolean) => void;
    authErrorText: (text: string) => void;
}

interface MyState {
    editMode: boolean;
    changePasswordMode: boolean;
    fields?: { [name: string]: string }
    selectedIndex: number;
    changePasswordLoading: boolean;
}

class PersonalCabinetPatientComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            editMode: true,
            changePasswordMode: false,
            selectedIndex: 0,
            changePasswordLoading: false,
        };
    }

    async onSubmit(data: IProfile) {
        if (!this.state.changePasswordMode) {
            await this.props.doChange(data, this.props.auth.userToken?.auth as string);
        }
        else {
            this.setState({ changePasswordLoading: true })
            await this.props.changePassword(this.props.auth.userToken?.auth as string, data);
            this.setState({ changePasswordMode: !this.state.changePasswordMode, changePasswordLoading: false });
        };
    };

    onEdit() {
        if (this.state.editMode) this.props.submit('PersonalCabinetPatientForm');
    }

    async onEditPass() {
        if (this.state.changePasswordMode) {
            this.props.submit('PersonalCabinetPasswordForm');
        } else {
            this.props.initialize('PersonalCabinetPasswordForm', {});
            this.setState({ changePasswordMode: !this.state.changePasswordMode });
        }
    }

    componentDidMount() {
        this.props.authErrorText('');
    }

    render() {
        const { t } = this.props;
        const { changePasswordLoading } = this.state;

        const renderMsg = () => {
            const msgText = this.props.auth.authErrorText;
            if (msgText) {
                return (
                    <div className='message-text'>
                        <p>{msgText}</p>
                    </div >
                )
            }
        };

        console.log('d')

        return (
            <>
                <div className='cabinet-wrapper'>
                    <Tabs value={this.state.selectedIndex} onChange={(_, v) => this.setState({ selectedIndex: v })}>
                        <Tab label={t('My profile')} {...a11yProps(0)} />
                        <Tab label={t('My data')} {...a11yProps(1)} />
                    </Tabs>

                    <TabPanel value={this.state.selectedIndex} index={1}>
                        {renderMsg()}
                        <PersonalCabinetPatientForm
                            editMode={this.state.editMode}
                            onSubmit={(data: IProfile) => this.onSubmit(data)} />
                        {this.state.changePasswordMode &&
                            <PersonalCabinetPasswordForm
                                onSubmit={(data: IProfile) => this.onSubmit(data)} />
                        }
                        <div className='bottom-block'>
                            {this.state.changePasswordMode &&
                                <button onClick={() => this.setState({ changePasswordMode: false })} className='blue-btn btn btn-primary'>{t('Cancel')}</button>
                            }
                            {this.state.editMode &&
                                <button className={'blue-btn btn' + (this.state.changePasswordMode ? ' dark' : '') + ' btn-primary'}
                                    onClick={async () => {
                                        await this.onEditPass();
                                    }}
                                    disabled={changePasswordLoading}
                                >
                                    <div style={{ display: 'flex' }}>
                                        {changePasswordLoading &&
                                            <div style={{ marginRight: '8px' }}>
                                                {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                            </div>}
                                        {t('Change Password')}
                                    </div>
                                </button>
                            }
                            {!this.state.changePasswordMode &&
                                <button onClick={() => this.onEdit()}
                                    className='blue-btn btn dark btn-primary spinner-button'>
                                    {
                                        this.props.loading.subjects.has(LoadingSubject.UpdateProfile) ||
                                        this.props.loading.subjects.has(LoadingSubject.ChangePswd) &&

                                        <div className='spinner-div'>
                                            <Spinner radius={19} color={"white"} stroke={2} visible={true} />
                                        </div>
                                    }
                                    <div>
                                        {this.state.editMode ? t('Save') : t('Edit')}
                                    </div>
                                </button>
                            }
                        </div>
                    </TabPanel>
                    <TabPanel value={this.state.selectedIndex} index={0}> 
                        <div className='my-profile'>
                            <div className='features-block'>
                                <Link to={{ pathname: '/my-doctors' }} className='item'>
                                    <img src={require('./../../images/patient-cabinet/my-doctors.png')} />
                                    <div>
                                        <h5>{t('My doctors')}</h5>
                                        <p>{t('In this section you can add the doctors you like.')}</p>
                                    </div>
                                </Link>
                                <Link to={{ pathname: '/doctors' }} className='item'>
                                    <img src={require('./../../images/patient-cabinet/doctors.png')} />
                                    <div>
                                        <h5>{t('Doctors')}</h5>
                                        <p>{t('In this section, you can choose from a variety of doctors, using various filters, you can choose the one that suits you!')}</p>
                                    </div>
                                </Link>
                                <Link to='/medical-card' className='item'>
                                    <img src={require('./../../images/patient-cabinet/my-med-card.png')} />
                                    <div>
                                        <h5>{t('My medcard')}</h5>
                                        <p>{t('In this section, you can take your medical notes and keep your personal indicators, monitor their dynamics.')}</p>
                                    </div>
                                </Link>
                                <Link to='/online-appointment' className='item'>
                                    <img src={require('./../../images/patient-cabinet/my-video-appointments.png')} />
                                    <div>
                                        <h5>{t('My consultations')}</h5>
                                        <p>{t('Here you can make an appointment for an online consultation with a doctor, choosing the right doctor and a convenient appointment time for you.')}</p>
                                    </div>
                                </Link>
                                <Link to={{ pathname: '/duty-doctors' }} className='item'>
                                    <img src={DoctorDutyImg}/>
                                    <div>
                                        <h5>{t('Duty doctors')}</h5>
                                        <p>{t('In this section, you can contact the doctor on duty online at any time of the day, without an appointment.')}</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </TabPanel>
                </div>
            </>
        );

    }
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        value: index,
    };
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    signIn,
    signUp,
    signOut,
    doChange,
    changePassword,
    reset,
    initialize,
    authErrorText,
};

let PersonalCabinetPatientComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(PersonalCabinetPatientComponent)
);

export default PersonalCabinetPatientComponentContainer