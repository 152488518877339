import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import { authReducer } from '../reducers/auth-reducer';
import {loadingReducer} from '../reducers/loading-reducer';
import { callReducer } from '../reducers/call-reducer';
import { filtersReducer } from "../reducers/filters-reducer";
import { alertReducer } from "../reducers/alert-reducer";
import { settingsReducer } from "../reducers/settings-reducer";
import { patientDoctorReducer } from "../reducers/patient-doctor-reducer";
import { doctorReducer } from "../reducers/doctor-reducer";
import { patientClinicReducer } from "../reducers/patient-clinic-reducer";
import { schedulingItemsReducer } from "../reducers/scheduing-items-reducer";
import { schedulingItemRecordsReducer } from "../reducers/scheduing-item-records-reducer";
import { dictionaryReducer } from "../reducers/dictionary-reducer";
import { clinicReducer } from "../reducers/clinic-reducer";
// import {deviceReducer} from "../reducers/device-reducer";
import { websocketReducer } from '../reducers/websocket-reducer';
// import { notifReducer } from '../reducers/notif-reducer';
import { patientSurveyReducer } from '../reducers/patient-survey-reducer';
import { fileReducer } from '../reducers/file-reducer';
import { patientVaccinationReducer } from '../reducers/patient-vaccination-reducer';
import { patientNoteReducer } from '../reducers/patient-note-reducer';
import { patientMedicineReducer } from '../reducers/patient-medicine-reducer';
import { patientDoctorAccessReducer } from '../reducers/patient-doctor-access-reducer';
import { patientConsultationHistoryReducer } from '../reducers/patient-consultaion-history-reducer';
import { patientAnalyzeReducer } from '../reducers/patient-analyze-reducer';
import { patientAllergyReducer } from '../reducers/patient-allergy-reducer';
import { dutyReducer } from '../reducers/duty-reducer';
import { statisticReducer } from '../reducers/statistics-reducer';
import { indicatorsReducer } from '../reducers/indicator-reducer';
import { profileReducer } from '../reducers/profile-reducer';
// import { ratingReducer } from '../reducers/rating-reducer';

const rootReducer = combineReducers({
  form: formReducer,
  auth: authReducer,
  loading: loadingReducer,
  call: callReducer,
  filters: filtersReducer,
  alert: alertReducer,
  patientDoctor: patientDoctorReducer,
  patientClinic: patientClinicReducer,
  doctor: doctorReducer,
  settings: settingsReducer,
  schedulingItems: schedulingItemsReducer,
  schedulingItemRecords: schedulingItemRecordsReducer,
  dictionary: dictionaryReducer,
  clinic: clinicReducer,
  // device: deviceReducer,
  websocket: websocketReducer,
  // notification: notifReducer,
  patientAllergy: patientAllergyReducer,
  patientAnalyze: patientAnalyzeReducer,
  patientConsultationHistory: patientConsultationHistoryReducer,
  patientDoctorAccess: patientDoctorAccessReducer,
  patientMedicine: patientMedicineReducer,
  patientNote: patientNoteReducer,
  patientSurvey: patientSurveyReducer,
  patientVaccination: patientVaccinationReducer,
  file: fileReducer,
  duty: dutyReducer,
  // rating: ratingReducer,
  statistics: statisticReducer,
  indicators: indicatorsReducer,

  profile: profileReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
