import React from 'react';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { RootState } from '../../store';
import { IDictionary } from '../../models/dictionary';
import { DictionaryTypesEnum } from '../../models/dictionary-types.enum';
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { ILoadingState, LoadingSubject } from '../../states/loading-state';
import { LoadingCenter } from '../Loading/Loading';

interface DiseasesProps {
    t: TFunction;
    loading: ILoadingState;
    diseases: IDictionary[] | undefined;
}

interface DiseasesState {
    sortedDiseases?: IDictionary[];
    alphabetDiseases: {};
    showDiseases: boolean;
    loading: boolean;
}

class Diseases extends React.Component<DiseasesProps, DiseasesState> {
    constructor(props: DiseasesProps) {
        super(props);
        this.state = {
            sortedDiseases: [],
            alphabetDiseases: {},
            showDiseases: true,
            loading: true,
        }
    }

    componentDidMount() {
    }

    componentDidUpdate(prev: DiseasesProps) {
        if (prev.diseases !== this.props.diseases) {
            setTimeout(() => {
                this.sortAndCreateDiseasesAlphabet();
            });
        }
    }

    sortAndCreateDiseasesAlphabet() {
        const sAlph: IDictionary[] | undefined = this.props.diseases?.sort((a, b) => {
            if (a.name.replace('\"', '') < b.name.replace('\"', '')) return -1;
            if (a.name.replace('\"', '') > b.name.replace('\"', '')) return 1;
            return 0;
        });

        this.setState({sortedDiseases: sAlph});

        let letters = {};

        for (let diseas of (sAlph || [])) {
            const firstLetter = diseas.name.replace('\"', '')[0];
            if (letters[firstLetter]) {
                letters[firstLetter] = [...letters[firstLetter], diseas]
            } else {
                letters[firstLetter] = [diseas]
            }
        }

        this.setState({alphabetDiseases: letters}, () => {this.setState({loading: false})});
    }
    // showDiseasesHandler() {
    //     this.setState({showDiseases: !this.state.showDiseases});
    // }

    render() {
        const { t } = this.props;
        return(
            <div className="home">
                <div className="content-block">
                    <h1 className="big-title">{t('Directory of diseases')}</h1>
                </div>
                <div className={'alphabet opened'}>
                    {Object.keys(this.state.alphabetDiseases).map((letter, i) => (
                        <div className='alphabet-item' key={letter}>
                            <p className='alphabet-item-title'>{letter}</p>
                            <div className='alphabet-data-container'>
                                {this.state.alphabetDiseases[letter].map(disease => (
                                    <Link to={`/doctors?disease=${disease.id}`} key={disease.id}><p className='alphabet-data-name'>{disease.name}</p></Link>        
                                ))}
                            </div>
                        </div>)
                    )}
                </div>
                {(this.props.loading.subjects.has(LoadingSubject.Dictionaries) || this.state.loading) && <LoadingCenter/>}
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    diseases: state.dictionary?.diseases,
    loading: state.loading,
});

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Diseases));