import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './QuickAccess.scss';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import { ReactComponent as LocationIcon } from './../../images/quick-access/location.svg';
import { ReactComponent as WalletIcon } from './../../images/quick-access/wallet.svg';
import { ReactComponent as OnlineRecordIcon } from './../../images/quick-access/online-record.svg';

interface MyProps {
    t: TFunction
}

interface MyState { }

class QuickAccessComponent extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {

        };
    }

    render() {
        const { t } = this.props;
        return (
            <div className='quick-access-wrapper'>
                <div className='qa-item-wrapper'>
                    <div className='qa-item-img-block'>
                        {/* <img className='qa-item-img' src={require('./../../images/quick-access/location.png')} alt='location' /> */}
                        <LocationIcon />
                    </div>
                    <div className='qa-item-other'>
                        <h1 className='qa-item-title'>{t('Search doctor near you')}</h1>
                        <p className='qa-item-desc'>{t('Just point to the metro and choose')}</p>
                    </div>
                </div>
                <div className='qa-item-wrapper'>
                    <div className='qa-item-img-block'>
                        {/* <img className='qa-item-img' src={require('./../../images/quick-access/wallet.png')} alt='wallet' /> */}
                        <WalletIcon />
                    </div>
                    <div className='qa-item-other'>
                        <h1 className='qa-item-title'>{t('Price without comissions')}</h1>
                        <p className='qa-item-desc'>{t('Price as clinic')}</p>
                    </div>
                </div>
                <div className='qa-item-wrapper'>
                    <div className='qa-item-img-block'>
                        {/* <img className='qa-item-img' src={require('./../../images/quick-access/online-record.png')} alt='online-record' /> */}
                        <OnlineRecordIcon />
                    </div>
                    <div className='qa-item-other'>
                        <h1 className='qa-item-title'>{t('Quick online-appointment')}</h1>
                        <p className='qa-item-desc'>{t('Without call to clinic')}</p>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({

});

const mapDispatchToProps = {

};

let QuickAccessComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(QuickAccessComponent)
);

export default QuickAccessComponentContainer