import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../../store";
import './DoctorProfile.scss';
import { IAuthState } from "../../../states/auth-state";
import { submit, reset, FormAction, change } from 'redux-form';
import { ILoadingState, LoadingSubject } from "../../../states/loading-state";
import { IDictionaryState } from "../../../states/dictionary-state";
import Modal from 'react-bootstrap/Modal';
import { TFunction } from 'i18next';
import { WithTranslation, withTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { showConfirm } from '../../Dialogs';
import { IProfileState } from '../../../states/profile-state';
import { IProfileSection } from '../../../models/profile-section';
import { getProfileValue, getProfileValues, profileValueChangeBulk, profileValueDeleteBulk, profileValueSave } from '../../../actions/profile-actions';
import { UserRole } from '../../../models/user-role.enum';
import history from "./../../../history";
import DoctorProfileForm from './DoctorProfileForm';
import { LoadingCenter } from '../../Loading/Loading';
import Breadcrumbs from './../../Breadcrumbs/Breadcrumbs';
import { IProfileValue } from '../../../models/profile-value';
import { IDictionary } from '../../../models/dictionary';
import { ProfileValueType } from '../../../models/profile-value-type';
import moment from 'moment';
import Spinner from 'react-spinner-material';
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';

interface MyProps {
    t: TFunction;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    loading: ILoadingState;
    auth: IAuthState;
    dictionary: IDictionaryState;
    pushAlert: (text: string) => void;
    location: Location;

    value: number;

    profile: IProfileState;

    getProfileValues: (
        section: IProfileSection,
        page: number
    ) => void;

    getProfileValue: (
        id: number
    ) => Promise<{ [key: string]: any } | null>;

    profileValueSave: (
        values: { [key: string]: any },
        success: () => void,
    ) => void;

    profileValueDeleteBulk: (
        values: { [key: string]: any }[],
    ) => void;

    profileValueChangeBulk: (
        toDelete: { [key: string]: any }[],
        toAdd: { [key: string]: any }[],
    ) => void;
}

interface MyState {
    updateItem: { [key: string]: any } | null;
    loading: boolean;
    lang: string;
    removeMode: {} | null;
    changeMode: { [key: string]: any }[] | null;
    searchQuery: string;
    resultData: { [key: string]: any; }[]
    searchPressed: boolean;
}

class ProfileComponent extends Component<MyProps & WithTranslation, MyState> {

    constructor(props: MyProps & WithTranslation) {
        super(props);

        this.state = {
            updateItem: null,
            removeMode: null,
            changeMode: null,
            lang: props.i18n.language,
            loading: false,
            searchQuery: '',
            resultData: [],
            searchPressed: false,
        };

        const role = props.auth.userToken?.role;

        if (role !== UserRole.Doctor && role !== UserRole.Clinic) {
            history.push('/');
            return;
        }
        const section = role === UserRole.Doctor ? props.profile.section : props.profile.section;


        props.getProfileValues(section, 0);
    }

    setActive(i: IProfileSection) {
        this.setState({ removeMode: null, changeMode: null, resultData: [], searchQuery: '', searchPressed: false });
        this.props.getProfileValues(i, 0);
    }

    updateListData(page: number) {
        const role = this.props.auth?.userToken?.role;
        const section = role === UserRole.Doctor ? this.props.profile.section : this.props.profile.section;

        this.props.getProfileValues(section, page);
    }

    async confirmDelete() {
        if (await showConfirm("Are you sure to delete this item(s)?")) {
            const lines: { [key: string]: any }[] = [];
            for (const index in this.state.removeMode) {
                if (this.state.removeMode[index]) {
                    lines.push(this.props.profile.values[index]);
                }
            }
            this.props.profileValueDeleteBulk(lines);
            this.setState({ removeMode: null });
        }
    }

    onSubmit(data: { [key: string]: any }) {
        console.log('data', data);
        this.props.profileValueSave(data, () => {
            this.setState({ updateItem: null });
        });
    }

    setLanguage(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, lang: string): void {
        e.preventDefault();
        this.setState({ lang });
    }

    itemLabel(item: { [key: string]: any }, value: IProfileValue) {
        const raw = item[value.name];
        if (value.type === ProfileValueType.dictionary) {
            const dict = this.props.dictionary[value.dictionary + 'Map'];
            if (dict && dict[raw] !== undefined) {
                return (dict[raw] as IDictionary).name;
            }
        } else if (value.type === ProfileValueType.boolean) {
            return raw == 1 ? this.props.t('Yes') : this.props.t('No');
        } else if (value.type === ProfileValueType.date) {
            return raw === '0000-00-00 00:00:00' ? '' : moment(raw).format("DD.MM.YYYY");
        }
        return raw;
    }

    confirmChange() {
        const { changeMode } = this.state;
        if (!changeMode) {
            return;
        }
        const toDelete: { [key: string]: any }[] = [];
        const toAdd: { [key: string]: any }[] = [];
        for (const c of changeMode) {
            if (c.id && !c.selected) {
                toDelete.push(c);
                continue;
            }
            if (!c.id && c.selected) {
                toAdd.push(c);
            }
        }
        this.props.profileValueChangeBulk(toDelete, toAdd);
        this.setState({ changeMode: null });
    }

    change() {
        let { profile: { values }, dictionary } = this.props;
        const { resultData } = this.state;

        values = resultData.length ? resultData : values;

        const role = this.props.auth?.userToken?.role;
        const section = role === UserRole.Doctor ? this.props.profile.section : this.props.profile.section;

        const map: { [key: string]: boolean } = {};
        if (!values || !section?.values?.length) {
            return;
        }
        const changeMode = values.map(v => {
            map[v.id] = true;
            return { ...v, selected: true };
        });

        const value = section.values[0];
        const dict = dictionary[value.dictionary || ''] || [];

        if (!dict.length) {
            return;
        }

        const dictData = dict.filter(v => !map[v.id]).map(v => ({ [value.name]: v.id, selected: false }));

        this.setState({ changeMode: changeMode.concat(dictData) });
    }

    table() {
        const { t, getProfileValue } = this.props;
        const role = this.props.auth?.userToken?.role;
        const section = role === UserRole.Doctor ? this.props.profile.section : this.props.profile.section;

        const { changeMode, resultData } = this.state;
        const tableValues = section.values.filter(v => v.table);
        let values = changeMode || this.props.profile.values;

        if (!values.map) {
            values = [];
        }

        const singleDictionary = section.values?.length === 1 && section.values[0].type === ProfileValueType.dictionary;

        if (resultData.length && singleDictionary) {
            values = resultData;
        }

        if (!resultData.length && this.state.searchPressed) {
            return (
                <div className='no-results'><p>no results</p></div>
            )
        }

        return <table className={this.props.auth?.userToken?.role === 3 ? 'pointer' : ''}>
            <tr>
                {tableValues?.map(v => <th>{t(v.title)}</th>)}
            </tr>
            {values.map((item, index) => {
                return (
                    <tr onClick={async () => {
                        if (singleDictionary) {
                            return;
                        }
                        if (this.state.removeMode) {
                            return;
                        }
                        // await this.props.getPatientAllergy(item.id);
                        this.setState({ updateItem: {}, loading: true });
                        const updateItem = await getProfileValue(item.id);
                        if (!updateItem) {
                            this.setState({ updateItem: null, loading: false });
                            return;
                        }
                        this.setState({ updateItem, loading: false });
                    }}>
                        {tableValues?.map((v, i) => {
                            return (
                                <td key={i}>
                                    {this.state.removeMode && i === 0 && <div style={{ alignItems: 'center' }}>
                                        {!this.state.removeMode[index] && <RadioButtonUncheckedIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: true } })} fontSize='small' htmlColor='#007bff' />}
                                        {this.state.removeMode[index] && <CheckCircleOutlineIcon onClick={() => this.setState({ removeMode: { ...this.state.removeMode, [`${index}`]: false } })} fontSize='small' htmlColor='#007bff' />}
                                    </div>}
                                    {this.state.changeMode && i === 0 && <div style={{ alignItems: 'center' }}>
                                        {!item.selected && <RadioButtonUncheckedIcon onClick={() => this.setState({
                                            changeMode: this.state.changeMode ? [...this.state.changeMode.map((w, j) => {
                                                if (j === index) {
                                                    return { ...w, selected: true };
                                                }
                                                return w;
                                            })] : []
                                        })} fontSize='small' htmlColor='#007bff' />}
                                        {item.selected && <CheckCircleOutlineIcon onClick={() => this.setState({
                                            changeMode: this.state.changeMode ? [...this.state.changeMode.map((w, j) => {
                                                if (j === index) {
                                                    return { ...w, selected: false };
                                                }
                                                return w;
                                            })] : []
                                        })} fontSize='small' htmlColor='#007bff' />}
                                    </div>}
                                    {this.itemLabel(item, v)}
                                </td>
                            )
                        }

                        )}
                    </tr>
                )
            })}
        </table>
    }

    singleItem() {
        const { t, profile: { values } } = this.props;
        const role = this.props.auth?.userToken?.role;
        const section = role === UserRole.Doctor ? this.props.profile.section : this.props.profile.section;
        const saveLoading = this.props.loading.subjects.has(LoadingSubject.SaveProfileValue);
        const { lang } = this.state;
        if (!values?.length) {
            return <LoadingCenter />;
        }
        return <div className="right-block">
            <div className='container-header'>
                <div className='title-action'>
                    <span>{t(section.title)}</span>
                    <div className="btns">
                        <div>
                            <a href="#" className={'lang-link' + (lang === 'az' ? ' lang-link-selected' : '')} onClick={(e) => this.setLanguage(e, 'az')} >Az</a>
                            <a href="#" className={'lang-link' + (lang === 'ru' ? ' lang-link-selected' : '')} onClick={(e) => this.setLanguage(e, 'ru')} >Ru</a>
                            <a href="#" className={'lang-link' + (lang === 'en' ? ' lang-link-selected' : '')} onClick={(e) => this.setLanguage(e, 'en')} >En</a>
                        </div>
                        {!this.state.changeMode && <button onClick={() => this.props.submit('DoctorProfileForm')} className='blue-btn btn btn-primary'>
                            {saveLoading && <div style={{ marginRight: '8px' }}>
                                {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                            </div>}
                            {t('Save')}
                        </button>}
                    </div>
                </div>
            </div>
            <div className='container-section-2'>
                <DoctorProfileForm lang={lang} onSubmit={(data: { [key: string]: any }) => this.onSubmit(data)} section={section} values={values[0]} />
            </div>
        </div>;
    }

    filterSingles(searchQuery: string) {
        const { changeMode } = this.state;
        const section = this.props.profile.section;
        const tableValues = section.values.filter(v => v.table);

        let resultData: { [key: string]: any; }[] = [];

        if (!searchQuery) {
            this.setState({ resultData: resultData, searchPressed: false })
            return;
        }

        let values = changeMode?.length ? changeMode : this.props.profile.values;

        values.map((item, index) => {
            tableValues?.map((v, i) => {
                let ab = this.itemLabel(item, v);
                if (ab.toLowerCase().includes(searchQuery.toLocaleLowerCase())) {
                    resultData.push(item);
                }
            })
        })

        this.setState({ resultData: resultData, searchPressed: true });
    }

    showSearchBox() {
        const { t } = this.props;
        const { searchQuery } = this.state;
        return (
            <div className='search-wrapper'>
                <Form.Control
                    size="sm"
                    type="text"
                    value={searchQuery}
                    onChange={(e) => {
                        this.setState({ searchQuery: e.target.value });
                        this.filterSingles(e.target.value);
                    }}
                    placeholder={t('Search') + '...'}
                />
            </div>
        )
    }

    render() {
        const { t, profile: { values } } = this.props;
        const { lang, loading } = this.state;

        const role = this.props.auth?.userToken?.role;

        const section = role === UserRole.Doctor ? this.props.profile.section : this.props.profile.section;
        const sections = role === UserRole.Doctor ? this.props.profile.sections : this.props.profile.sections;

        const handleClose = () => {
            this.setState({ updateItem: null });
        };

        const currLang = (langLetter: string) => {
            if (langLetter === this.props.i18n.language) {
                return 'current-lang';
            } else return '';
        }

        const singleDictionary = section.values?.length === 1 && section.values[0].type === ProfileValueType.dictionary;
        const saveLoading = this.props.loading.subjects.has(LoadingSubject.SaveProfileValue);

        return (<>
            <div style={{ maxWidth: '910px', margin: '20px auto 0px auto' }}><Breadcrumbs location={this.props.location} /></div>

            <div className='home'>
                {this.state.updateItem && <Modal centered show={!!this.state.updateItem} onHide={handleClose}>
                    <Modal.Header className='my-meds-modal-header' closeButton>
                        <Modal.Title className='dp-modal-title'>
                            {
                                this.state.updateItem.id ?
                                    t('Change')
                                    : t('Add')
                            }
                            <div className='dp-modal-title__lang'>
                                <span className={'language ' + currLang('az')} onClick={() => this.props.i18n.changeLanguage('az')}>Az</span>
                                <span className={'language ' + currLang('ru')} onClick={() => this.props.i18n.changeLanguage('ru')}>Ru</span>
                                <span className={'language ' + currLang('en')} onClick={() => this.props.i18n.changeLanguage('en')}>En</span>
                            </div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {!loading && <DoctorProfileForm lang={lang} onSubmit={(data: { [key: string]: any }) => this.onSubmit(data)} section={section} values={this.state.updateItem} />}

                        {loading && <LoadingCenter />}

                        <div className='modal-button-block'>
                            <button className='blue-btn btn btn-primary auth-button'
                                onClick={handleClose}>{t('Cancel')}
                            </button>
                            {!loading &&
                                <button className='blue-btn btn btn-primary dark auth-button'
                                    onClick={() => this.props.submit('DoctorProfileForm')}
                                    disabled={saveLoading}
                                >
                                    <div style={{ display: 'flex' }}>
                                        {saveLoading &&
                                            <div style={{ marginRight: '8px' }}>
                                                {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                            </div>}
                                        {t('Save')}
                                    </div>
                                </button>}
                        </div>
                    </Modal.Body>
                </Modal>}

                <div className='my-dp-wrapper'>
                    <div className="left-block">
                        {sections.map(i => <div className={'item' + (section.name === i.name ? ' item-active' : '')} onClick={() => this.setActive(i)}>
                            {i.icon}
                            <span>{t(i.title)}</span>
                            <img className="arrow-left" src={require('../../../images/medical-card/small-icons/sort-down.svg')} />
                        </div>)}
                    </div>

                    {!section && <div className="right-block">
                        <div className="no-item-selected">{t('No profile selected')}</div>
                    </div>}
                    {section && section.singleLoad && this.singleItem()}
                    {section && !section.singleLoad && <div className="right-block">
                        <div className='container-header'>
                            <div className='title-action'>
                                <span>{t(section.title)}</span>
                                <div className="btns">
                                    {!singleDictionary && <>{!!values?.length && <div className="btns">
                                        {!this.state.removeMode && <button onClick={() => this.setState({ removeMode: {} })} className='blue-btn btn btn-primary'>{t('Delete')}</button>}
                                        {this.state.removeMode && <button onClick={() => this.setState({ removeMode: null })} className='blue-btn btn btn-primary'>{t('Cancel')}</button>}
                                        {this.state.removeMode && <button onClick={() => this.confirmDelete()} className='blue-btn btn dark btn-primary'>{t('Delete 2')}</button>}
                                    </div>}
                                        {!this.state.removeMode && <button className='blue-btn btn btn-primary' onClick={() => this.setState({ updateItem: {}, loading: false })}>{t('Add')}</button>}</>}
                                    {singleDictionary && <>
                                        {this.state.changeMode && <button onClick={() => this.setState({ changeMode: null })} className='blue-btn btn btn-primary'>{t('Cancel')}</button>}
                                        {this.state.changeMode && <button onClick={() => this.confirmChange()} className='blue-btn btn dark btn-primary'>{t('Confirm')}</button>}
                                        {!this.state.changeMode && <button onClick={() => this.change()} className='blue-btn btn btn-primary'>{t('Change')}</button>}
                                    </>}
                                </div>
                            </div>
                            {singleDictionary && this.showSearchBox()}
                        </div>
                        <div className='container-section-2'>
                            <div id="all-data" className='all-data table_data'>
                                {singleDictionary ?
                                    this.table() :
                                    <InfiniteScroll
                                        next={() => {
                                            if (this.props.loading.subjects.has(LoadingSubject.ProfileValues) || this.props.loading.subjects.has(LoadingSubject.ProfileValuesNext)) {
                                                return;
                                            }
                                            this.updateListData(this.props.profile.page);
                                        }}
                                        scrollableTarget="all-data"
                                        hasMore={this.props.profile.hasMore}
                                        loader={<LoadingCenter />}
                                        dataLength={values.length}
                                    >
                                        {this.table()}
                                        {/* {!this.props.loading.subjects.has(LoadingSubject.ProfileValues) && this.props.profile.values?.map((r, i) => this.renderListItem(r, i))} */}
                                    </InfiniteScroll>}
                            </div>
                        </div>
                    </div>}
                </div>
                {/* _className='standart-margin-bottom' */}
            </div>
        </>);
    }
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
        value: index,
    };
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    profile: state.profile,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    change,
    getProfileValues,
    profileValueSave,
    profileValueDeleteBulk,
    getProfileValue,
    profileValueChangeBulk,
};

let ProfileComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(ProfileComponent)
);

export default ProfileComponentContainer