import { Action, ActionCreator } from 'redux';
import { DictionaryTypesEnum } from '../models/dictionary-types.enum';
import { IDictionary } from '../models/dictionary';
import { ThunkAction } from 'redux-thunk';
import { pushAlert } from "./alert-actions";
import * as environment from '../app.json';
import { IClinic } from '../models/clinic';
import { RootState } from '../store';
import { UserRole } from '../models/user-role.enum';
import { authFetch, signOut } from './auth-actions';
import { loadingEnd, loadingStart } from './loading-actions';
import { LoadingSubject } from '../states/loading-state';

function topDoctorData(language: string) {
    let topDoctor = environment.topDoctor;
    if (language == 'az') topDoctor = environment.topDoctorAz;
    if (language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const GET_DICTIONARY = 'GET_DICTIONARY';
interface DictionaryAction {
    type: typeof GET_DICTIONARY,
    dictionaryType: DictionaryTypesEnum,
    payload: IDictionary[],
}

export const _dictionaryList: ActionCreator<DictionaryAction> = (dictionaryType: DictionaryTypesEnum, payload: any[]) => {
    return {
        type: GET_DICTIONARY,
        dictionaryType: dictionaryType,
        payload: payload,
    }
}

export type DictionaryActionType = DictionaryAction;

export const getDictionaryList = (
    type: DictionaryTypesEnum,
    language: string,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    if (type === DictionaryTypesEnum.Clinics) {
        dispatch(getClinicsList(language));
        return;
    }
    try {
        console.log('aaaaaa')
        dispatch(loadingStart(LoadingSubject.Dictionaries));
        const response = await fetch(topDoctorData(language) + 'dds/ddlist?type=' + type, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const json = await response.json();

        dispatch(_dictionaryList(type, json.payload))
    } catch (e) {
        console.log('getDictionaryList exception', e);
    } finally {
        dispatch(loadingEnd(LoadingSubject.Dictionaries));
    }
}

const getClinicsList = (
    language: string
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
        dispatch(loadingStart(LoadingSubject.Dictionaries));
        const response = await fetch(topDoctorData(language) + 'clinics/search?per-page=10000', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        const json = await response.json();

        dispatch(_dictionaryList(DictionaryTypesEnum.Clinics, json.map((c: IClinic) => {
            return {
                id: c.id,
                type: DictionaryTypesEnum.Clinics,
                name: c.name,
                description: c.description,
                active: c.active,
                metro: c.metro,
                region: c.region
            } as IDictionary
        })))
    } catch (e) {
        console.log('getClinicsList exception', e);
    } finally {
        dispatch(loadingEnd(LoadingSubject.Dictionaries));
    }
}

export const getSelfClinicsList = (
    ): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    try {
        const auth = getState().auth;
        if (auth.userToken?.role !== UserRole.Doctor) {
            return;
        }

        const response = await authFetch(
            getState, dispatch, environment.baseUrl +
            'external/private/clinic/list', 'POST',
            {myClinic: true}, { Accept: 'application/json', 'Content-Type': 'application/json' }
        );

        if (response.status === 200) {
            const json = await response.json();
            console.log('doctor clinics', json)

            dispatch(_dictionaryList(DictionaryTypesEnum.Work, json.map((c: IClinic) => {return {
                id: c.id,
                type: DictionaryTypesEnum.Work,
                name: c.name,
                description: c.description,
                active: c.active,
            } as IDictionary})));
        }
        else if (response.status === 401) {
            dispatch(signOut(auth.userToken.auth));
        }
        else {
            console.log('DoctorList error', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        console.log('getClinicsList exception', e);
    } finally {
    }
}