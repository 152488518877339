import React from "react";
import { ILoadingState, LoadingSubject } from "../../states/loading-state";
import { IAuthState } from "../../states/auth-state";
import { RootState } from "../../store";
import { connect } from "react-redux";
import { IDictionaryState } from "../../states/dictionary-state";
import { submit, reset, FormAction } from 'redux-form';
import { getPatientDoctorAccessList, deletePatientDoctorAccess } from "../../actions/patient-doctor-access-actions";
import { IPatientDoctorAccessState } from "../../states/patient-doctor-access-state";
import './DoctorsUserAccess.scss';
import { Link } from 'react-router-dom';
import { TFunction } from "i18next";
import { withTranslation } from 'react-i18next';
import { getUserById } from "../../actions/auth-actions";
import moment from 'moment';
import 'moment/locale/az';
import 'moment/locale/ru';
import Breadcrumbs from './../Breadcrumbs/Breadcrumbs';
import history from "./../../history";
import Button from "react-bootstrap/Button";
import Form from 'react-bootstrap/Form';
import { IPatientDoctorAccess } from "../../models/user-doctor-access";
import { LoadingCenter } from "../Loading/Loading";

type Props = {
    t: TFunction;
    submit: (form: string) => FormAction;
    reset: (form: string) => FormAction;
    loading: ILoadingState;
    auth: IAuthState;
    dictionary: IDictionaryState;
    patientDoctorAccess: IPatientDoctorAccessState;
    pushAlert: (text: string) => void;
    getPatientDoctorAccessList: () => void;
    deletePatientDoctorAccess: (id: number) => void;
    getUserById: (id: number, token: string) => void;
    language: string;
    location: Location;
};

type State = {
    selectedPatient: any;
    searchQuery: string;
    resultData: IPatientDoctorAccess[];
    searchPressed: boolean;
}

class UserDoctorsAccessComponent extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            selectedPatient: null,
            searchQuery: '',
            resultData: [],
            searchPressed: false,
        }
    }

    componentDidMount() {
        this.props.getPatientDoctorAccessList();
        moment.locale(this.props.language);
    }

    componentDidUpdate(prev: Props, prevState: State) {
        if (prev.language !== this.props.language) {
            moment.locale(this.props.language);
        }
        if (prevState.selectedPatient !== this.state.selectedPatient) {
            this.props.getUserById(this.state.selectedPatient.patient.ID, this.props.auth.userToken?.auth as string);
        }
    }

    filterPatients() {
        const { searchQuery } = this.state;
        const listData = this.props.patientDoctorAccess.list || [];

        let resultData = listData;

        if (searchQuery) {
            resultData = listData.filter((item: any) => item.patient.Name.toLowerCase().includes(searchQuery.toLowerCase()));
        }
        else {
            this.setState({ resultData: [] });
        }

        this.setState({ resultData: resultData, searchPressed: true });
    }

    render() {
        const { t } = this.props;
        const { searchQuery, resultData, searchPressed } = this.state;

        let listData = searchPressed ? resultData : this.props.patientDoctorAccess.list || [];

        const listLoading = this.props.loading.subjects.has(LoadingSubject.PatientDoctorAccessList);

        console.log('props')

        let bottom: JSX.Element | JSX.Element[];
        if (listLoading) {
            bottom = (
                <td colSpan={5} style={{ padding: '20px 0' }}>
                    <LoadingCenter />
                </td>
            );
        } else if (!listData.length) {
            bottom = (
                <td colSpan={5}>
                    <div style={{ margin: '20px auto', width: '70%' }} className='no-items-no-results'>
                        <p>{searchPressed ? t('No results were found') : t('No data available')}</p>
                    </div>
                </td>
            );
        } else {
            bottom = (listData.map((d: any, index: number) => {
                return (
                    <tr className='patient' onClick={() => { history.push({ pathname: '/medical-card', search: 'item=' + d.id }) }}>
                        <td>{++index}</td>
                        <td><img width={38} height={38} src={d.patient.Image ? 'https://topdoktor.org/uploads/patients/' + d.patient.Image : require('./../../images/doctor-access-user-icon.png')} /></td>
                        <td>{d.patient?.Name}</td>
                        <td>{moment(d.startdate).format('DD.MM.YYYY HH:mm')}</td>
                        <td>{moment(d.enddate).format('DD.MM.YYYY HH:mm')}</td>
                    </tr>
                )
            }));
        }

        return (
            <div className='doctor-user-access-wrapper-main' >
                <h1 className='medical-card-title'>{t('Access for doctors')}</h1>
                <div style={{ display: 'flex', margin: '20px auto', maxWidth: '940px' }}><Breadcrumbs location={this.props.location} /></div>

                <div className='my-md-wrapper'>

                    <div className='search-patient-by-name-block'>
                        <div className='left'>
                            <h6>{t('Access for doctors')}</h6>
                        </div>
                        <div className='right'>
                            <Form.Label className="regular-text">{t('Patient name')}</Form.Label>
                            <Form.Control
                                size="sm"
                                type="text"
                                value={searchQuery}
                                onChange={async (e) => {
                                    await this.setState({ searchQuery: e.target.value });
                                    this.filterPatients();
                                }}
                                placeholder={t('Type your request here...')}
                            />
                        </div>
                    </div>

                    <table className='doctor-to-patient-access-tab'>
                        <tr>
                            <th rowSpan={2}>№</th>
                            <th rowSpan={2}>{t('Photo')}</th>
                            <th rowSpan={2}>{t('Patient')}</th>
                            <th colSpan={2} className='centered'>{t('Access')}</th>
                        </tr>
                        <tr>
                            <th className='centered'>{t('Start')}</th>
                            <th className='centered'>{t('End')}</th>
                        </tr>
                        {bottom}
                    </table>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    dictionary: state.dictionary,
    patientDoctorAccess: state.patientDoctorAccess,
    language: state.settings.language,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    reset,
    getPatientDoctorAccessList,
    getUserById,
    deletePatientDoctorAccess,
}

let UserDoctorsAccessComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    (UserDoctorsAccessComponent)
);

export default withTranslation()(UserDoctorsAccessComponentContainer);
