import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, FormErrors, formValueSelector } from 'redux-form';
import MiniTextInput from './../FormComponents/MiniTextInput';
import { IDoctor_1_Step } from "../../models/doctor-1-step";
import { IFile } from '../../models/file';
import { RootState } from '../../store';
import { IDictionaryState } from '../../states/dictionary-state';
import MiniDropDownSelect from './../FormComponents/MiniDropDownSelect';
import DropdownMulti from './../FormComponents/DropdownMulti';
import FilesComponent from './../FormComponents/FilesComponent';
import { TFunction } from 'i18next';
import { withTranslation } from 'react-i18next';
import './auth-form.scss';

type Props = {
    t: TFunction;
    dictionary: IDictionaryState;
    type: number;
    sex: number;
    scientific_degree: number;
    language: string;
    files: IFile[];
    uploadfileimage: any;
}

class RegistrationFormDoctor1Step extends React.Component<InjectedFormProps<IDoctor_1_Step> & { isChecked: boolean } & Props> {

    render() {
        const { isChecked, t } = this.props;
        const valuesForSexDropdown = [{ value: '0', label: t('Male') },
        { value: '1', label: t('Female') }];

        return (
            <div className='auth-form auth-form-flex'>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="name" label={t('Name')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <Field name="surname" label={t('Surname')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="patronymic" label={t('Patronymic')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <Field name="sex"
                            label={t('Gender')}
                            component={MiniDropDownSelect}
                            valuesForDropdown={valuesForSexDropdown}
                        />
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="birthdate" type='date' placeholder={t('Birthdate')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <Field name="scientific_degree"
                            label={t('Scientific degree')}
                            component={MiniDropDownSelect}
                            selectedValue={this.props.scientific_degree}
                            valuesForDropdown={this.props.dictionary.academicDegree?.map(d => ({ value: d.id, label: d.name }))}
                        />
                    </div>
                </div>
                <div className='auth-form__row'>
                    <Field name="education"
                        label={t('Education')}
                        component={MiniTextInput}
                        type='textarea'
                    />
                </div>
                <div className='auth-form__row'>
                    <Field name="training_work_abroad"
                        label={t('Training work abroad')}
                        component={MiniTextInput}
                        type='textarea'
                    />
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="work_experience" label={t('Work experience')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <Field name="language"
                            label={t('Language')}
                            component={MiniDropDownSelect}
                            type={'multiselect'}
                            placeholder={t('Select')}
                            selectedValues={this.props.language}
                            valuesForDropdown={this.props.dictionary.languages?.map(d => ({ value: d.id, label: d.name }))}
                        />
                    </div>
                    
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="mobil" label={t('Phone number')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <Field name="code" label={t('Code')} component={MiniTextInput}/>
                    </div>
                </div>
                <div className='auth-form__row'>
                    <div className='auth-form__col'>
                        <Field name="work" label={t('Work')} component={MiniTextInput}/>
                    </div>
                    <div className='auth-form__col'>
                        <Field
                            name="uploadfileimage"
                            label={t('Doctor\'s photo')}
                            doctorRegistration={true}
                            files={this.props.files}
                            type={'uploadfileimage/'}
                            placeholder={t('Choose the photo')}
                            component={FilesComponent}
                        />
                    </div>
                </div>
                <div className='auth-form__row'>
                    <Field name="additional_information"
                        label={t('Additional information')}
                        component={MiniTextInput}
                        type='textarea'
                    />
                </div>
                

                
            </div>
        );
    }
}

const validate = (values: IDoctor_1_Step, props: {t: TFunction} & InjectedFormProps<IDoctor_1_Step>): FormErrors<IDoctor_1_Step> => {

    const errors: FormErrors<IDoctor_1_Step> = {};
    const { t } = props;

    if (!values.name || values.name.trim() === '') {
        errors.name = t('Field couldn\'t be empty');
    }
    if (!values.surname || values.surname.trim() === '') {
        errors.surname = t('Field couldn\'t be empty');
    }
    if (!values.patronymic || values.patronymic.trim() === '') {
        errors.patronymic = t('Field couldn\'t be empty');
    }
    if (!values.sex) {
        errors.sex = t('Field couldn\'t be empty');
    }
    if (!values.birthdate) {
        errors.birthdate = t('Field couldn\'t be empty');
    }
    if (!values.education || values.education.trim() === '') {
        errors.education = t('Field couldn\'t be empty');
    }
    if (!values.scientific_degree) {
        errors.scientific_degree = t('Field couldn\'t be empty');
    }
    // if (!values.training_work_abroad || values.training_work_abroad.trim() === '') {
    //     errors.training_work_abroad = t('Field couldn\'t be empty');
    // }
    // if (!values.work_experience || values.work_experience.trim() === '') {
    //     errors.work_experience = t('Field couldn\'t be empty');
    // }
    // if (!values.additional_information || values.additional_information.trim() === '') {
    //     errors.additional_information = t('Field couldn\'t be empty');
    // }
    if ((!values.language) || (typeof values.language === 'object' && values.language.length == 0)) {
        errors.language = t('Field couldn\'t be empty');
    }
    // if (!values.mobil || values.mobil.trim() === '') {
    //     errors.mobil = t('Field couldn\'t be empty');
    // }
    // if (!values.code || values.code.trim() === '') {
    //     errors.code = t('Field couldn\'t be empty');
    // }
    // if (!values.work || values.work.trim() === '') {
    //     errors.work = t('Field couldn\'t be empty');
    // }
    if (!values.uploadfileimage || Object.keys(values.uploadfileimage).length !== 0 && values.uploadfileimage.constructor === Object) {
        errors.uploadfileimage = t('Field couldn\'t be empty');
    }

    return errors;
};

const form = reduxForm<IDoctor_1_Step, any>({
    form: 'RegistrationFormDoctor1Step',
    validate,
})(RegistrationFormDoctor1Step);

const selector = formValueSelector('RegistrationFormDoctor1Step');
const mapStateToProps = (state: RootState) => {
    const { type, sex, scientific_degree, language } = selector(state, 'type', 'sex', 'scientific_degree', 'language') || null;
    console.log('language', language);
    return {
        type, sex, scientific_degree,
        dictionary: state.dictionary,
        language: language,
    }
};

export default connect(mapStateToProps)(withTranslation()(form));