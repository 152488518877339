import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './header.scss';
import Dropdown from 'react-bootstrap/Dropdown'
import AuthComponentContainer from './../Auth/Auth';
import { IAuthState } from '../../states/auth-state';
import { language } from "../../actions/settings-actions";
import Logo from '../../images/RMD_svg@2x.png';
import JCI from '../../images/JCI@2x.png';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { WithTranslation, withTranslation } from 'react-i18next';

interface MyProps {
    auth: IAuthState;
    lang: string;
    language: (language: string) => void;
}

interface MyState { }

class HeaderComponent extends Component<MyProps & RouteComponentProps & WithTranslation, MyState> {

    constructor(props: MyProps & RouteComponentProps & WithTranslation) {
        super(props);
        this.state = {
        };
    }

    render() {

        if (this.props.location.pathname.indexOf('/home-new') === 0) {
            return null;
        }

        return (
            <div>
                <div className='header'>
                    <div className='header__wrapper'>
                        <div className='header__wrapper_logo-block'>
                            <Link to='/'>
                                <img className='header__wrapper_logo-block_logo' src={Logo} alt='logo' />
                            </Link>
                            <img className='header__wrapper_logo-block_jci' src={JCI} alt='jci' />
                        </div>
                        <div className='header__wrapper_login'>
                            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <AuthComponentContainer />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    lang: state.settings.language
});

const mapDispatchToProps = {
    language
};



let HeaderComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(withRouter(HeaderComponent))
);


export default HeaderComponentContainer;