import { Action, ActionCreator } from "redux";
import { ThunkAction } from "redux-thunk";
import * as environment from '../app.json';
import moment from "moment";
import { authFetch, signOut } from "./auth-actions";
import { RootState } from "../store";
import { DictionaryTypesEnum } from "../models/dictionary-types.enum";
import { getDictionaryList } from "./dictionary-actions";
import { getIndicatorsInfo } from "./indicator-actions";

export const FONT_SIZE = 'FONT_SIZE';
interface FontSizeAction {
    type: typeof FONT_SIZE;
    fontSize: number;
}

const _fontSize: ActionCreator<FontSizeAction> = (
    fontSize: number,
) => {
    return {
        type: FONT_SIZE,
        fontSize,
    };
};

export const fontSize = (fontSize: number | null): ThunkAction<void, {}, undefined, Action<string>> =>
    dispatch => {
        localStorage.setItem('az.ezgil.videodoctor.fontSize', fontSize ? fontSize.toString() : '13');
        console.log('fontSize: ', fontSize);
        dispatch(_fontSize(fontSize));
    };

export const LANGUAGE = 'LANGUAGE';
interface LanguageAction {
    type: typeof LANGUAGE;
    language: string;
}

const _language: ActionCreator<LanguageAction> = (
    language: string
) => {
    return {
        type: LANGUAGE,
        language
    }
}

export const language = (language: string): ThunkAction<void, RootState, undefined, Action<string>> =>
    async (dispatch, getState) => {

        if (!language) {
            return;
        }

        moment.locale(language);

        localStorage.setItem('az.ezgil.videodoctor.language', language);

        dispatch(_language(language));

        dispatch(getIndicatorsInfo(language));
        dispatch(getDictionaryList(DictionaryTypesEnum.BloodTypes, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.City, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.District, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.ClinicType, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.ContactType, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.SpecializationTypes, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.Analyzes, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.Services, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.InsuranceCompanies, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.Diseases, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.Languages, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.Procedures, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.Underground, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.Clinics, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.MedicationIntake, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.AcademicDegree, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.ArticleCategories, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.SocialNetworks, language));
        dispatch(getDictionaryList(DictionaryTypesEnum.AdsTypes, language));

        let tokens = getState().auth.userToken;

        if (!tokens || !tokens.auth) {
            tokens = JSON.parse(localStorage.getItem("az.ezgil.videodoctor.token") || '{}');

            if (!tokens || !tokens.auth) return;
        }

        const response = await authFetch(getState, dispatch, environment.auth + 'changeLanguage', 'POST', { language });
    };

export type SettingsActionType = FontSizeAction | LanguageAction;