import { ActionCreator, Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { loadingStart, loadingEnd } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
import i18n from 'i18next';
import * as environment from '../app.json';
import { pushAlert } from "./alert-actions";
import { authFetch } from "./auth-actions";
import { IPatientDoctorAccess } from "../models/user-doctor-access";
import { UserRole } from "../models/user-role.enum";
import { IPatientSharedProfile } from "../models/patient-shared-profile";
import { RootState } from "../store";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language === 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language === 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const PATIENT_SHARED_PROFILE = 'PATIENT_SHARED_PROFILE';
interface IPatientSharedProfileAction {
    type: typeof PATIENT_SHARED_PROFILE;
    sharedProfile: IPatientSharedProfile;
}

export const _patientSharedProfile: ActionCreator<IPatientSharedProfileAction> = (sharedProfile: IPatientSharedProfile) => {
    return {
        type: PATIENT_SHARED_PROFILE,
        sharedProfile
    };
};

export const PATIENT_DOCTOR_ACCESS_LIST = 'PATIENT_DOCTOR_ACCESS_LIST';
interface PatientDoctorAccessListAction {
    type: typeof PATIENT_DOCTOR_ACCESS_LIST;
    doctorAccess: IPatientDoctorAccess[];
}

export const _patientDoctorAccessList: ActionCreator<PatientDoctorAccessListAction> = (doctorAccess: IPatientDoctorAccess[]) => {
    return {
        type: PATIENT_DOCTOR_ACCESS_LIST,
        doctorAccess
    };
};

export const PATIENT_DOCTOR_ACCESS_ITEM = 'PATIENT_DOCTOR_ACCESS_ITEM';
interface PatientDoctorAccessItemAction {
    type: typeof PATIENT_DOCTOR_ACCESS_ITEM;
    doctorAccess: IPatientDoctorAccess | null;
}

export const _patientDoctorAccessItem: ActionCreator<PatientDoctorAccessItemAction> = (doctorAccess: IPatientDoctorAccess | null) => {
    return {
        type: PATIENT_DOCTOR_ACCESS_ITEM,
        doctorAccess
    };
};

export type PatientDoctorAccessActionTypes = PatientDoctorAccessListAction | PatientDoctorAccessItemAction | IPatientSharedProfileAction;

export const getPatientDoctorAccessList = (
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorAccessList));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + (getState().auth.userToken?.role === UserRole.Doctor ? 'doctorpatientpermissions' : 'patientdoctorpermissions') + '?per-page=1000', 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '&token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientDoctorAccessList(json));
        }
        else if (response.status !== 401) {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorAccessList));
    }
}

export const getPatientDoctorAccess = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorAccess));
    console.log('getPatientDoctorAccess', id);
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientdoctorpermissions/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientDoctorAccessItem(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorAccess));
    }
}

export const getPatientSharedProfile = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorAccess));
    console.log('getPatientSharedProfile', id);
    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'doctorpatientpermissions/' + id, 'GET',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_patientSharedProfile(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorAccess));
    }
}

export const createPatientDoctorAccess = (
    doctorAccess: IPatientDoctorAccess,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorAccess));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientdoctorpermissions', 'POST',
            doctorAccess, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientDoctorAccessList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorAccess));
    }
}


export const deletePatientDoctorAccess = (
    id: number,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorAccess));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientdoctorpermissions/' + id, 'DELETE',
            null, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientDoctorAccessList());
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            console.log('text', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorAccess));
    }
}


export const updatePatientDoctorAccess = (
    doctorAccess: IPatientDoctorAccess,
    success: () => void,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.PatientDoctorAccess));

    try {
        const response = await authFetch(
            getState, dispatch, topDoctorData() + 'patientdoctorpermissions/' + doctorAccess.id, 'PATCH',
            doctorAccess, {Accept: 'application/json', 'Content-Type': 'application/json'}, '?token='
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(getPatientDoctorAccessList());
            success && success();
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
            //dispatch(errorPatientDoctorList(json.code, token));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.PatientDoctorAccess));
    }
}