import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Ratings.scss';
import { TFunction, i18n } from 'i18next';
import { withTranslation } from 'react-i18next';
import Modal from 'react-bootstrap/Modal'
import StarRatings from "react-star-ratings";
import { IDoctor } from '../../models/doctor';
import { IAuthState } from "../../states/auth-state";
import { createDoctorRating } from "../../actions/rating-actions";
import { IDoctorRating } from '../../models/doctor-rating';
import { showAlert, showConfirm } from "../Dialogs";

interface MyProps {
    t: TFunction;
    i18n: i18n;
    doctor?: IDoctor;
    auth: IAuthState;
    createDoctorRating: (
        rating: IDoctorRating,
        id: number,
        token: string,
    ) => void;
}

interface MyState {
    full_rating: IDoctorRating,
    selectable: boolean;
    isOpen: boolean;
}

const initialRating = {
    waiting_time: 0,
    professionalism: 0,
    relevance_price: 0,
    diagnosis_clear: 0,
    mindfulness: 0,
    atmosphere: 0,
    quality_service: 0,
    total: 0,
    id: 0
}

class DoctorRatings extends Component<MyProps, MyState> {

    constructor(props: MyProps) {
        super(props);
        this.state = {
            full_rating: {
                id: 0,
                waiting_time: 0,
                professionalism: 0,
                relevance_price: 0,
                diagnosis_clear: 0,
                mindfulness: 0,
                atmosphere: 0,
                quality_service: 0,
                total: 0,
            },
            selectable: false,
            isOpen: false
        }
    }

    componentDidUpdate(prevProps: MyProps) {
        if(prevProps.doctor != this.props.doctor) {
            this.disableEditable();
        }
    }

    handleClose = () => {
        this.setState({ isOpen: false });
    }

    checkAllowSchedule = () => {
        if (!this.props.auth.userToken) {
            showAlert(this.props.t('Please Sing in for appointment!'));
        }
        return !!this.props.auth.userToken;
    }

    enableEditable = () => {
        this.setState({ selectable: true, full_rating: initialRating });
    }

    disableEditable = () => {
        const { doctor } = this.props;
        if (!doctor) {
            return;
        }
        this.setState({ selectable: false, full_rating: doctor.full_rating });
    }

    saveRatings = () => {
        if (this.props.doctor) {
            this.props.createDoctorRating(
                this.state.full_rating,
                this.props.doctor.id,
                this.props.auth.userToken!.auth
            );
        }
        this.disableEditable();
        this.handleClose();
    }

    getAmountReview = (doctor: IDoctor) => {
        if(!doctor) { return; }
        switch(this.props.i18n.language) {
            case 'az':
                return doctor?.full_rating?.count + " istifadəçinin fikirləri əsasında";
            case 'en':
                return "Based on opinions of " + doctor?.full_rating?.count + " users";
            case 'ru':
                return "Основано на мнении " + doctor?.full_rating?.count + " пользователей";
            default:
                return "";
        }
    }

    render() {
        const { t, doctor } = this.props;
        const { isOpen, full_rating, selectable } = this.state;
        const { waiting_time, professionalism, relevance_price, diagnosis_clear, mindfulness, atmosphere, quality_service, total } = full_rating || {};
        const amount_people = doctor ? this.getAmountReview(doctor) : 0;

        return ( 
            <>
                <div className='raiting' onClick={() => this.setState({ isOpen: true })}>
                    <StarRatings
                        isSelectable={false}
                        maxStars={5}
                        rating={doctor?.total_raiting}
                        starRatedColor="#FCE205"
                        starHoverColor="#FCE205"
                        numberOfStars={5}
                        starDimension="20px"
                        starSpacing="2px"
                        name='rating'
                    />
                    <p className='reviews-count'>{doctor?.full_rating?.count || 0}  {t('reviews')}</p>
                </div>
                <Modal centered show={isOpen} onHide={this.handleClose}>
                    <Modal.Header closeButton>{t('Doctor rating')}</Modal.Header>
                    <Modal.Body>
                        <div className="rating-doctor">{doctor?.surname + ' ' + doctor?.name + ' ' + doctor?.patronymic}</div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>1. {t('Waiting time')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, waiting_time: rate} }) : undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={waiting_time}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>5. {t('Diagnosis clear')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, diagnosis_clear: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={diagnosis_clear}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>2. {t('Professionalism')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, professionalism: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={professionalism}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>6. {t('Mindfulness')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, mindfulness: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={mindfulness}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>3. {t('Relevance price')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, relevance_price: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={relevance_price}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>7. {t('Atmosphere')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, atmosphere: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={atmosphere}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-row">
                            <div className="rating-element">
                                <span>4. {t('Quality service')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, quality_service: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={quality_service}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                            <div className="rating-element">
                                <span>8. {t('Total')}</span>
                                <StarRatings
                                    changeRating={selectable ? (rate) => this.setState({ full_rating: {...full_rating, total: rate} }): undefined}
                                    isSelectable={selectable}
                                    maxStars={5}
                                    rating={total}
                                    starRatedColor="#FCE205"
                                    starHoverColor="#FCE205"
                                    numberOfStars={5}
                                    starDimension="20px"
                                    starSpacing="2px"
                                    name='rating'
                                />
                            </div>
                        </div>
                        <div className="rating-review">
                            {!selectable ? amount_people: ""}
                        </div>
                        {!selectable && <div className='show-more-btn'
                            style={{
                                width: '150px',
                                fontSize: '13px',
                                margin: '0',
                                marginTop: '25px',
                                marginLeft: 'auto'
                            }}
                            onClick={() => {
                                if(this.checkAllowSchedule()) {
                                    this.enableEditable();
                                }
                            }}>
                            <p className='show-more-btn-title'>{t('Rate by yourself')}</p>
                        </div>}
                        {selectable && <div className='show-more-btn'
                            style={{
                                width: '150px',
                                fontSize: '13px',
                                margin: '0',
                                marginTop: '25px',
                                marginLeft: 'auto'
                            }}
                            onClick={() => {
                                this.saveRatings();
                            }}>
                            <p className='show-more-btn-title'>{t('Save')}</p>
                        </div>}

                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth
});

const mapDispatchToProps = {
    createDoctorRating
};

let DoctorRatingsContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(DoctorRatings)
);

export default DoctorRatingsContainer;