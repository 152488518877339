import { IDoctor } from "../models/doctor";
import { Action, ActionCreator } from "redux";
import { IUser } from "../models/user";
import { ThunkAction } from "redux-thunk";
import { loadingEnd, loadingStart } from "./loading-actions";
import { LoadingSubject } from "../states/loading-state";
// import AsyncStorage from "@react-native-community/async-storage";
import { pushAlert } from "./alert-actions";
import * as environment from '../app.json';
import { authFetch, processStageChange, signOut } from "./auth-actions";
import { IDoctorRating } from "../models/doctor-rating";
import { IDoctorFilter } from "../models/doctor-filter";
import i18n from 'i18next';
import { RootState } from "../store";
import { IDoctorPrice } from '../models/doctor-price';
import { IDoctorPriceFilter } from '../models/doctor-price-filter';
import { TFunction } from "i18next";
import { showConfirm } from "../components/Dialogs";

// import {_search} from "./content-actions";

function topDoctorData() {
    let topDoctor = environment.topDoctor;
    if (i18n.language == 'az') topDoctor = environment.topDoctorAz;
    if (i18n.language == 'ru') topDoctor = environment.topDoctorRu;
    return topDoctor;
}

export const DOCTORS_PAGE_SIZE = 8;

export const DOCTOR_LIST = 'DOCTOR_LIST';
interface DoctorListAction {
    type: typeof DOCTOR_LIST;
    doctors: IDoctor[];
    page: number;
    my: boolean;
}

export const _doctorList: ActionCreator<DoctorListAction> = (doctors: IDoctor[], page: number, my: boolean) => {
    return {
        type: DOCTOR_LIST,
        doctors,
        page,
        my
    };
};

export const DOCTOR_PRICE = 'DOCTOR_PRICE';
interface DoctorPriceAction {
    type: typeof DOCTOR_PRICE;
    doctorPrice: IDoctorPrice[];
}

const _doctorPrice: ActionCreator<DoctorPriceAction> = (doctorPrice: IDoctorPrice[]) => {
    return {
        type: DOCTOR_PRICE,
        doctorPrice
    };
};

export const DOCTOR_FULL_LIST = 'DOCTOR_FULL_LIST';
interface DoctorFullListAction {
    type: typeof DOCTOR_FULL_LIST;
    doctorFullList: IDoctor[];
}

const _doctorFullList: ActionCreator<DoctorFullListAction> = (doctorFullList: IDoctor[]) => {
    console.log(doctorFullList);
    return {
        type: DOCTOR_FULL_LIST,
        doctorFullList,
    };
};

export const DOCTOR_LIST_COUNT = 'DOCTOR_LIST_COUNT';
interface DoctorListCountAction {
    type: typeof DOCTOR_LIST_COUNT;
    totalCount: number;
}

export const _doctorListCount: ActionCreator<DoctorListCountAction> = (totalCount: number) => {
    return {
        type: DOCTOR_LIST_COUNT,
        totalCount
    };
};

export const DOCTOR = 'DOCTOR';
interface DoctorAction {
    type: typeof DOCTOR;
    doctor: IDoctor;
}

const _doctor: ActionCreator<DoctorAction> = (doctor: IDoctor) => {
    return {
        type: DOCTOR,
        doctor
    };
};

export const DOCTOR_RATING = 'DOCTOR_RATING';
interface DoctorRatingAction {
    type: typeof DOCTOR_RATING;
    doctorRating: IDoctorRating[];
}

const _doctorRating: ActionCreator<DoctorRatingAction> = (doctorRating: IDoctorRating[]) => {
    return {
        type: DOCTOR_RATING,
        doctorRating
    };
};

export const DOCTORS_AUTOCOMPLETE = 'DOCTORS_AUTOCOMPLETE';
interface DoctorsAutocompleteAction {
    type: typeof DOCTORS_AUTOCOMPLETE;
    doctorsAutocomplete: IDoctor[];
}

export const _doctorsAutocomplete: ActionCreator<DoctorsAutocompleteAction> = (doctorsAutocomplete: IDoctor[]) => {
    return {
        type: DOCTORS_AUTOCOMPLETE,
        doctorsAutocomplete
    };
};

export const DOCTOR_CACHED_DETAILS = 'DOCTOR_CACHED_DETAILS';
interface DoctorCachedDetailsAction {
    type: typeof DOCTOR_CACHED_DETAILS;
    doctor: IDoctor;
}

export const _doctorCachedDetails: ActionCreator<DoctorCachedDetailsAction> = (doctor: IDoctor) => {
    return {
        type: DOCTOR_CACHED_DETAILS,
        doctor
    };
};

export type DoctorActionTypes = DoctorAction | DoctorListAction | DoctorRatingAction | DoctorListCountAction | DoctorsAutocompleteAction | DoctorCachedDetailsAction | DoctorFullListAction | DoctorPriceAction;

export const getDoctorList = (
    filter: IDoctorFilter | undefined,
    page = 0,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(page === 0 ? LoadingSubject.DoctorList : LoadingSubject.DoctorListNextPage));

    try {
        console.log('external/private/doctor/list' + `?limit=${DOCTORS_PAGE_SIZE}&offset=${DOCTORS_PAGE_SIZE * page}`, filter)

        const access = !!getState().auth.userToken ? 'private' : 'public';

        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/' + access + '/doctor/list' + `?limit=${page < 0 ? 10000 : DOCTORS_PAGE_SIZE}&offset=${page < 0 ? 0 : DOCTORS_PAGE_SIZE * page}`,
            'POST', {filter, clinic: 20}, { Accept: 'application/json', 'Content-Type': 'application/json' });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_doctorList(json, page, filter?.myDoctors));
            page >= 0 && dispatch(getFullDoctorsCount(filter));
            // dispatch(_search(json, page));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            console.log('DoctorList error', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        console.log('DoctorList error', e);

        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(page === 0 ? LoadingSubject.DoctorList : LoadingSubject.DoctorListNextPage));
    }
}

export const getFullDoctorList = (clinicId:number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(_doctorFullList([]));
    const access = !!getState().auth.userToken ? 'private' : 'public';
    console.log(access);

    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/' + access + '/doctor/list',
            'POST', {'clinic': clinicId}, {Accept: 'application/json', 'Content-Type': 'application/json'});
            
        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
                console.log('json', json);
                return json;
        }
        else {
            console.log('DoctorList error', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        console.log('DoctorList error', e);

        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
           }
}

export const getDoctorListNoRedux = (filter: IDoctorFilter | undefined, page = 0., pageSize: number):
    ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
        try {
            const access = !!getState().auth.userToken ? 'private' : 'public';
            const response = await authFetch(getState, dispatch, environment.baseUrl +

                'external/' + access + '/doctor/list' +
                (pageSize ? `?limit=${pageSize}&offset=${pageSize * page}` : ''),
                'POST', {...filter, clinic: 20}, { Accept: 'application/json', 'Content-Type': 'application/json' });

            console.log('response', response);

            if (response.status === 200) {
                const json = await response.json();
                console.log('json', json);
                return json;
            }
        } catch (e) {
            console.log('DoctorList error', e);
        } finally {
        }
    }

export const getDoctorAutocomplete = (
    filter: IDoctorFilter | undefined
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(_doctorsAutocomplete([]));

    const access = !!getState().auth.userToken ? 'private' : 'public';
    console.log('auto filter', filter);
    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/' + access + '/doctor/list' + `?limit=20&offset=0`,
            'POST', {...filter, clinic: 20} || {}, { Accept: 'application/json', 'Content-Type': 'application/json' });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_doctorsAutocomplete(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            console.log('DoctorList error', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        console.log('DoctorList error', e);

        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
    }
}

export const getFullDoctorsCount = (
    filter: IDoctorFilter | undefined
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    const access = !!getState().auth.userToken ? 'private' : 'public';

    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/' + access + '/doctor/count',
            'POST', {...filter, clinic: 20} || {}, { Accept: 'application/json', 'Content-Type': 'application/json' });

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            console.log('json', json);
            dispatch(_doctorListCount(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            console.log('DoctorList error', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        // dispatch(loadingEnd(page === 0 ? LoadingSubject.DoctorList : LoadingSubject.DoctorListNextPage));
    }
}

export const getDoctor = (
    id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    // dispatch(loadingStart(LoadingSubject.Doctor));

    try {
        const response = await fetch(topDoctorData() + 'doctors/' + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_doctorCachedDetails(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorDoctor(json.code));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        // dispatch(loadingEnd(LoadingSubject.Doctor));
    }
}

export const getDoctorRating = (
    id: number
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DoctorRating));

    try {
        const response = await fetch(topDoctorData() + 'doctorratings?id=' + id, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        });

        console.log('response', response);

        const json = await response.json();
        console.log('json', json);
        if (response.status === 200) {
            dispatch(_doctorRating(json));
        }
        else if (response.status === 401) {
            //dispatch(signOut(token));
        }
        else {
            dispatch(errorDoctor(json.code));
        }
    } catch (e) {
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DoctorRating));
    }
}

export const askDutyPrice = (
    filter: IDoctorPriceFilter,
    t: TFunction,
): ThunkAction<Promise<boolean>, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.InitiateCall));
    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/public/doctor/price', 'POST', filter || {}
        );

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            const price = json as IDoctorPrice[];

            return await showConfirm(t('Are you sure you want call the duty doctor?'), { title: price.length ? t('Appointment price') + ': ' + price[0].duty + ' AZN' : '' });
        }
        else if (response.status === 401) {
            dispatch(signOut(getState().auth.userToken!.auth));
        }
        else {
            console.log('DoctorList error', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        console.log('DoctorList error', e);
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.InitiateCall));
    }

    return false;
}

export const getDoctorPrice = (
    filter: IDoctorPriceFilter,
): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    dispatch(loadingStart(LoadingSubject.DoctorPrice));

    try {
        const response = await authFetch(getState, dispatch, environment.baseUrl +
            'external/public/doctor/price', 'POST' ,filter || {});

        console.log('response', response);

        if (response.status === 200) {
            const json = await response.json();
            dispatch(_doctorPrice(json));
        }
        else if (response.status === 401) {
            dispatch(signOut(getState().auth.userToken!.auth));
        }
        else {
            console.log('DoctorList error', await response.text());
            dispatch(pushAlert('Unknown error. Please, check internet connection'));
        }
    } catch (e) {
        console.log('DoctorList error', e);
        
        dispatch(pushAlert('Unknown error. Please, check internet connection'));
    } finally {
        dispatch(loadingEnd(LoadingSubject.DoctorPrice));
    }
}

export const errorDoctorList = (code: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    dispatch((pushAlert('Error')));
}
export const errorDoctor = (code: number): ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch, getState) => {
    //console.warn('code', code);
    dispatch((pushAlert('Error')));
}
