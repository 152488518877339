import React, { Component } from 'react';
import { connect } from "react-redux";
import { submit, FormAction } from "redux-form";
import { RootState } from "../../store";
import './Auth.scss';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import LoginForm from '../../forms/Auth/LoginForm';
import VerifyAccountForm from './../../forms/Auth/VerifyAccountForm';
import RegistrationFormUser from './../../forms/Auth/RegistrationFormUser';
import { IUser } from './../../models/user';
import {
    signIn, signUp, authErrorText, verifyPhone, verifyEmail, processStageChange, newDoctor, newDoctor1, newDoctor2, _preSignIn,
    restorePassword, verifyOperation, restoreChangePassword, resendVerification, toggleAuth, fetchSelf
} from './../../actions/auth-actions';
import { IAuthState, LoginStage } from '../../states/auth-state';
import { IUserSignUpRequest } from "../../models/user-sign-up-request";
import { Link } from 'react-router-dom';
import history from "./../../history";
import RegistrationFormDoctor1Step from './../../forms/Auth/RegistrationFormDoctor1Step';
import RegistrationFormDoctor2Step from './../../forms/Auth/RegistrationFormDoctor2Step';
import { IDoctor_1_Step } from '../../models/doctor-1-step';
import { IDoctor_2_Step } from '../../models/doctor-2-step';
import ForgotForm from '../../forms/Forgot/Forgot-form';
import ForgotCodeForm from '../../forms/Forgot/Forgot-code-form';
import ForgotRepeatForm from '../../forms/Forgot/Forgot-repeat-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { language } from '../../actions/settings-actions';
import { VDButton } from '../Layouts/buttons'
import VDDropdown from '../Layouts/dropdowns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { signOut } from '../../actions/auth-actions';
import { SvgIcon } from '@material-ui/core';
import { ReactComponent as LogoutIcon } from '../../images/logout.svg';
import { ILoadingState, LoadingSubject } from '../../states/loading-state';
import Spinner from 'react-spinner-material';
import { IDoctor } from '../../models/doctor';
import { UserRole } from "../../models/user-role.enum";

interface MyProps {
    submit: (form: string) => FormAction;
    signIn: (user: IUser, onSuccess: any) => void;
    signUp: (user: IUserSignUpRequest, onSuccess: any) => void;
    _preSignIn: (userToken: any) => void;
    authErrorText: (text: string) => void;
    verifyPhone: (username: string, code: string) => void;
    verifyEmail: (username: string, code: string) => void;
    processStageChange: (processStage: number) => void;
    newDoctor: (user: IUserSignUpRequest, onSuccess: any, doctorStep: any) => void;
    newDoctor1: (user: IDoctor_1_Step, token: string, doctorStep: any) => void;
    newDoctor2: (user: IDoctor_2_Step, token: string, doctorFinal: any, doctorStep: any) => void;
    restorePassword: (emailOrLogin: string) => void;
    verifyOperation: (operation: string, code: string) => void;
    restoreChangePassword: (operation: string, user: IUser) => void;
    resendVerification: (emailOrUsername: string, subject: string, operation: string) => void;
    language: (language: string) => void;
    auth: IAuthState;
    loading: ILoadingState;
    signOut: (token: string) => void;
    toggleAuth: (b: boolean) => void;
    fetchSelf: (token: string, role: UserRole,) => void;
}

interface MyState {
    openLogin: boolean;
    openRegistration: boolean;
    asDoctor: boolean;
    doctorFinal: boolean;
    doctorStep: any;
}

class AuthComponent extends Component<MyProps & WithTranslation, MyState> {

    constructor(props: MyProps & WithTranslation) {
        super(props);
        this.state = {
            openLogin: false,
            openRegistration: false,
            asDoctor: false,
            doctorFinal: false,
            doctorStep: 1,
        };

        let language = (window.navigator as any)?.userLanguage?.substring(0, 2) || window.navigator?.language?.substring(0, 2);
        if (language !== 'az' && language !== 'en' && language !== 'ru') {
            language = 'az';
        }
        const currentLang = localStorage.getItem('az.ezgil.videodoctor.language') || language;
        this.setLanguage(currentLang);
    }

    setLanguage(lang: string) {
        this.props.i18n.changeLanguage(lang);
        this.props.language(lang);
        this.props.fetchSelf(this.props.auth.userToken?.auth || '', this.props.auth.userToken?.role || 3);
    }

    render() {
        const authToken = this.props.auth.userToken?.auth;
        const role = this.props.auth.userToken?.role;

        const { t } = this.props;

        const handleShow = () => this.props.toggleAuth(true);
        const handleClose = () => {
            this.props.authErrorText('');
            this.props.processStageChange(0);
            this.setState({
                openLogin: false,
                openRegistration: false,
                asDoctor: false,
                doctorFinal: false,
            });
            this.props.toggleAuth(false);
        };

        const onSuccess = () => {}

        const onSubmit = (user: any) => {
            const { openRegistration, asDoctor } = this.state;
            const { processStage, operation } = this.props.auth;

            this.props.authErrorText('');

            if (processStage === 0) {
                if (!openRegistration) {
                    this.props.signIn(user as IUser, onSuccess);
                }
                else {
                    if (asDoctor) {
                        this.props.newDoctor(user as IUserSignUpRequest, onSuccess, () => this.setState({ doctorStep: 1 }));
                    }
                    else {
                        this.props.signUp(user as IUserSignUpRequest, onSuccess);
                    }
                }
            }
            else if (processStage === LoginStage.PhoneVerification) {
                this.props.verifyPhone(this.props.auth.user?.login as string, user.code as string);
            }
            else if (processStage === LoginStage.EmailVerification) {
                this.props.verifyEmail(this.props.auth.user?.login as string, user.code as string);
            }
            else if (processStage === 4) {
                const isDoctor = this.props.auth.preUserToken?.auth && this.props.auth.preUserToken?.role === 2;

                if (isDoctor) {
                    let doctorStep = this.state.doctorStep;
                    if (this.props.auth.preUserToken?.status === 2) {
                        doctorStep = 2;
                    }

                    if (this.props.auth.preUserToken?.status === 1 && doctorStep === 1) {
                        this.props.newDoctor1(user, this.props.auth.preUserToken?.auth!, () => this.setState({ doctorStep: 2 }));
                    }
                    else if ((this.props.auth.preUserToken?.status === 2 || this.props.auth.preUserToken?.status === 1) && doctorStep === 2) {
                        this.props.newDoctor2(user, this.props.auth.preUserToken?.auth!, () => this.setState({ doctorFinal: true }), () => this.setState({ doctorStep: 3 }));
                    }
                }
            }
            else if (processStage === 5) {
                this.props.restorePassword(user.login);
            }
            else if (processStage === 6) {
                this.props.verifyOperation(operation || '', user.code);
            }
            else {
                user.login = this.props.auth.user?.login as string;
                user.email = this.props.auth.user?.email as string;
                this.props.restoreChangePassword(operation || '', user);
            }

        };

        const openProfile = () => {
            if (authToken && role === 3) {
                history.push('/personal-cabinet-patient');
            } else history.push('/personal-cabinet-doctor');
        }

        const renderError = () => {
            const errorText = this.props.auth.authErrorText;
            if (errorText) {
                return (
                    <div className='auth-error-wrapper'>
                        <img src={require('./../../images/error-img.png')} />
                        <p>{errorText}</p>
                    </div >
                )
            }
        };

        const renderWelcome = () => {
            const patientLoading = this.props.loading.subjects.has(LoadingSubject.SignUp);
            const medicalLoading = this.props.loading.subjects.has(LoadingSubject.NewDoctor);

            return (
                <Modal.Body className='register'>
                    <Modal.Title > {t('Register')} </Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        {this.props.auth.authErrorText && <div style={{ marginTop: '15px', marginBottom: '-15px' }}>{renderError()}</div>}
                        <RegistrationFormUser onSubmit={data => onSubmit(data)} />
                        <div className='modal-custom-footer'>
                            <p>{t('Register as')} </p>
                            <div className='modal-button-block'>

                                <button className='blue-btn btn btn-primary auth-button'
                                    onClick={() => {
                                        this.props._preSignIn(null);
                                        this.setState({ asDoctor: false });
                                        this.props.submit('RegistrationFormUser');
                                    }}
                                    disabled={patientLoading}
                                >
                                    <div style={{ display: 'flex' }}>
                                        {patientLoading &&
                                            <div style={{ marginRight: '8px' }}>
                                                {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                            </div>}
                                        {t('Patient')}
                                    </div>
                                </button>

                                <button className='blue-btn btn btn-primary dark auth-button'
                                    onClick={() => {
                                        this.props._preSignIn(null);
                                        this.setState({ asDoctor: true });
                                        this.props.submit('RegistrationFormUser');
                                    }}
                                    disabled={medicalLoading}
                                >
                                    <div style={{ display: 'flex' }}>
                                        {medicalLoading &&
                                            <div style={{ marginRight: '8px' }}>
                                                {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                            </div>}
                                        {t('Medical worker')}
                                    </div>
                                </button>

                            </div>
                        </div>
                    </div>
                </Modal.Body >
            )
        }

        const renderVerify = (stage: LoginStage) => {
            const verifyLoading = this.props.loading.subjects.has(LoadingSubject.VerifyPhone);

            const { operation } = this.props.auth;
            return (
                <Modal.Body >
                    <Modal.Title style={{ textAlign: 'left', marginBottom: '20px' }} >
                        {t(LoginStage.PhoneVerification ? 'Phone confirmation' : 'Email confirmation')}
                    </Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        {renderError()}
                        <VerifyAccountForm onSubmit={data => onSubmit(data)} />
                        <Link to={''} className='modal-link'
                            onClick={() => this.props.resendVerification(this.props.auth.user?.login as string, stage === LoginStage.PhoneVerification ? 'Phone' : 'Email', operation as string)}
                        >
                            {t('Send code again')}
                        </Link>
                        <div className='modal-button-block'>
                            <button className='blue-btn btn btn-primary auth-button'
                                onClick={() => {
                                    this.props.submit('VerifyAccountForm');
                                }}
                                disabled={verifyLoading}
                            >
                                <div style={{ display: 'flex' }}>
                                    {verifyLoading &&
                                        <div style={{ marginRight: '8px' }}>
                                            {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                        </div>}
                                    {t('Confirm')}
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal.Body >
            )
        }

        const renderDoctor1Step = () => {
            const NewDoctor1Loading = this.props.loading.subjects.has(LoadingSubject.NewDoctor1);

            return (
                <Modal.Body className='step1'>
                    <Modal.Title>{t('Step 1')}</Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        {renderError()}
                        <RegistrationFormDoctor1Step onSubmit={data => onSubmit(data)} />
                        <div className='modal-button-block'>
                            <button className='blue-btn btn btn-primary auth-button'
                                onClick={() => {
                                    this.props.submit('RegistrationFormDoctor1Step');
                                }}
                                disabled={NewDoctor1Loading}
                            >
                                <div style={{ display: 'flex' }}>
                                    {NewDoctor1Loading &&
                                        <div style={{ marginRight: '8px' }}>
                                            {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                        </div>}
                                    {t('Next')}
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            )
        }

        const renderDoctor2Step = () => {
            const NewDoctor2Loading = this.props.loading.subjects.has(LoadingSubject.NewDoctor2);

            return (
                <Modal.Body className='step1'>
                    <Modal.Title>{t('Step 2')}</Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        {renderError()}
                        <RegistrationFormDoctor2Step onSubmit={data => onSubmit(data)} />
                        <div className='modal-button-block'>
                            <button className='blue-btn btn btn-primary auth-button'
                                onClick={() => {
                                    this.props.submit('RegistrationFormDoctor2Step');
                                }}
                                disabled={NewDoctor2Loading}
                            >
                                <div style={{ display: 'flex' }}>
                                    {NewDoctor2Loading &&
                                        <div style={{ marginRight: '8px' }}>
                                            {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                        </div>}
                                    {t('Finish')}
                                </div>
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            )
        }

        const renderWaitAdminConfirmation = () => {
            return (
                <Modal.Body>
                    <Modal.Title style={{ textAlign: 'left', marginBottom: '20px' }} ></Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        <div className='auth-error-wrapper' style={{ borderColor: 'green' }}>
                            <p style={{ color: 'green', marginBottom: '10px', textAlign: 'center' }}>
                                {t('Thank you for registration')}
                            </p>
                        </div >
                    </div>
                </Modal.Body>
            )
        }

        const renderLogin = () => {
            const loginLoading = this.props.loading.subjects.has(LoadingSubject.SignIn);

            return (
                <Modal.Body className='login'>
                    <div className='modal-close'><FontAwesomeIcon icon={faTimes} onClick={handleClose} /></div>
                    <Modal.Title>{t('Authorization')}</Modal.Title>
                    {renderError()}
                    <LoginForm onSubmit={data => onSubmit(data)} />
                    <Link className='modal-link' to={''} onClick={() => this.props.processStageChange(5)}>{t('Forgot your password?')}</Link>
                    <div className='modal-button-block'>
                        <button className='blue-btn btn btn-primary auth-button'
                            onClick={() => this.props.submit('LoginForm')}
                            disabled={loginLoading}
                        >
                            <div style={{ display: 'flex' }}>
                                {loginLoading &&
                                    <div style={{ marginRight: '8px' }}>
                                        {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                    </div>}
                                {t('Sign in')}
                            </div>
                        </button>
                        <button className='blue-btn btn btn-primary dark auth-button'
                            onClick={() => {
                                this.props._preSignIn(null);
                                this.props.authErrorText('');
                                this.setState({ openRegistration: true });
                            }}>{t('Register')}
                        </button>
                    </div>
                </Modal.Body>
            )
        }

        const renderForgotPswd = () => {
            const restoreLoading = this.props.loading.subjects.has(LoadingSubject.RestorePswd);

            return (
                <Modal.Body>
                    <Modal.Title style={{ textAlign: 'left', marginBottom: '20px' }} >{t('Forgot your password?')}</Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        <p>{t("Login or Email or Phone")}</p>
                        {renderError()}
                        <ForgotForm onSubmit={data => onSubmit(data)} />
                        <Button size='sm' className='btn-modal-window'
                            style={{ display: 'table', margin: '20px auto 0 auto' }}
                            variant="primary"
                            onClick={() => {
                                this.props.submit('ForgotForm');
                            }}
                            disabled={restoreLoading}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {restoreLoading &&
                                    <div style={{ marginRight: '8px' }}>
                                        {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                    </div>}
                                {t('Restore')}
                            </div>
                        </Button>
                    </div>
                </Modal.Body>
            )
        }

        const renderVerifyEmail = () => {
            const { operation, hasEmail } = this.props.auth;
            const verificationLoading = this.props.loading.subjects.has(LoadingSubject.VerifyEmail);

            return (
                <Modal.Body>
                    <Modal.Title style={{ textAlign: 'left', marginBottom: '20px' }} >{t('Forgot your password?')}</Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        <p>{hasEmail ? t("restore_by_mail") : t("restore_by_phone")}</p>
                        {renderError()}
                        <ForgotCodeForm onSubmit={data => onSubmit(data)} />
                        <Button size='sm' className='btn-modal-window'
                            style={{ display: 'table', margin: '20px auto 0 auto' }}
                            variant="primary"
                            onClick={() => {
                                this.props.submit('ForgotCodeForm');
                            }}
                            disabled={verificationLoading}
                        >
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {verificationLoading &&
                                    <div style={{ marginRight: '8px' }}>
                                        {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                    </div>}
                                {t('Verification')}
                            </div>
                        </Button>
                        <Link to={''} onClick={() => this.props.resendVerification(this.props.auth.user?.login as string, 'OperationByEmail', operation as string)}>{t('Send code again')}</Link>
                    </div>
                </Modal.Body>
            )
        }

        const renderChangePswd = () => {
            const changePswdLoading = this.props.loading.subjects.has(LoadingSubject.ChangePswd);

            return (
                <Modal.Body>
                    <Modal.Title style={{ textAlign: 'left', marginBottom: '20px' }} >{t('Forgot your password?')}</Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        <p>{t('Login or email')}</p>
                        {renderError()}
                        <ForgotRepeatForm onSubmit={data => onSubmit(data)} />
                        <Button size='sm' className='btn-modal-window'
                            style={{ display: 'table', margin: '20px auto 0 auto' }}
                            variant="primary"
                            onClick={() => {
                                this.props.submit('ForgotRepeatForm');
                            }}
                            disabled={changePswdLoading}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                {changePswdLoading &&
                                    <div style={{ marginRight: '8px' }}>
                                        {<Spinner radius={18} color={"#ffffff"} stroke={3} visible={true} />}
                                    </div>}
                                {t('Change')}
                            </div>
                        </Button>
                    </div>
                </Modal.Body>
            )
        }

        const renderPswdChanged = () => {
            return (
                <Modal.Body>
                    <Modal.Title style={{ textAlign: 'left', marginBottom: '20px' }} >{this.props.t('Forgot your password?')}</Modal.Title>
                    <div className='doctor-or-patient-buttons-block'>
                        <p>{t('Password successfully changed. Please, login with new password')}</p>
                        <Button size='sm' className='btn-modal-window'
                            style={{ display: 'table', margin: '20px auto 0 auto' }}
                            variant="primary"
                            onClick={() => {
                                this.props.processStageChange(0);
                            }} > {t('Close')}
                        </Button>
                    </div>
                </Modal.Body>
            )
        }

        const whichFormToRender = () => {
            const { openRegistration } = this.state;
            const { processStage } = this.props.auth;

            let doctorFinal = this.state.doctorFinal;
            if (this.props.auth.userToken?.status === 3) {
                doctorFinal = true;
            }

            if (processStage === 0) {
                if (openRegistration) {
                    return renderWelcome();
                }
                else {
                    return renderLogin();
                }
            }
            else if (processStage === LoginStage.PhoneVerification || processStage === LoginStage.EmailVerification) {
                return renderVerify(processStage);
            }
            else if (processStage === LoginStage.VerifyPhoneSuccess || processStage === LoginStage.VerifyEmailSuccess) {
                const isDoctor = this.props.auth.preUserToken?.auth && this.props.auth.preUserToken?.role === 2;

                doctorFinal = this.state.doctorFinal;
                if (this.props.auth.preUserToken?.status === 3) {
                    doctorFinal = true;
                }

                if (isDoctor) {
                    if (this.props.auth.preUserToken?.status === 1 && this.state.doctorStep === 1) {
                        return renderDoctor1Step();
                    }
                    else if ((this.props.auth.preUserToken?.status === 2 && !doctorFinal) || (this.state.doctorStep === 2)) {
                        return renderDoctor2Step();
                    }
                    else if ((this.props.auth.preUserToken?.status === 3 || doctorFinal) || (this.props.auth.preUserToken?.status === 2)) {
                        return renderWaitAdminConfirmation();
                    }
                    else if (this.props.auth.preUserToken?.status === 4) {
                        history.push('/personal-cabinet-doctor');
                        handleClose();
                    }
                }
                else {
                    if (this.props.auth.userToken?.auth) {
                        if (this.props.auth.userToken?.role === 2) {
                            history.push('/personal-cabinet-doctor');
                        }
                        else if (this.props.auth.userToken?.role === 3) {
                            history.push('/personal-cabinet-patient');
                        }
                        else if (this.props.auth.userToken?.role === 1) {
                            history.push('/personal-cabinet-clinic');
                        }

                        handleClose();
                    }
                    else {
                        this.props.processStageChange(0);
                        this.setState({ openRegistration: false });
                    }
                }
            }
            else if (processStage === 5) {
                return renderForgotPswd();
            }
            else if (processStage === 6) {
                return renderVerifyEmail();
            }
            else if (processStage === 7) {
                return renderChangePswd();
            } else if (processStage === 8) {
                return renderPswdChanged();
            } else {
                return null;
            }
        }

        const currLang = (langLetter: string) => {
            if (langLetter === this.props.i18n.language) {
                return 'current-lang';
            } else return '';
        }

        return (
            <div className='auth-wrapper' >
                <div className='auth-wrapper__lang'>
                    <span className={'language ' + currLang('az')} onClick={() => this.props.i18n.changeLanguage('az')}>Az</span>
                    <span className={'language ' + currLang('ru')} onClick={() => this.props.i18n.changeLanguage('ru')}>Ru</span>
                    <span className={'language ' + currLang('en')} onClick={() => this.props.i18n.changeLanguage('en')}>En</span>
                </div>
                <div className='auth-wrapper__login'>
                    {!authToken ? <>
                        <button className='btn btn-light' onClick={() => handleShow()}>{t("Sign in")}</button>
                    </> : <>
                        <button className='btn btn-light' onClick={() => openProfile()}>{t("Profile")}</button>
                    </>}
                </div>
                {authToken && 
                <div className='auth-wrapper__login'>
                    <button className='btn btn-light' onClick={() => {this.props.signOut(this.props.auth.userToken!.auth); }}>
                        {!this.props.loading.subjects.has(LoadingSubject.SignOut) && <SvgIcon htmlColor='$main-dark-color' fontSize='small' component={LogoutIcon}/>}
                        {this.props.loading.subjects.has(LoadingSubject.SignOut) && <Spinner radius={20} color={"$main-dark-color"} stroke={2} visible={true} />}
                    </button>
                </div>
                }
                <Modal dialogClassName="modal-main" centered show={this.props.auth.showAuth} onHide={handleClose}>
                    {whichFormToRender()}
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    loading: state.loading,
});

const mapDispatchToProps = {
    submit,
    signIn,
    signOut,
    signUp,
    authErrorText,
    verifyPhone,
    verifyEmail,
    processStageChange,
    newDoctor,
    newDoctor1,
    newDoctor2,
    _preSignIn,
    restorePassword,
    verifyOperation,
    restoreChangePassword,
    resendVerification,
    language,
    toggleAuth,
    fetchSelf,
};

let AuthComponentContainer = connect(mapStateToProps, mapDispatchToProps)(withTranslation()(AuthComponent));

export default AuthComponentContainer;