import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { confirmable } from 'react-confirm';
import './Dialogs.scss';
import { TFunction } from "i18next";
import { withTranslation } from 'react-i18next';

export interface ConfirmationProps {
  okLabel?: string;
  cancelLabel?: string;
  title?: string;
  enableEscape?: boolean;
  warning?: string;
}

class ConfirmationDialog extends React.Component<ConfirmationProps & { t: TFunction; confirmation: string; show: boolean; proceed: (a: boolean) => void; }> {
  render() {
    const {
      okLabel = this.props.t('Yes'),
      cancelLabel = this.props.t('No'),
      title,
      warning,
      confirmation,
      show,
      proceed,
      enableEscape = true,
      t,
    } = this.props;

    return (
      <div className="static-modal">
        <Modal style={{ marginTop: '170px' }} show={show} onHide={() => proceed(false)} backdrop={enableEscape ? true : 'static'} keyboard={enableEscape}>
          <div className='dialogs-block'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
              {t(confirmation)}
              {title && <p>{t(title)}</p>}
              {warning && <p className='dialog-warning'>{t(warning)}</p>}
            </Modal.Body>
            <Modal.Footer>
              <Button className='blue-btn btn btn-primary' onClick={() => proceed(false)}>{cancelLabel}</Button>
              <Button className='blue-btn btn btn-primary' onClick={() => proceed(true)}>{okLabel}</Button>
            </Modal.Footer>
          </div>
        </Modal>
      </div>
    )
  }
}

export default withTranslation()(confirmable(ConfirmationDialog));