import React from "react";
import { DELETE_PROFILE_VALUES, ProfileActionsTypes, PROFILE_VALUES } from "../actions/profile-actions";
import { IProfileState } from "../states/profile-state";
import DescriptionIcon from '@material-ui/icons/Description';
import { ProfileValueType } from "../models/profile-value-type";
import ContactsIcon from '@material-ui/icons/Contacts';
import HealingIcon from '@material-ui/icons/Healing';
import LinkIcon from '@material-ui/icons/Link';
import PaymentIcon from '@material-ui/icons/Payment';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FacebookIcon from '@material-ui/icons/Facebook';
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects';
import BusinessIcon from '@material-ui/icons/Business';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import InsuranceIcon from '@material-ui/icons/Security';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { SIGN_IN } from "../actions/auth-actions";
import { UserRole } from "../models/user-role.enum";

const sectionsDoctor = [
    {
        name: 'doctorarticles',
        title: 'Articles',
        icon: <DescriptionIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'category',
                title: 'Category',
                type: ProfileValueType.dictionary,
                multilanguage: false,
                dictionary: 'articleCategories',
                table: true,
                required: true,
            },
            {
                name: 'title',
                title: 'Title',
                type: ProfileValueType.string,
                multilanguage: true,
                table: true,
                required: true,
            },
            {
                name: 'text',
                title: 'Content',
                type: ProfileValueType.html,
                multilanguage: true,
                table: false,
            },
            {
                name: 'date',
                title: 'Date',
                type: ProfileValueType.date,
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'image',
                title: 'Image',
                type: ProfileValueType.file,
                limit: 2048000,
                multilanguage: false,
                table: false,
            },
            {
                name: 'publish',
                title: 'Published',
                type: ProfileValueType.boolean,
                multilanguage: false,
                table: true,
            },
        ]
    },
    {
        name: 'doctorcontacts',
        title: 'Contacts',
        icon: <ContactsIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'type',
                title: 'Type',
                type: ProfileValueType.dictionary,
                dictionary: 'contactType',
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'value',
                title: 'Value',
                type: ProfileValueType.string,
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'doctordiseases',
        title: 'Disease',
        addOnly: true,
        icon: <ReportProblemIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'disease',
                title: 'Disease',
                type: ProfileValueType.dictionary,
                dictionary: 'diseases',
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'doctormaterials',
        title: 'Materials',
        icon: <LinkIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'title',
                title: 'Title',
                type: ProfileValueType.string,
                multilanguage: true,
                table: true,
                required: true,
            },
            {
                name: 'description',
                title: 'Description',
                type: ProfileValueType.html,
                multilanguage: true,
                table: false,
            },
            {
                name: 'link',
                title: 'Link',
                type: ProfileValueType.string,
                multilanguage: false,
                table: true,
            },
        ]
    },
    {
        name: 'doctorprices',
        title: 'Prices',
        icon: <PaymentIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'clinic_id',
                title: 'Clinic',
                type: ProfileValueType.dictionary,
                dictionary: 'clinics',
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'first_cons_sum',
                title: 'First Offline Consultation',
                type: ProfileValueType.decimal,
                multilanguage: false,
                table: true,
            },
            {
                name: 'second_cons_sum',
                title: 'Second Offline Consultation',
                type: ProfileValueType.decimal,
                multilanguage: false,
                table: true,
            },
            {
                name: 'first_price',
                title: 'First Online Consultation',
                type: ProfileValueType.decimal,
                multilanguage: false,
                table: true,
            },
            {
                name: 'second_price',
                title: 'Second Online Consultation',
                type: ProfileValueType.decimal,
                multilanguage: false,
                table: true,
            },
            {
                name: 'duty_price',
                title: 'Duty Consultation',
                type: ProfileValueType.decimal,
                multilanguage: false,
                table: true,
            },
        ]
    },
    {
        name: 'doctorprocedures',
        title: 'Procedures',
        addOnly: true,
        icon: <HealingIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'procedure',
                title: 'Procedure',
                type: ProfileValueType.dictionary,
                dictionary: 'procedures',
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'doctorpromotions',
        title: 'Promotions',
        icon: <LoyaltyIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'type',
                title: 'Type',
                type: ProfileValueType.dictionary,
                dictionary: 'adsTypes',
                multilanguage: true,
                table: true,
                required: true,
            },
            {
                name: 'title',
                title: 'Title',
                type: ProfileValueType.string,
                multilanguage: true,
                table: true,
                required: true,
            },
            {
                name: 'description',
                title: 'Description',
                type: ProfileValueType.html,
                multilanguage: true,
                table: false,
            },
            {
                name: 'startdate',
                title: 'Start Date',
                type: ProfileValueType.date,
                multilanguage: false,
                table: true,
            },
            {
                name: 'enddate',
                title: 'End Date',
                type: ProfileValueType.date,
                multilanguage: false,
                table: true,
            },
            {
                name: 'imageFile',
                title: 'Image',
                type: ProfileValueType.file,
                limit: 2048000,
                multilanguage: false,
                table: false,
            },
        ]
    },
    {
        name: 'doctorsocials',
        title: 'Social accounts',
        icon: <FacebookIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'social_type',
                title: 'Type',
                type: ProfileValueType.dictionary,
                dictionary: 'socialNetworks',
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'value',
                title: 'Value',
                type: ProfileValueType.string,
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'doctorspecializations',
        title: 'Specializations',
        addOnly: true,
        icon: <EmojiObjectsIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'specialization',
                title: 'Specialization',
                type: ProfileValueType.dictionary,
                dictionary: 'specializationTypes',
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'doctorworks',
        title: 'Working places',
        icon: <BusinessIcon htmlColor="#0279BD" />,
        values: [
            {
                name: 'clinic_id',
                title: 'Clinic',
                type: ProfileValueType.dictionary,
                dictionary: 'clinics',
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'working_time',
                title: 'Working Time',
                type: ProfileValueType.string,
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'home_visit',
                title: 'Home Visit',
                type: ProfileValueType.boolean,
                multilanguage: false,
                table: false,
            },
            {
                name: 'children_only',
                title: 'Children Only',
                type: ProfileValueType.boolean,
                multilanguage: false,
                table: false,
            },
            {
                name: 'status',
                title: 'Status',
                type: ProfileValueType.boolean,
                multilanguage: false,
                table: false,
            },
        ]
    },
    {
        name: 'users/updateprofile',
        title: 'About me',
        icon: <AccountBoxIcon htmlColor="#0279BD" />,
        singleLoad: 'users/profile',
        values: [{
            name: 'bio',
            title: 'Biography',
            type: ProfileValueType.html,
            multilanguage: true,
            table: false,
        }],
    }
];

const sectionsClinic = [
    {
        name: 'clinicinsurances',
        title: 'Insurances',
        icon: <InsuranceIcon htmlColor="#0279BD" />,
        addOnly: true,
        values: [
            // {
            //     name: 'clinic_id',
            //     title: 'Clinic',
            //     type: ProfileValueType.dictionary,
            //     dictionary: 'clinics',
            //     multilanguage: false,
            //     table: true,
            //     required: true,
            // },
            {
                name: 'insurance',
                title: 'Insurance',
                type: ProfileValueType.dictionary,
                dictionary: 'insuranceCompanies',
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'clinicnotices',
        title: 'Notices',
        icon: <NotificationsActiveIcon htmlColor="#0279BD" />,
        values: [
            // {
            //     name: 'clinic_id',
            //     title: 'Clinic',
            //     type: ProfileValueType.dictionary,
            //     dictionary: 'clinics',
            //     multilanguage: false,
            //     table: true,
            //     required: true,
            // },
            {
                name: 'type',
                title: 'Type',
                type: ProfileValueType.dictionary,
                dictionary: 'noticeTypes',
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'title',
                title: 'Title',
                type: ProfileValueType.string,
                multilanguage: true,
                table: true,
                required: true,
            },
            {
                name: 'description',
                title: 'Description',
                type: ProfileValueType.html,
                multilanguage: true,
                table: false,
            },
            {
                name: 'imageFile',
                title: 'Image',
                type: ProfileValueType.file,
                limit: 2048000,
                multilanguage: false,
                table: false,
            },
        ]
    },
    {
        name: 'clinicprocedures',
        title: 'Procedures',
        icon: <HealingIcon htmlColor="#0279BD" />,
        addOnly: true,
        values: [
            // {
            //     name: 'clinic_id',
            //     title: 'Clinic',
            //     type: ProfileValueType.dictionary,
            //     dictionary: 'clinics',
            //     multilanguage: false,
            //     table: true,
            //     required: true,
            // },
            {
                name: 'procedure',
                title: 'Procedure',
                type: ProfileValueType.dictionary,
                dictionary: 'procedures',
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'clinicsocials',
        title: 'Social accounts',
        icon: <FacebookIcon htmlColor="#0279BD" />,
        values: [
            // {
            //     name: 'clinic_id',
            //     title: 'Clinic',
            //     type: ProfileValueType.dictionary,
            //     dictionary: 'clinics',
            //     multilanguage: false,
            //     table: true,
            //     required: true,
            // },
            {
                name: 'social_type',
                title: 'Type',
                type: ProfileValueType.dictionary,
                dictionary: 'socialNetworks',
                multilanguage: false,
                table: true,
                required: true,
            },
            {
                name: 'value',
                title: 'Value',
                type: ProfileValueType.string,
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'clinicspecializations',
        title: 'Specializations',
        icon: <EmojiObjectsIcon htmlColor="#0279BD" />,
        addOnly: true,
        values: [
            // {
            //     name: 'clinic_id',
            //     title: 'Clinic',
            //     type: ProfileValueType.dictionary,
            //     dictionary: 'clinics',
            //     multilanguage: false,
            //     table: true,
            //     required: true,
            // },
            {
                name: 'specialization',
                title: 'Specialization',
                type: ProfileValueType.dictionary,
                dictionary: 'specializationTypes',
                multilanguage: false,
                table: true,
                required: true,
            },
        ]
    },
    {
        name: 'clinics',
        title: 'About me',
        icon: <AccountBoxIcon htmlColor="#0279BD" />,
        singleLoad: 'clinics/ownview',
        values: [{
                name: 'name',
                title: 'Name',
                type: ProfileValueType.string,
                multilanguage: true,
                table: false,
            },{
                name: 'hospital',
                title: 'Hospital',
                type: ProfileValueType.boolean,
                multilanguage: true,
                table: false,
            },{
                name: 'year_o',
                title: 'Year',
                type: ProfileValueType.string,
                multilanguage: false,
                table: false,
            },{
                name: 'city',
                title: 'City',
                type: ProfileValueType.dictionary,
                dictionary: 'city',
                multilanguage: false,
                table: true,
                required: false,
            },{
                name: 'region',
                title: 'District',
                type: ProfileValueType.dictionary,
                dictionary: 'district',
                multilanguage: false,
                table: true,
                required: false,
            },{
                name: 'address',
                title: 'Address',
                type: ProfileValueType.string,
                multilanguage: true,
                table: false,
            },{
                name: 'type',
                title: 'Type',
                type: ProfileValueType.dictionary,
                dictionary: 'district',
                multilanguage: false,
                table: true,
                required: true,
            },{
                name: 'metro',
                title: 'Metro',
                type: ProfileValueType.dictionary,
                dictionary: 'metro',
                multilanguage: false,
                table: true,
                required: false,
            },{
                name: 'description',
                title: 'Description',
                type: ProfileValueType.html,
                multilanguage: true,
                table: false,
            },
            {
                name: 'imageFile',
                title: 'Image',
                type: ProfileValueType.file,
                limit: 2048000,
                multilanguage: false,
                table: false,
            },{
                name: 'workinghours',
                title: 'Working Hours',
                type: ProfileValueType.html,
                multilanguage: true,
                table: false,
            },{
                name: 'additional_information',
                title: 'Additional information',
                type: ProfileValueType.html,
                multilanguage: true,
                table: false,
            },
        ],
    },
];

const tokenString = localStorage.getItem("az.ezgil.videodoctor.token");
const role = tokenString ? JSON.parse(tokenString).role : UserRole.Doctor;
const sections = role === UserRole.Clinic ? sectionsClinic : sectionsDoctor;

const initialState: IProfileState = {
    sections: sections,
    values: [],
    page: 0,
    hasMore: true,
    section: sections[0],
}

export function profileReducer(
    state = initialState,
    action: ProfileActionsTypes
): IProfileState {
    switch (action.type) {
        case SIGN_IN:
            const role = action.userToken?.role;
            const sections = role === UserRole.Clinic ? sectionsClinic : sectionsDoctor;
            return { ...state, sections, section: sections[0] };
        case PROFILE_VALUES:
            return { ...state, ...{ section: action.section, values: action.values, page: action.page, hasMore: action.hasMore } };
        case DELETE_PROFILE_VALUES:
            const values = state.values.filter(v => !action.values.find(j => j["id"] === v["id"]));
            return { ...state, values };
        default:
            return state;
    }
}
