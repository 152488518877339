import React from 'react';
import { connect } from 'react-redux';
import { Field, InjectedFormProps, reduxForm, formValueSelector, FormErrors, submit, reset, FormAction, FormSubmitHandler, SubmitHandler, ConfigProps } from 'redux-form';
import { WithTranslation, withTranslation } from 'react-i18next';
import { IIndicatorValue } from '../../../models/indicator-value';
import { ILoadingState } from '../../../states/loading-state';
import { IAuthState } from '../../../states/auth-state';
import { IDictionaryState } from '../../../states/dictionary-state';
import { IIndicatorsState } from '../../../states/indicators-state';
import { RootState } from '../../../store';
import { IIndicatorLine } from '../../../models/indicator-line';
import TextInput from '../../../forms/FormComponents/TextInput';
import moment from 'moment';

type Props = {
    selectedValues?: IIndicatorValue[] | null;
    indicatorLines: IIndicatorLine[];
    computes?: {[key:string]: string};
}

class IndicatorForm extends React.Component<InjectedFormProps<{[key: string]: string}, WithTranslation & Props, string> & WithTranslation & Props> {

    constructor(props: InjectedFormProps<{[key: string]: string}, WithTranslation & Props, string> & WithTranslation & Props) {
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
        const values = this.props.selectedValues;
        console.log('this.props.values', values);
        if (values) {
            const data = {dateTime: moment(values[0].dateTime).format("YYYY-MM-DDTHH:mm")} as any;
            for (const v of values) {
                data['v' + v.indicatorLineCode] = v.value;
            }
            console.log('valuesdata', data);
            this.props.initialize(data);
        } else {
            this.props.initialize({dateTime: moment().format("YYYY-MM-DDTHH:mm") as any});
        }
    }

    componentDidUpdate(oldProps: Props) {
        if (this.props.computes && oldProps.computes && this.props.computes['sum'] !== oldProps.computes['sum']) {
            for (const l of this.props.indicatorLines.filter(l => l.formula)) {
                this.props.change('v' + l.code, '' + this.props.computes['v' + l.code])
            }
        }
    }

    render() {
        const { t } = this.props;
        return (
            <div style={{ padding: '0px 0 30px 0' }} className='indicators-form'>
                {this.props.indicatorLines.filter(l => !l.formula).map(l => <Field 
                    name={'v' + l.code} label={l.name} placeholder = {l.unit}
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        height: '24px',
                        paddingLeft: '5px',
                    }}
                    type={l.decimal ? 'decimal' : 'integer'}
                    key={l.id}
                    component={TextInput} />
                )}
                {this.props.indicatorLines.filter(l => l.formula).map(l => <Field
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    readOnly={true}
                    key={l.id} label={l.name} placeholder = {l.unit}
                    name={'v' + l.code} component={TextInput} />
                )}
                <Field
                    name="dateTime"
                    type='datetime-local'
                    style={{
                        width: '70%',
                        marginBottom: '10px',
                        paddingLeft: '5px',
                    }}
                    placeholder={t('Date and Time')}
                    component={TextInput}
                />
            </div>
        );
    }
}

const validate = (values: {[key: string]: string}, props: Partial<ConfigProps<{[key: string]: string}, WithTranslation & Props, string>> & WithTranslation & Props): FormErrors<any> => {
    const errors: FormErrors<any> = {};
    
    const {t} = props;
    for (const l of props.indicatorLines) {
        const value = values['v' + l.code];
        if (!value || value.toString().trim() === '') {
            errors['v' + l.code] = t("Field couldn't be empty");
        }
    }
    return errors;
};

const form = reduxForm<{[key: string]: string}, WithTranslation & Props, string>({
    form: 'IndicatorForm',
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    validate,
})(IndicatorForm);

const selector = formValueSelector('IndicatorForm');
const mapStateToProps = (state: RootState, ownProps: Props) => {
    const computes: {[key:string]: string} = {};
    const values = ownProps.indicatorLines.filter(l => !l.formula).map(l => {
        return 'const v' + l.code + '=' + (selector(state, 'v' + l.code) || 0);
    }).join(';');
    computes['sum'] = "0";
    for (const l of ownProps.indicatorLines) {
        if (!l.formula) {
            continue;
        }
        try {
            computes['v' + l.code] = eval(`(() => {${values}; return ${l.formula}})()`);
        } catch (e) {
            console.log(e);
        }
        computes['sum'] += computes['v' + l.code];
    }
    return {
        computes,
    }
};

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(form));