export enum LoadingSubject {
    Initial,
    SignIn,
    SignOut,
    SignUp,
    VerifyEmail,
    VerifyPhone,
    RestorePswd,
    UserById,
    ChangePswd,
    UpdateProfile,
    DoctorList,
    DoctorListNextPage,
    Doctor,
    ClinicList,
    ClinicListNextPage,
    Clinic,
    DoctorRating,
    PatientDoctorList,
    SavePatientDoctor,
    UpdatePatientDoctor,
    GetPatientDoctor,
    DeletePatientDoctor,
    PatientClinicList,
    PatientClinicListNextPage,
    SavePatientClinic,
    UpdatePatientClinic,
    GetPatientClinic,
    DeletePatientClinic,
    SaveSchedulingItem,
    SaveMultipleSchedulingItem,
    SchedulingItems,
    CountSchedulingItem,
    DeleteSchedulingItem,
    SaveSchedulingItemRecord,
    SchedulingItemRecords,
    SchedulingItemRecordsFull,
    CountSchedulingItemRecord,
    ApprovingSchedulingItemRecord,
    SaveDevice,

    InitiateCall,

    PatientSurveyList,
    PatientSurvey,
    PatientConsultationHistoryList,
    PatientConsultationHistory,
    PatientAllergyList,
    PatientAllergy,
    PatientNoteList,
    PatientNote,
    PatientAnalyzeList,
    PatientAnalyze,
    PatientDoctorAccessList,
    PatientDoctorAccess,
    PatientMedicineList,
    PatientMedicine,
    PatientVaccinationList,
    PatientVaccination,
    DutyItem,

    CreateClinicComment,
    CreateDoctorComment,
    CreateClinicRating,
    CreateDoctorRating,

    RefreshToken,
    NewDoctor,
    NewDoctor1,
    NewDoctor2,

    Messages,
    MessagesFull,
    IndicatorsInfo,
    IndicatorValues,
    IndicatorValuesNext,
    SaveIndicator,
    IndicatorsChart,
    DoctorPrice,
    Payment,
    Dictionaries,

    ProfileValuesNext,
    ProfileValues,
    SaveProfileValue,
    DeleteProfileValue,
    ProfileValue,
}

export interface ILoadingState {
  subjects: Set<LoadingSubject>;
}
