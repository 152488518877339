import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Footer.scss';
import { Link, Redirect, RouteComponentProps, withRouter } from 'react-router-dom';
import { IAuthState } from '../../states/auth-state';
import { WithTranslation, withTranslation } from 'react-i18next';
import Logo from '../../images/RMD_svg@2x.png';
import JCI from '../../images/JCI@2x.png';
import Ezgil from '../../images/ezgil-2@2x.png';
import CallIcon from '@material-ui/icons/Call';
import WhatsApp from '@material-ui/icons/WhatsApp';
import Email from '@material-ui/icons/Email';
import Modal from 'react-bootstrap/Modal'
import history from './../../history';
import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { toggleAuth } from '../../actions/auth-actions';
import { UserRole } from '../../models/user-role.enum';

interface FooterProps {
    auth: IAuthState;
    toggleAuth: (b: boolean) => void;
}

interface FooterState {
    renderRegistration: boolean;
    show: boolean;
    scrollToMobile: boolean;
}

class FooterComponent extends Component<FooterProps & RouteComponentProps & WithTranslation, FooterState> {
    mobileAppBlock: HTMLDivElement | null | undefined;
    constructor(props: FooterProps & RouteComponentProps & WithTranslation) {
        super(props);
        this.state = {
            renderRegistration: false,
            show: false,
            scrollToMobile: false
        };
    }

    mobileApp(): boolean {
        if (this.props.location.pathname === '/') return true;
        else return false;
    }

    render() {
        const { t, auth: { userToken } } = this.props;
        if (this.props.location.pathname.indexOf('/online-appointment') === 0 ||
            this.props.location.pathname.indexOf('/home-new') === 0) {
            return null;
        }

        return (
            <>
                {this.state.show &&
                    <Modal dialogClassName="modal-main" centered
                        show={this.state.show}
                        onHide={() => this.setState({ show: false })}
                    >
                        <Modal.Header
                            className='my-meds-modal-header'
                            closeButton>
                        </Modal.Header>

                        <Modal.Body>
                            <div style={{
                                border: '1px solid #03A8F5',
                                padding: '35px 40px 20px 40px',
                                marginBottom: '30px',
                            }} >
                                <p style={{
                                    fontSize: '0.9rem',
                                    color: '#03A8F5',
                                }} >Вы уже зарегистрированны на сайте RDM.az</p>
                            </div>
                        </Modal.Body>
                    </Modal>
                }

                <div className='footer'>
                    <div className='footer__left'>
                        <div className='footer__left_container'>
                            <div className='footer__left_logos'>
                                <img className='footer__left_logos_logo' src={Logo} alt='logo' />
                                <img className='footer__left_logos_jci' src={JCI} alt='jci' />
                            </div>
                            <div className='footer__left_contacts'>
                                {t('Make an appointment').toUpperCase()}<br/>
                                913<br/>
                                +99412 530 90 29<br/>
                                +99450 229 81 90<br/>
                                office@rdm.az
                            </div>
                        </div>
                    </div>
                    <div className='footer__right'>
                        <div className='footer__right_container'>
                            <div className='footer__right_col'>
                                <h1>{t('Service')}</h1>
                                <Link to='/vacantions'><p>{t('Vacantions')}</p></Link>
                                <Link to='/contacts'><p>{t('Contacts')}</p></Link>
                                <Link to='/faq'><p>{t('FAQ')}</p></Link>
                                <Link to='/terms-of-use'><p>{t('Terms of use')}</p></Link>
                                <Link to='/privacy'><p>{t('Privacy')}</p></Link>
                                <Link to='/agreement'><p>{t('Consent to the processing of personal data')}</p></Link>
                                {this.mobileApp() ?
                                    <ScrollLink to='mobile-block' spy={true} smooth={true} offset={0} duration={500} delay={100}>
                                        <p className='footer-right-list-item'>{t('Mobile application')}</p>
                                    </ScrollLink> :
                                    <Link to={{ pathname: '/', state: { scrollToMobile: true } }}>
                                        <p className='footer-right-list-item'>{t('Mobile application')}</p>
                                    </Link>}
                            </div>
                            <div className='footer__right_col'>
                                <h1>{t('For patient')}</h1>
                                <Link to='/diseases'><p>{t('Directory of diseases')}</p></Link>
                                <Link to='/doctors'><p>{t('All doctors')}</p></Link>
                            </div>
                            <div className='footer__right_col'>
                                <h1>{t('For doctor and clinic')}</h1>
                                {userToken && (userToken.role === UserRole.Doctor ? 
                                    <Link to='/personal-cabinet-doctor'><p>{t('Profile')}</p></Link> :
                                    userToken.role === UserRole.Clinic ?
                                    <Link to='/personal-cabinet-clinic'><p>{t('Profile')}</p></Link> :
                                    <Link to='/personal-cabinet-patient'><p>{t('Profile')}</p></Link>
                                )}
                                
                                <p className='p-as-link' onClick={() => {
                                            if (this.props.auth.userToken) {
                                                this.setState({ show: true });
                                            }
                                            else {
                                                this.props.toggleAuth(true);
                                            }
                                        }}>
                                        {t('Registration')}
                                </p>
                            </div>
                        </div>
                        <div className='footer__right_ezgil'>
                            <img src={Ezgil} alt='ezgil' />
                            <p>
                                EZGIL medical technologies © 2020.<br/>
                                {t('All right reserved')}
                            </p>
                        </div>
                    </div>
                </div>
                <div className='main-warning'>
                    <p className='main-warning__circle'>0+</p>
                    <p className='main-warning__title'>{t('The information presented on the site cannot be used to diagnose, prescribe treatment and does not replace a doctor\'s appointment.')}</p>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
});

const mapDispatchToProps = {
    toggleAuth,
};

let FooterComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(withRouter(FooterComponent))
);

export default FooterComponentContainer;