import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './OnlineAppointment.scss';
import { Link } from 'react-router-dom';
import { IAuthState } from "../../states/auth-state";
import { approve, cancel, getSchedulingItemRecords, getSchedulingItemRecordById, payForRecord } from '../../actions/scheduling-item-record-actions';
import { ISchedulingItemRecordFilter } from '../../models/scheduling-item-record-filter';
import { SortOrder } from '../../models/sort-order';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ISchedulingItemRecordsState } from '../../states/scheduling-item-records-state';
import { ILoadingState, LoadingSubject } from '../../states/loading-state';
import { UserRole } from '../../models/user-role.enum';
import { IDictionaryState } from '../../states/dictionary-state';
import * as environment from '../../app.json';
import Spinner from 'react-spinner-material';
import Chat from './Chat';
import { ISchedulingItemRecord, SchedulingItemRecordStatus } from '../../models/scheduling-item-record';
import { ringOnScheduler } from '../../actions/call-actions';
import { ICallState } from '../../states/call-state';
import Call from './Call';
import { CallStage } from '../../models/call-stage';
import { withTranslation, WithTranslation } from 'react-i18next';
import moment from 'moment';
import { RecordStatus } from './RecordStatus';
import CloseIcon from '@material-ui/icons/Close';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import uiTheme from "../../theme";
import Modal from 'react-bootstrap/Modal';
import queryString from 'query-string';
import history from '../../history';
import { IDoctor } from '../../models/doctor';
import Breadcrumbs from './../Breadcrumbs/Breadcrumbs';

interface MyProps {
    auth: IAuthState;
    call: ICallState;
    schedulingItemRecords: ISchedulingItemRecordsState;
    loading: ILoadingState;
    dictionary: IDictionaryState;
    ringOnScheduler: (record: ISchedulingItemRecord) => void;
    getSchedulingItemRecords: (
        filter: ISchedulingItemRecordFilter,
        page?: number,
    ) => void;
    getSchedulingItemRecordById: (id: number) => void;
    approve: (item: ISchedulingItemRecord) => void;
    cancel: (item: ISchedulingItemRecord) => void;
    location: Location;
    payForRecord: (record: ISchedulingItemRecord) => void;
}

interface MyState {
    selectedRecord?: ISchedulingItemRecord;
    videoMode: boolean;
    approveModal: boolean;
    cancelModal: boolean;
    recordId?: string;
}

class OnlineAppointment extends Component<MyProps & WithTranslation, MyState> {

    constructor(props: MyProps & WithTranslation) {
        super(props);
        const videoMode = props.call.stage === CallStage.Outcome || props.call.stage === CallStage.Call;
        this.state = {
            videoMode,
            selectedRecord: videoMode ? props.call.stageData?.record : undefined,
            approveModal: false,
            cancelModal: false,
            recordId: undefined
        };
    }

    getPatient = () => {
        const { location: { search } } = this.props;
        const { chat } = queryString.parse(search);
        if (chat) {
            this.props.getSchedulingItemRecordById(Number(chat));
        }
    }

    componentWillUnmount() {
    }

    componentDidMount() {
        this.props.getSchedulingItemRecords({ dateOrder: SortOrder.Asc, asPatient: true }, 0);
        this.getPatient();
    }

    componentDidUpdate(prevProps: MyProps) {
        const newProps = this.props;
        if (newProps.call.stage !== (prevProps.call || {}).stage && newProps.call.stage === CallStage.Outcome) {
            this.setState({ videoMode: true });
        }
        if ((prevProps.call || {}).stage === CallStage.Income && newProps.call.stage === CallStage.Call) {
            this.setState({ videoMode: true });
        }

        if (this.state.videoMode && newProps.call.stage === CallStage.Idle) {
            this.setState({ videoMode: false });
        }

        if (prevProps.schedulingItemRecords?.selectedRecord !== newProps.schedulingItemRecords?.selectedRecord) {
            this.setState({ selectedRecord: newProps.schedulingItemRecords.selectedRecord })
        }

        if (prevProps.schedulingItemRecords?.redirect !== newProps.schedulingItemRecords?.redirect) {
            const { redirect } = newProps.schedulingItemRecords;
            if (redirect) window.open(redirect);
        }
    }

    handleCall(): void {
        if (this.props.call.stage === CallStage.Call || this.props.call.stage === CallStage.Outcome) {
            this.setState({ videoMode: true });
            return;
        }
        this.props.ringOnScheduler(this.state.selectedRecord!);
    }

    haveCancelation(status: SchedulingItemRecordStatus): boolean {
        switch (status) {
            case SchedulingItemRecordStatus.Created:
            case SchedulingItemRecordStatus.Approved:
            case SchedulingItemRecordStatus.Paid:
                return true;
            default:
                return false;
        }

    }

    haveApprove(r: ISchedulingItemRecord): boolean {
        if (this.props.auth.userToken?.selfId !== r.doctorId) {
            return false;
        }
        switch (r.status) {
            case SchedulingItemRecordStatus.Created:
                return true;
            default:
                return false;
        }

    }

    haveCall(doctor: ISchedulingItemRecord) {
        if (doctor.patientId !== this.props.auth.userToken?.selfId) {
            return;
        }
        return doctor.status === SchedulingItemRecordStatus.Ready ||
            doctor.status === SchedulingItemRecordStatus.Initiated ||
            doctor.status === SchedulingItemRecordStatus.Accepted ||
            doctor.status === SchedulingItemRecordStatus.Paid;
    }

    time(r: ISchedulingItemRecord) {
        return <div className="time">
            {moment(r.start).format('DD MMMM YYYY') + " • "}
            {moment(r.start).format('HH:mm')} - {moment(r.end).format('HH:mm')}
        </div>
    }

    renderItem(r: ISchedulingItemRecord) {
        return <div key={r.id} className={'item' + (r.id === this.state.selectedRecord?.id ? ' item-selected' : '')} onClick={() => {
            if (r.status === SchedulingItemRecordStatus.Approved) this.props.payForRecord(r);
            this.setState({ selectedRecord: r });
        }}>
            <img src={this.props.auth.userToken?.selfId === r.doctorId ?
                r.patientImage ? r.asPatient ? environment.uploads + 'doctors/' + r?.patientImage : environment.uploads + 'patients/' + r?.patientImage :
                    require('./../../images/no-user-photo.png') :
                r.doctorImage ? environment.uploads + 'doctors/' + r?.doctorImage :
                    require('./../../images/doctor-default-avatar.png')} />

            {r.asPatient || this.props.auth.userToken?.role === UserRole.Patient ?
                <div className="item-text-info">
                    <div className="first-info">
                        <div>
                            <Link to='#'>{r.doctorSpecIds?.map(s => ((this.props.dictionary.specializationTypesMap || {})[s] || {}).name).join(', ')}</Link>
                            {this.time(r)}
                        </div>
                        <RecordStatus item={r} translationProps={this.props} hideText={true} />
                    </div>
                    <Link className="doctor-name" to='#'>{r.doctorName}</Link>
                </div> :
                <div className="item-text-info">
                    <div>
                        <div>
                            <Link to='#'>{r.patientName}</Link>
                            {this.time(r)}
                        </div>

                    </div>
                </div>
                // <div>
                //     <Link to='#'>{r.doctorSpecIds?.map(s => ((this.props.dictionary.specializationTypesMap || {})[s] || {}).name).join(', ')}</Link>
                //     {this.time(r)}
                //     <Link to='#'>{r.doctorName}</Link>
                // </div> :
                // <div>
                //     <Link to='#'>{r.patientName}</Link>
                //     {this.time(r)}
                // </div> 
            }
        </div>
    }

    activeDoctor() {
        const r = this.state.selectedRecord;
        if (!r) {
            return <div />;
        }
        return <div className='active-doctor'>
            <div>
                <Link to='#'>{r.doctorId === this.props.auth.userToken?.selfId ? r.patientName : r.doctorName}</Link>
                <div className='item-information'>
                    {this.time(r)}
                    <RecordStatus translationProps={this.props} item={r} hideText={true} />
                </div>
            </div>
            {this.haveCall(r) && <img src={require('./../../images/call-button.png')} onClick={() => this.handleCall()} />}
            <div className={'cancelation-items'}>
                {this.state.selectedRecord && this.haveApprove(this.state.selectedRecord) && <div className={'approve-item'} onClick={() => {
                    this.setState({ approveModal: true });
                }}><ThumbUpIcon fontSize='small' htmlColor={uiTheme.palette.lightGreenColor} />{this.props.t('approve')}</div>}
                {this.state.selectedRecord && this.haveCancelation(this.state.selectedRecord.status) && <div className={'cancel-item'} onClick={() => {
                    this.setState({ cancelModal: true });
                }}><CloseIcon fontSize='small' htmlColor={uiTheme.palette.secondaryColor} />{this.props.t('cancel')}</div>}
            </div>
        </div>
    }

    render() {
        const schedulingItemRecordList = this.props.schedulingItemRecords.schedulingItemRecordList;
        //console.log('online appointment props', this.props)
        //console.log('this.state.selectedRecord', this.state.selectedRecord);

        return (
            <div className='online-appointment-content'>

                {/* <QuickAccess /> */}

                {/* <div className='search-in-medical-card-wrapper'>
                    <div className='search-in-medical-card'>
                        <h1>Поиск по моей медицинской карте!</h1>
                        <input />
                    </div>
                </div> */}

                <div className='online-appointment-main'>
                    {/* <h1>Онлайн приём</h1> */}
                    <div style={{ maxWidth: '950px', display: 'flex', margin: '10px auto' }}><Breadcrumbs location={this.props.location} /></div>

                    <div className='chat-wrapper'>
                        <div className='left-block'>

                            <div className='my-user'>
                                <div className='avatar-block'>
                                    <img className='_img' src={this.props.auth.userToken?.role === UserRole.Doctor ?
                                        this.props.auth.self?.imageFile ? environment.uploads + 'doctors/' + this.props.auth.self?.imageFile :
                                            require('./../../images/doctor-default-avatar.png') :
                                        this.props.auth.self?.image ? environment.uploads + 'patients/' + this.props.auth.self?.image :
                                            require('./../../images/no-user-photo.png')} ></img>
                                    <div className='online-indicator'></div>
                                </div>
                                {this.props.auth.self?.name && <span>{this.props.auth.userToken?.role === UserRole.Doctor ? (this.props.auth.self as IDoctor)?.surname + " " + this.props.auth.self?.name + " " + (this.props.auth.self as IDoctor)?.patronymic : this.props.auth.self?.name}</span>}
                            </div>

                            <div className='search-bar'>
                                {/* <input /> */}
                            </div>

                            <div className='my-consultations-wrapper'>
                                <p>{this.props.t('My consultations ')}</p>
                                <div className='my-consultations-container' id="consultaions-container">
                                    <InfiniteScroll
                                        next={() => {
                                            if (this.props.schedulingItemRecords.schedulingItemRecordsEnd || this.props.loading.subjects.has(LoadingSubject.SchedulingItemRecords) || this.props.loading.subjects.has(LoadingSubject.SchedulingItemRecordsFull)) {
                                                return;
                                            }
                                            this.props.getSchedulingItemRecords({ dateOrder: SortOrder.Asc, asPatient: true }, this.props.schedulingItemRecords.schedulingItemRecordsPage! + 1)
                                        }}
                                        scrollableTarget="consultaions-container"
                                        hasMore={!this.props.schedulingItemRecords.schedulingItemRecordsEnd}
                                        loader={<Spinner radius={24} color={"$main-dark-color"} stroke={2} visible={true}></Spinner>}
                                        dataLength={schedulingItemRecordList?.length || 0}
                                    >
                                        {schedulingItemRecordList?.map(r => this.renderItem(r))}

                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>

                        {this.state.selectedRecord && !this.state.videoMode && <div className='right-block'>
                            {this.activeDoctor()}

                            <Chat record={this.state.selectedRecord} />
                        </div>}

                        {!this.state.selectedRecord && <div className='right-block'>

                            <div className='active-doctor'>
                                <div>
                                    <div className='item-information' style={{ minHeight: '30px' }}>
                                    </div>
                                </div>
                                <div className={'cancelation-items'}>
                                </div>

                            </div>
                            <div className='messages-wrapper'>
                                <div className='messages-block-main' >
                                    <div className='no-item-selected'>
                                        {this.props.t('Choose the consultation for starting the chat')}
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {this.state.selectedRecord && this.state.videoMode && <div className='right-block'>
                            <Call showChat={() => this.setState({ videoMode: false })} />
                        </div>}
                    </div>
                </div>

                <Modal
                    size='sm'
                    centered
                    show={this.state.cancelModal}
                    onHide={() => this.setState({ cancelModal: false })}
                >
                    <Modal.Body className='modal-online-appointment'>
                        <h5>{this.props.t('Are you sure you want to cancel record?')}</h5>

                        <button className='btn-modal-window btn btn-primary btn-sm' onClick={() => {
                            this.state.selectedRecord && this.props.cancel(this.state.selectedRecord);
                        }}>
                            {this.props.t('Yes')}
                        </button>
                        &nbsp;
                        <button className='btn-modal-window btn-modal-window-negative btn btn-secondary btn-sm' onClick={() => {
                            this.setState({ cancelModal: false });
                        }}>
                            {this.props.t('No')}
                        </button>
                    </Modal.Body>
                </Modal>

                <Modal
                    size='sm'
                    centered
                    show={this.state.approveModal}
                    onHide={() => this.setState({ approveModal: false })}
                >
                    <Modal.Body className='modal-online-appointment'>
                        <h5>{this.props.t('Are you sure you want to approve record?')}</h5>

                        <button className='btn-modal-window btn btn-primary btn-sm' onClick={() => {
                            this.state.selectedRecord && this.props.approve(this.state.selectedRecord);
                        }}>
                            {this.props.t('Yes')}
                        </button>
                        &nbsp;
                        <button className='btn-modal-window btn-modal-window-negative btn btn-secondary btn-sm' onClick={() => {
                            this.setState({ approveModal: false });
                        }}>
                            {this.props.t('No')}
                        </button>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    form: state.form,
    auth: state.auth,
    call: state.call,
    schedulingItemRecords: state.schedulingItemRecords,
    loading: state.loading,
    dictionary: state.dictionary,
});

const mapDispatchToProps = {
    getSchedulingItemRecords,
    getSchedulingItemRecordById,
    ringOnScheduler,
    payForRecord,
    cancel,
    approve,
};

let OnlineAppointmentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(OnlineAppointment)
);

export default OnlineAppointmentContainer