import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import './Home.scss';
import FiltersComponentContainer from './../Filters/Filters';
import { Link, RouteComponentProps } from 'react-router-dom';
import { IAuthState } from "../../states/auth-state";
import { WithTranslation, withTranslation } from 'react-i18next';
import Alphabet from '../Alphabet/Alphabet';
import { getStatistics } from '../../actions/statistics-actions';
import { IStatistics } from '../../states/statistics';
import Logo from '../../images/RDM_logo@2x.png';
import Mobile1 from '../../images/obrezannoye@2x.png';
import Mobile2 from '../../images/group446@2x.png';
import Carousel from 'react-bootstrap/Carousel';
import history from "./../../history";
import { ReactComponent as Slider1Ru } from './../../images/slider1_ru.svg';
import { ReactComponent as Slider1En } from './../../images/slider1_en.svg';
import { ReactComponent as Slider1Az } from './../../images/slider1_az.svg';
import DoctorIcon from './../../images/Group 438.png';
import MedCardIcon from './../../images/Group 2106.png';
import AppointmentIcon from './../../images/Group 2187.png';
import MobAppIcon from './../../images/Group 2188.png';

import { Link as ScrollLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import { toggleAuth, verfyFromParams } from '../../actions/auth-actions';

interface MyProps {
    auth: IAuthState;
    statistics: IStatistics;
    language: string;
    getStatistics: () => void;
    toggleAuth: (b: boolean) => void;
    verfyFromParams: (
        userdata: string,
        code: string,
        operation: string | null,
    ) => void;
    location: any;
}

interface MyState {
    slider_index: number;
    scrollToMobile: any;
}

class HomeComponent extends Component<MyProps & WithTranslation & RouteComponentProps, MyState> {
    mobileAppBlock: HTMLDivElement | null | undefined;

    constructor(props: MyProps & WithTranslation & RouteComponentProps) {
        super(props);
        this.state = {
            slider_index: 1,
            scrollToMobile: false,
        };

        if (props.location.pathname !== '/verify') {
            return;
        }
        const params = new URLSearchParams(props.location.search);
        const code = params.get('code');
        const userdata = params.get('userdata');
        const operation = params.get('operation');
        if (code && userdata) {
            props.verfyFromParams(userdata, code, operation);
        }
    }

    componentDidMount() {
        this.props.getStatistics();
        
        const { scrollToMobile } = this.state;
        const { history, location } = this.props;
        if (this.props?.location?.state?.scrollToMobile) {
            console.log('scroll', scrollToMobile)
            scroller.scrollTo('mobile-block', {
                smooth: true
            });
            history.replace('/')
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div className='home'>
                <FiltersComponentContainer/>
                <div className='features-block-main'>
                    <p className='small-title'>{t('Also you can on RDM')}</p>
                    <div className='features-block'>
                        <div className='feature-container' onClick={() => history.push('/doctors')}>
                            <img className='feature-image' src={DoctorIcon} alt='feature_doctors' />
                            <p
                                className='feature-desc'>
                                {t('Make')}
                                <span> {t('an appointment')}</span>
                            </p>
                        </div>
                        <div className='feature-container' onClick={() => {
                            if (this.props.auth.userToken?.auth) {
                                history.push('/medical-card');
                            } else {
                                this.props.toggleAuth(true);
                            }
                        }}>
                            <img className='feature-image' src={MedCardIcon} alt='feature_medical_card' />
                            <p className='feature-desc'>
                                {t('Create')} <span>{t('own medcard')}</span>
                            </p>
                        </div>
                        <div className='feature-container' onClick={() => history.push('/doctors')}>
                            <img className='feature-image' src={AppointmentIcon} alt='feature_video' />
                            <p className='feature-desc'>{t('Make ')} <span>{t('an online appointment')}</span></p>
                        </div>
                        <div className='feature-container' onClick={() => this.mobileAppBlock && this.mobileAppBlock.scrollIntoView({behavior: 'smooth'})}>
                            <img className='feature-image' src={MobAppIcon} alt='feature_os' />
                            <p className='feature-desc'>{t('Download')} <span>{t('mobile application')}</span></p>
                        </div>
                    </div>
                </div>

                <div className='reviews-block'>
                    <div className='counter-block-wrapper'>
                        <h1 className='big-title'>{t('We care about your health')}</h1>
                        <div className='reviews-nav'>
                            <p className='review-item'>{t('The service began operating in 2020 under the VideoDoc brand. We have helped millions of people to get help and we continue to improve the quality of medical services.')}</p>
                        </div>
                        <div className='counter-block blue-background'>
                            <div className='counter-item'>
                                <p className='counter-title'>{this.props.statistics?.totalRecords}</p>
                                <p className='counter-desc'>{t('Patient appointed')}</p>
                            </div>
                            <div className='counter-item'>
                                <p className='counter-title'>{this.props.statistics?.totalReviews}</p>
                                <p className='counter-desc'>{t('Comments on site')}</p>
                            </div>
                            <div className='counter-item'>
                                <p className='counter-title'>{this.props.statistics?.totalDoctors}</p>
                                <p className='counter-desc'>{t('Practice doctor in base')}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Alphabet />
                <Element name='mobile-block' className='standart-margin-bottom'>
                    <div className='content-block' id='mobile-app-block' ref={ref => this.mobileAppBlock = ref}>
                        <h1 className='big-title'>{t('Mobile application')}</h1>
                        <div className='reviews-nav'>
                            <p className='review-item'>{t('No way to choose a doctor at the computer? No problem! After all, the VideoDoktor mobile application is available from any smartphone! Just follow the link!')}</p>
                        </div>

                        <div className='mobile-app-block blue-background'>
                            <div className='mobile-app-left'>
                                <img className='app_icon' src={Logo} alt="app_icon" />
                                <p className='mobile-app-desc'>{t('Verified doctors in your phone')}</p>
                                <img className='qr-code-img' src={require('./../../images/qr_code_img.png')} alt="qr_code_img" />
                                <div className='app-images-block'>
                                    <div className='app-markets'>
                                        <div><a href='https://apps.apple.com/ru/app/video-doktor/id1520369601' target='__blank' ><img className='app-store' src={require('./../../images/app_store.png')} alt="app_store" /></a></div>
                                        <div><a href='https://play.google.com/store/apps/details?id=az.ezgil.videodoctor' target='__blank'><img className='google-play' src={require('./../../images/google_play.png')} alt="google_play" /></a></div>
                                    </div>
                                </div>
                            </div>
                            <div className='mobile-app-right'>
                                <img className='app_img1' src={Mobile1} alt='app_img1' />
                                <img className='app_img2' src={Mobile2} alt='app_img2' />
                            </div>
                        </div>
                    </div>
                </Element>
            </div >
        )
    }
}

const mapStateToProps = (state: RootState) => ({
    form: state.form,
    auth: state.auth,
    statistics: state.statistics,
    language: state.settings.language,
});

const mapDispatchToProps = {
    getStatistics,
    toggleAuth,
    verfyFromParams,
};

let HomeComponentContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(HomeComponent)
);

export default HomeComponentContainer