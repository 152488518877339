import {IDoctorState} from "../states/doctor-state";
import {
    DOCTOR, 
    DOCTOR_LIST, 
    DOCTOR_RATING, 
    DoctorActionTypes, 
    DOCTORS_PAGE_SIZE, 
    DOCTOR_LIST_COUNT, 
    DOCTORS_AUTOCOMPLETE, 
    DOCTOR_CACHED_DETAILS, 
    DOCTOR_FULL_LIST,
    DOCTOR_PRICE } from "../actions/doctor-action";
import { IDoctor } from "../models/doctor";

const initialState: IDoctorState = {
    doctors: [],
    mydoctors: [],
    doctor: undefined,
    doctorRating: undefined,
    doctorFullList: [],
    fullTotalCount: 0,
    mydoctorsPage: 0,
    mydoctorsFinal: false,
    doctorsPage: 0,
    doctorsFinal: false,
    doctorsAutocomplete: [],
    doctorDetails: new Map<number, IDoctor>(),
    doctorPrice: []
}

export function doctorReducer(
    state = initialState,
    action: DoctorActionTypes
): IDoctorState {
    switch (action.type) {
        case DOCTOR_LIST:
            if (action.my) {
                const mydoctors = action.doctors;
                return {...state, ...{mydoctors: mydoctors, mydoctorsFinal: action.doctors?.length < DOCTORS_PAGE_SIZE, mydoctorsPage: action.page + 1}};
            }
            const doctors = action.doctors;
            return {...state, ...{doctors: doctors, doctorsFinal: action.doctors?.length < DOCTORS_PAGE_SIZE, doctorsPage: action.page + 1}};
        case DOCTOR:
            return {...state, ...{doctor: action.doctor}};
        case DOCTOR_RATING:
            return {...state, ...{doctorRating: action.doctorRating}};
        case DOCTOR_FULL_LIST:
            state = {...state, ...{doctorFullList: action.doctorFullList}}
            console.log(state);
            return state;
        case DOCTOR_LIST_COUNT:
            return {...state, ...{fullTotalCount: action.totalCount}};
        case DOCTORS_AUTOCOMPLETE:
            return {...state, ...{doctorsAutocomplete: action.doctorsAutocomplete}};
        case DOCTOR_CACHED_DETAILS:
            const doctorMap: Map<number, IDoctor> = new Map<number, IDoctor>(state.doctorDetails);
            doctorMap.set(action.doctor.id, action.doctor);
            return {...state, ...{doctorDetails: doctorMap}};
        case DOCTOR_PRICE:
            return {...state, ...{doctorPrice: action.doctorPrice}};
        default:
            return state;
    }
    
}
