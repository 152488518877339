import { faCaretDown, faCheckCircle as fasCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment } from 'react';
import { IDropdownItem } from '../../models/dropdown-item';
import OutsideClick from './OutsideClick';


interface DropdownProps {
    title: string;
    text?: string;
    items?: IDropdownItem[];
    fullDropdown: boolean;
    onClick?: (strArg: string) => void;
}

interface DropdownState {
    isActive: boolean;
    isItemActive: boolean[];
}

class VDDropdown extends React.Component<DropdownProps, DropdownState> {
    constructor(props: DropdownProps) {
        super(props);
        this.state = {
            isActive: false,
            isItemActive: new Array(this.props.items?.length).fill(false),
        }
    }

    clickHandler(param: string) {
        this.props.onClick && this.props.onClick(param.toLowerCase());
        this.setState({isActive: false});
    }

    clickOutsideHandler() {
        this.setState({isActive: false});
    }

    renderBig() {
        const { title, text, items } = this.props;
        return(
            <div className='vd-dropdown'>
                <div className='vd-dropdown__title' onClick={() => this.setState({isActive: !this.state.isActive})}>
                    <p>{title}</p>
                    <p className='vd-dropdown__drop'><FontAwesomeIcon size={'lg'} icon={faCaretDown} /></p>
                </div>
                {this.state.isActive && (
                    <div className='vd-dropdown__item'>
                        {this.props.items instanceof Array && this.props.items.map((item: IDropdownItem, i) => (
                            <Fragment key={i}>
                                <p
                                    onClick={() => {
                                        let tmpArr: boolean[] = this.state.isItemActive;
                                        tmpArr[i] = !tmpArr[i];
                                        this.setState({isItemActive: tmpArr});
                                    }} 
                                    className={this.state.isItemActive[i] ? 'vd-dropdown__item_title item-selected' : 'vd-dropdown__item_title'}>
                                    <FontAwesomeIcon color='#017698' icon={farCheckCircle} /> {item.title}
                                </p>
                                {this.state.isItemActive[i] && 
                                    <div className='vd-dropdown__item_text' dangerouslySetInnerHTML={{__html: item.text || ''}}/>
                                }
                            </Fragment>
                        ))}
                    </div>
                )}
            </div>
        )
    }

    renderMiniDropdown() {
        const { title, items } = this.props;

        return (
            <>
                <OutsideClick clickHandler={this.clickOutsideHandler.bind(this)}>
                    <div className='mini-dropdown'>
                        <div className='mini-dropdown__header' onClick={() => this.setState({isActive: !this.state.isActive})}>
                            <p className='mini-dropdown__header_title'>{title}</p>
                            <p className='mini-dropdown__header_drop'><FontAwesomeIcon icon={faCaretDown} size={'lg'} color={'#fff'} /></p>
                        </div>
                        {this.state.isActive && <div className='mini-dropdown__items'>
                            {items instanceof Array && items.filter(item => item.title !== title).map(item => (
                                <p className='mini-dropdown__items_item' key={item.title} 
                                    onClick={() => this.clickHandler(item.title)}>
                                    {item.title}
                                </p>
                            ))}
                        </div>}
                    </div>
                </OutsideClick>
            </>
        )
    }

    render() {
        if(this.props.fullDropdown) return this.renderBig();
        else return this.renderMiniDropdown();
    }
}

export default VDDropdown;