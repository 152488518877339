
import React, { ReactNode } from 'react';
import { WrappedFieldProps } from "redux-form";
import { Multiselect } from 'multiselect-react-dropdown';
import './DropDownSelect.scss';
import { WithTranslation, withTranslation } from 'react-i18next';

export interface CustomFieldProps extends WithTranslation {
    name?: string;
    label?: string;
    _className: string;
    type?: string;
    selectedValue?: number;
    style?: any;
    valuesForDropdown: Array<any>;
    onClose?: (id: number | undefined) => void;
    placeholder?: string;
}

class MiniDropDownSelect extends React.Component<WrappedFieldProps & CustomFieldProps> {

    render() {

        const { input: { onChange, onBlur, value },
            label, valuesForDropdown, onClose, type, selectedValue,
            meta: { error, touched, valid }, _className, name, placeholder, t } = this.props;

        const errorClassName = !valid && touched ? 'error' : '';

        if (valuesForDropdown && valuesForDropdown.length) {
            valuesForDropdown.sort((a, b) => a.label?.localeCompare(b.label));
        }

        console.log('selected', selectedValue);
        console.log('value', value);
        console.log('valuesForDropdown', valuesForDropdown);
        console.log('onc', onChange);

        let sel: ReactNode;

        if (type === 'multiselect') {
            const splited = (value || '').split(',');
            const options = valuesForDropdown.map(o => ({ id: o.value, name: o.label }));

            sel = <Multiselect
                options={options}
                selectedValues={options.filter(o => splited.indexOf(o.id?.toString()) >= 0)}
                placeholder={placeholder}
                onSelect={(v) => onChange(v.map(o => o.id).join(','))}
                onRemove={(v) => onChange(v.map(o => o.id).join(','))}
                displayValue="name"
            />;
        } else {
            sel = <select
                className={errorClassName}
                name={name}
                onChange={onChange}
            >
                <option key={"not-selected"} selected={!value && value !== 0} value={""}>{t('Not selected')}</option>
                {
                    valuesForDropdown && valuesForDropdown.map((i) => {
                        return <option key={i.value} selected={i.value === value} value={i.value}>{i.label}</option>
                    })
                }
            </select>
        }

        return (
            <div className={'mini-text-input-component reg-input-styles' + ' ' + _className}>
                <p>{label}</p>
                {sel}
                {!valid && touched ? <p className='text-input-component__error'>{error}</p> : null}
            </div>
        );
    }
}

export default withTranslation()(MiniDropDownSelect);