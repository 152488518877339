import React from 'react';
import NumberFormat from 'react-number-format';
import { WrappedFieldProps } from "redux-form";
import './TextInput.scss';
import { Editor } from 'react-draft-wysiwyg';
import { stateFromHTML } from 'draft-js-import-html';
import {stateToHTML} from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import moment from 'moment';

export interface CustomFieldProps {
    name?: string;
    placeholder?: string;
    label?: string;
    type?: string;
    disableInput?: boolean;
    editable?: boolean;
    multiline?: boolean;
    _className?: any;
}

class MiniTextInput extends React.Component<WrappedFieldProps & CustomFieldProps> {

    render() {
        const { placeholder, label, type, disableInput, editable, multiline, _className,
            input: { onChange, onBlur }, meta: { touched, valid, error }, name
        } = this.props;

        let value = this.props.input.value;

        if (type === 'datetime-local' && value.length == 19) {
            value = value.replace(" ", "T");
            value = value.substring(0, value.length - 3);
        }

        if (type === 'date') {
            value = moment(value).format('yyyy-MM-DD');
        }
        
        const errorClassName = !valid && touched ? 'error' : '';

        console.log('!valid && touched ' + label + ' ' + !valid + ' ' + touched);

        return (
            <div className={'mini-text-input-component reg-input-styles' + ' ' + _className}>
                <p>{label ? label : placeholder}</p>
                {type !== 'textarea' ? 
                    (type === 'decimal' || type === 'integer') ? <NumberFormat
                        className={errorClassName}
                        name={name}
                        onChange={onChange}
                        value={value}
                        placeholder={placeholder}
                    /> :
                    type === 'html' ?
                        <Editor
                            toolbar={{ 
                                options: ['inline', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history']
                            }}
                            onEditorStateChange={e => onChange(stateToHTML(e.getCurrentContent()))}
                            defaultEditorState={EditorState.createWithContent(stateFromHTML(value))}/>
                        :
                        <input
                            className={errorClassName}
                            name={name}
                            type={type}
                            onChange={onChange}
                            value={value}
                            placeholder={placeholder}
                        />
                :
                    <textarea
                        className={errorClassName}
                        name={name}
                        onChange={onChange}
                        value={value}
                        placeholder={placeholder} /> 
                }
                {!valid && touched ? <p className={'mini-text-input-component__error' + (type === 'datetime-local' || type === 'date' ? ' offset12' : '')}>{error}</p> : null}
            </div>
        );
    }
}

export default MiniTextInput;