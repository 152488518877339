import React, { Component } from 'react';
import { connect } from "react-redux";
import { RootState } from "../../store";
import { WithTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './HomeNew.scss';
import HealthCardLogoSVG from './../../images/home-new/health-card-logo.svg';
import VideodoctorLogoSVG from './../../images/home-new/videodoctor-logo.svg';
import AddCircleSVG from './../../images/home-new/add-circle.svg';
import TelephoneSVG from './../../images/home-new/telephone-svg.svg';
import MessageSVG from './../../images/home-new/message-svg.svg';
import EzgilSVG from './../../images/home-new/ezgil-white-svg.svg';

interface MyProps {
}

interface MyState {
}

class HomeNew extends Component<MyProps & WithTranslation, MyState> {

    constructor(props: MyProps & WithTranslation) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className='home-new-wrapper'>

                <div className='home-new-header'>
                    <div className='left-block'>
                        <div className='left-sub-1'>
                            <Link to='/'>
                                <img width='35' src={HealthCardLogoSVG} alt="Health Card" />
                                <p>Şəfa Kartım</p>
                            </Link>
                        </div>

                        <div className='separator'></div>

                        <div className='left-sub-2'>
                            <Link to='/'>
                                <img width='27' src={VideodoctorLogoSVG} alt="Health Card" />
                                <p><span>by</span> VideoDoktor</p>
                            </Link>
                        </div>
                    </div>
                    <div className='right-block'>
                        <div className='lang-block-home-new'>
                            <Link to='/'>Az</Link>
                            <Link to='/'>Ru</Link>
                            <Link to='/'>En</Link>
                        </div>

                        <div className='help-links'>
                            <Link to='/'>Помощь</Link>
                            <Link to='/'>Функции</Link>
                        </div>

                        <button className='access-to-health-card-btn'>
                            Доступ к Şəfa Kartım
                        </button>
                    </div>

                </div>

                <div className='welcome-block'>
                    <div className='main-info-block'>
                        <div className='health-icon-block'>
                            <img width='85' src={HealthCardLogoSVG} alt="Health Card" />
                            <p>Şəfa Kartım</p>
                        </div>
                        <div className='desc-and-button'>
                            <p>Присоединяйтесь к сотням пациентов, которые уже используют Şəfa Kartım для управления своим здоровьем и лечением</p>
                            <button>Доступ к Şəfa Kartım</button>
                        </div>
                    </div>

                    <div className='apps-block'>
                        <Link to='/'>
                            <img width='130' src={require('./../../images/appstore.png')} alt="App Store" />
                        </Link>
                        <Link to='/'>
                            <img width='155' src={require('./../../images/playmarket.png')} alt="Google play" />
                        </Link>
                    </div>
                </div>

                <div className='features-list-main-block'>

                    <h2>Электронная медицинская карта в смартфоне</h2>
                    <h3>«Şəfa Kartım» дает полный доступ к электронной медицинской карте и позволяет:</h3>

                    <div className='features-list-items'>
                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>записаться к врачу, перенести прием, настроить уведомления о предстоящих посещениях</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>записаться на лабораторные исследования по назначениям</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>ознакомиться с протоколами осмотров врачей, результатами лабораторных и инструментальных исследований, выписками из стационаров</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>самостоятельно вносить данные о температуре тела, уровне кислорода, пульсе, уровне сахара в крови и артериальном давлении</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>ознакомиться с информацией о выполненных собственных вакцинациях и историей вакцинации детей до 18 лет</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>скачать медицинские документы и отправить через электронную почту или мессенджеры</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>самостоятельно загружать медицинские документы: протокол осмотра врача, результаты лабораторных и инструментальных исследований, выписной эпикриз из стационара, рецепт, заключение врачебной комиссии</p>
                        </div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>ознакомиться с результатами анализов на коронавирусную инфекцию, сделанных после 20 апреля</p>
                        </div>

                        <div></div>

                        <div>
                            <img width='22' src={AddCircleSVG} alt="Add Circle" />
                            <p>посмотреть выписанные рецепты</p>
                        </div>
                    </div>

                </div>

                <div className='app-features-block'>
                    <div className='app-description'>
                        <h2>Вся информация о вашем здоровье в одном месте</h2>
                        <p>Смотрите свои лекарства, результаты анализов,
                        предстоящие встречи, медицинские счета, оценку
                        цен и многое другое в одном месте, даже если вас
                        посещали несколько медицинских  организаций.
                        </p>
                        <button>Узнать больше</button>
                    </div>
                </div>

                <div className='share-your-card-block'>
                    <div className='share-description'>
                        <h2>Делитесь своей медицинской картой безопасно и надежно</h2>
                        <p>Поделитесь своей медицинской картой со всеми, кто
                        в ней нуждается. Большинство ваших поставщиков
                        медицинских услуг уже могут получить необходимую
                        информацию, но если они не могут, вы можете
                        поделиться своими записями на месте.
                        </p>
                        <button>Узнать больше</button>
                    </div>
                </div>

                <div className='make-an-appointment-block'>
                    <div className='make-an-appointment-description'>
                        <h2>Быстро назначьте встречу и получите помощь</h2>
                        <p>Назначьте встречи в удобное для вас время,
                        выполняйте задачи перед визитом из дома и
                        находите ближайшее отделение неотложной
                        помощи или неотложной помощи, когда вам
                        это нужно.
                        </p>
                    </div>
                </div>

                <div className='take-care-of-your-family-block'>
                    <div className='take-care-of-your-family-description'>
                        <h2>Позаботьтесь о своих детях и других членах семьи</h2>
                        <p>Будьте в курсе всех назначенных встреч и
                        проверяйте членов семьи, которым
                        требуется дополнительная помощь,
                        - все из вашей учетной записи.
                        </p>
                    </div>
                </div>

                <div className='contact-your-doctor-block'>
                    <div className='contact-your-doctor-description'>
                        <h2>Свяжитесь с врачом, где бы вы ни находились</h2>
                        <p>Отправьте сообщение, получите онлайн-
                        диагностику и лечение, поговорите лицом
                        к лицу по видео или договоритесь о
                        последующем посещении врача лично,
                        в зависимости от уровня необходимой
                        вам помощи.
                        </p>
                    </div>
                </div>

                <div className='see-how-block'>
                    <h2>Посмотрите, как Şəfa Kartım вписывается в вашу повседневную жизнь</h2>
                    <div className='see-how-description'>
                        <div className='video-tutorial'></div>
                        <div className='video-tutorial-desc'>
                            <p>И это только царапает поверхность</p>
                            <p>Взгляните на полный список функций</p>
                            <button>Посмотреть все функции</button>
                        </div>
                    </div>
                </div>

                <div className='pre-footer'>
                    <h2>Берите с собой свою медицинскую карту, куда бы вы не пошли</h2>
                    <button>Доступ к Şəfa Kartım</button>

                    <p>Используете мобильное устройство или планшет?</p>
                    <p>Найдите приложение Şəfa Kartım на своей платформе</p>

                    <div className='app-markets-block'>
                        <Link to='/'>
                            <img width='130' src={require('./../../images/appstore.png')} alt="App Store" />
                        </Link>
                        <Link to='/'>
                            <img width='155' src={require('./../../images/playmarket.png')} alt="Google play" />
                        </Link>
                    </div>
                </div>

                <div className='home-new-footer'>

                    <div className='home-new-footer-left'>

                        <div className='logos'>
                            <div className='logo-card'>
                                <img width='60' src={HealthCardLogoSVG} alt="Health Card" />
                                <p>Şəfa Kartım</p>
                            </div>

                            <div className='logo-videodoctor'>
                                <Link to='/'>
                                    <img width='22' src={VideodoctorLogoSVG} alt="Health Card" />
                                    <p><span>by</span> VideoDoktor</p>
                                </Link>
                            </div>
                        </div>

                        <div className='contacs-block'>

                            <div className='call-us'>
                                <p>Звоните нам:</p>
                                <div className='numbers-list'>
                                    <div>
                                        <img width='10' src={TelephoneSVG} alt="Call us" />
                                        <p>(+994) 51-206-38-13</p>
                                    </div>
                                    <div>
                                        <img width='10' src={TelephoneSVG} alt="Call us" />
                                        <p>(+994) 51-206-38-13</p>
                                    </div>
                                </div>
                            </div>

                            <div className='write-us'>
                                <p>Пишите нам:</p>
                                <div className='emails-list'>
                                    <div>
                                        <img width='10' src={MessageSVG} alt="Message us" />
                                        <p>info@videodoktor.az</p>
                                    </div>
                                    <div>
                                        <img width='10' src={MessageSVG} alt="Message us" />
                                        <p>dataprotection@videodoktor.az</p>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className='home-new-footer-right'>

                        <div className='navigation-links'>
                            <Link to='/'>О нас</Link>
                            <Link to='/'>Доступ к Şəfa Kartım</Link>
                            <Link to='/'>Функции</Link>
                            <Link to='/'>Помощь</Link>
                        </div>

                        <div className='ezgil-logotype'>
                            <img width='80' src={EzgilSVG} alt="Ezgil logotype" />
                            <p>EZGIL medical technologies © 2021. Все права защищены.</p>
                        </div>

                    </div>

                </div>

            </div >
        )
    }
}

const mapStateToProps = (state: RootState) => ({
});

const mapDispatchToProps = {
};

let HomeNewContainer = connect(mapStateToProps, mapDispatchToProps)(
    withTranslation()(HomeNew)
);

export default HomeNewContainer;